import React, {useEffect, useState} from "react";
import {FormControlLabel, Switch,} from "@mui/material";

export default function DHSwitch({id, label, required, value, onChange}) {

    const [v, setV] = useState(false);
    useEffect(() => {
        setV(value);
    }, [value]);

    return <FormControlLabel control={<Switch id={id} checked={v} required={required} onClick={(event) => onChange(event.target.checked)}/>} label={label}/>

}