import React, {useEffect, useState} from 'react'
import StatistikGameComponent from "./StatistikGameComponent";
import DHDialog from "../../../general/DHDialog";

export default function StatistikGameDialog({gameId, tournamentId, tournamentGameId, onClose}) {

    let [dimensions, setDimensions] = useState({
        width: window.screen.width}
    );

    useEffect(() => {
        function handleResize() {
            setDimensions({
                width: window.screen.width
            })
        }
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return <DHDialog showCloseButton={true} onClose={() => onClose(false)} title="Game/Details"
                            comp={<>
        <div style={{width: "100%"}}>
            <StatistikGameComponent gameId={gameId} tournamentId={tournamentId} tournamentGameId={tournamentGameId}/>
        </div>
    </>}/>
}
