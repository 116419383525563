import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {getModusNameById} from "../../general/GameModusComponent";
import LigaGame from "./LigaGame";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import StatisticGameDetailDialog from "../statistics/StatisticGameDetailDialog";
import {getTournamentsystem, TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import ConfirmDialog from "../../general/ConfirmDialog";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import HighlightTable from "./ligaHighlightTable";
import DateUtil from "../../../util/DateUtil";

export function Sponsoren(props) {
    let rows = [];
    for(let i = 0; i < props.sponsoren.length; i++) {
        let sponsor = props.sponsoren[i];
        rows.push(<a href={sponsor.sponsorEntity.url} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}} key={"key_sponsor_" + sponsor.id}>
            <div className="grid align-center vertical-container" style={{marginLeft:30, marginTop:10, marginBottom:10, cursor:"pointer", textDecoration: "none", color:"black", width:"100%"}}
                 key={"key_div_sponsor_" + sponsor.id}>
            {(sponsor.sponsorEntity.image !== null && sponsor.sponsorEntity.image !== "") && <img src={sponsor.sponsorEntity.image} alt="Sponsoren" style={{width:50, marginRight:10, marginLeft:5}}/> }
            {sponsor.sponsorEntity.anzeigename}
                </div>
        </a>);
    }
    return <Panel header="Sponsoren des Turnier-/Ligabetreibers" style={{marginTop:20, marginBottom:20}}><div className="grid">
        <div className="col">{rows}</div>
    </div></Panel>
}

export function TabelleHighscores(props) {
    if( props.ranglisteHighscores === null || props.ranglisteHighscores === undefined ) {
        return "";
    }
    let rows = [];
    let lastValue = undefined;
    let platz = 1;
    for(let i = 0; i < props.ranglisteHighscores.length; i++) {
        let row = props.ranglisteHighscores[i];
        let wert = "";
        let gesamt = 0;
        for (const highscore of row.values) {
            wert += highscore.value + "x" + highscore.key + ", ";
            gesamt = gesamt + highscore.value;
        }
        if( lastValue === undefined) {
            lastValue = gesamt;
        } else if (lastValue !== gesamt ) {
            platz = i + 1;
            lastValue = gesamt;
        }
        wert = "Anzahl: " + gesamt + " " + wert;
        let spielername = props.getSpielerName(row.id);
        rows.push({rang:platz, spieler:spielername, wert:wert, id: row.id});
    }

    return <div className="col-12 md:col-12 lg:col-4" style={{padding:10}}>
        <h2 style={{color: props.color !== undefined ? props.color : "black"}}>Highscores</h2>
        <HighlightTable keyPrefix="key_highscore" col3="" col3Width="120px" rows={rows} />
    </div>;
}

export function TabelleHighfinishes(props) {
    if( props.ranglisteHighfinishes === null || props.ranglisteHighfinishes === undefined ) {
        return "";
    }
    let rows = [];
    let lastValue = undefined;
    let platz = 1;
    for(let i = 0; i < props.ranglisteHighfinishes.length; i++) {
        let row = props.ranglisteHighfinishes[i];
        let wert = "";
        let gesamt = 0;
        for (const highfinish of row.values) {
            wert += highfinish.value + "x" + highfinish.key + ", ";
            gesamt = gesamt + highfinish.value;
        }
        if( lastValue === undefined) {
            lastValue = gesamt;
        } else if (lastValue !== gesamt ) {
            platz = i + 1;
            lastValue = gesamt;
        }
        wert = "Anzahl: " + gesamt + " " + wert;
        let spielername = props.getSpielerName(row.id);
        rows.push({rang:platz, spieler:spielername, wert:wert, id: row.id});
    }
    return <div className="col-12 md:col-12 lg:col-4" style={{padding:10}}>
        <h2 style={{color: props.color !== undefined ? props.color : "black"}}>Highfinishes</h2>
        <HighlightTable keyPrefix="key_highfinishes" col3="" col3Width="120px" rows={rows} />
    </div>;
}

export function TabelleWorstDarts(props) {
    if (props.worstDarts === null || props.worstDarts === undefined || props.worstDarts.length === 0) {
        return "";
    }
    let rows = [];
    let lastValue = undefined;
    let platz = 1;
    for (let i = 0; i < props.worstDarts.length; i++) {
        let row = props.worstDarts[i];
        let spielername = props.getSpielerName(row.id);
        let wert = row.anzahldarts;
        if( lastValue === undefined) {
            lastValue = wert;
        } else if (lastValue !== wert ) {
            platz = i + 1;
            lastValue = wert;
        }
        rows.push({rang: platz, spieler: spielername, wert: wert, id: row.id});
    }

    return <div className="col-12 md:col-12 lg:col-4" style={{padding: 10}}>
        <h2 style={{color: props.color !== undefined ? props.color : "black"}}>Worst Darts</h2>
        <HighlightTable keyPrefix="key_worst_darts" col3="" col3Width="120px" rows={rows}/>
    </div>;
}

export function TabelleLowDarts(props) {
    if (props.lowDarts === null || props.lowDarts === undefined || props.lowDarts.length === 0) {
        return "";
    }
    let rows = [];
    let lastBest = undefined;
    let lastAnzahlLowDarts = undefined;
    let platz = 1;
    for (let i = 0; i < props.lowDarts.length; i++) {
        let row = props.lowDarts[i];
        let lowDartsString = "";
        Object.entries(row.lowDarts).map(([key, value]) => (
            lowDartsString += value + "x" + key + ", "
        ));
        let spielername = props.getSpielerName(row.id);
        let wert = <div>Best: {row.best}<br/>(Anzahl: {row.anzahlLowDarts}) {lowDartsString}</div>
        if( lastBest === undefined) {
            lastBest = row.best;
            lastAnzahlLowDarts = row.anzahlLowDarts;
        } else if (lastBest !== row.best ) {
            platz = i + 1;
            lastBest = row.best;
            lastAnzahlLowDarts = row.anzahlLowDarts;
        } else if( lastBest === row.best && lastAnzahlLowDarts !== row.anzahlLowDarts) {
            platz = i + 1;
            lastBest = row.best;
            lastAnzahlLowDarts = row.anzahlLowDarts;
        }
        rows.push({rang: platz, spieler: spielername, wert: wert, id: row.id});
    }

    return <div className="col-12 md:col-12 lg:col-4" style={{padding: 10}}>
        <h2 style={{color: props.color !== undefined ? props.color : "black"}}>Low Darts</h2>
        <HighlightTable keyPrefix="key_best_darts" col3="" col3Width="120px" rows={rows}/>
    </div>;
}

export function TabelleBestDarts(props) {
    if (props.bestDarts === null || props.bestDarts === undefined || props.bestDarts.length === 0) {
        return "";
    }
    let rows = [];
    let lastValue = undefined;
    let platz = 1;
    for (let i = 0; i < props.bestDarts.length; i++) {
        let row = props.bestDarts[i];
        let spielername = props.getSpielerName(row.id);
        let wert = row.anzahldarts;
        if( lastValue === undefined) {
            lastValue = wert;
        } else if (lastValue !== wert ) {
            platz = i + 1;
            lastValue = wert;
        }
        rows.push({rang: platz, spieler: spielername, wert: wert, id: row.id});
    }

    return <div className="col-12 md:col-12 lg:col-4" style={{padding: 10}}>
        <h2 style={{color: props.color !== undefined ? props.color : "black"}}>Best Darts</h2>
        <HighlightTable keyPrefix="key_best_darts" col3="" col3Width="120px" rows={rows}/>
    </div>;
}

export function TabelleRangliste(props) {
    if( props.players === null || props.players === undefined ) {
        return "";
    }
    let rows = [];
    let lastValue = undefined;
    let platz = 1;
    for(let i = 0; i < props.players.length; i++) {
        let player = props.players[i];
        let spielername = player.name;
        let wert = player.ranglistePunkte;
        if( lastValue === undefined) {
            lastValue = wert;
        } else if (lastValue !== wert ) {
            platz = i + 1;
            lastValue = wert;
        }
        rows.push({rang:platz, spieler:spielername, wert:wert, id: player.id});
    }
    return <div className="col-12 md:col-12 lg:col-4" style={{padding:0}}>
        <h2><img alt="Logo" src={"/images/darthelfer.svg"} style={{width: 70, marginRight:20}}/>Rangliste</h2>
        <HighlightTable keyPrefix="key_ranglistetabelle" col3="Punkte" col3Width="70px" rows={rows} />
    </div>;
}

export function TabelleWeekendLeague(props) {
    if( props.rangliste === null || props.rangliste === undefined ) {
        return "";
    }
    let rows = [];
    let lastValue = undefined;
    let platz = 1;
    for(let i = 0; i < props.rangliste.length; i++) {
        let row = props.rangliste[i];
        let spielername = props.getSpielerName(row.userId);
        let wert = row.anzahlLegsGewonnen;
        if( lastValue === undefined) {
            lastValue = wert;
        } else if (lastValue !== wert ) {
            platz = i + 1;
            lastValue = wert;
        }
        rows.push({rang:platz, spieler:spielername, wert:wert, id: row.id});
    }
    return <div className="col-12 md:col-12 lg:col-4" style={{padding:10}}>
        <h2><img alt="Logo" src={"/images/darthelfer.svg"} style={{width: 70, marginRight:20}}/>Tabelle</h2>
        <div style={{textAlign:"left"}}>Anzahl Spiele: {props.anzahlGames}</div>
        <div style={{textAlign:"left"}}>Anzahl Legs: {props.anzahlLegs}</div>
        <HighlightTable keyPrefix="key_180er" col3="Anzahl" col3Width="70px" rows={rows} />
    </div>;
}

export function Tabelle180er(props) {
    if( props.rangliste180er === null || props.rangliste180er === undefined ) {
        return "";
    }
    let rows = [];
    let lastValue = undefined;
    let platz = 1;
    for(let i = 0; i < props.rangliste180er.length; i++) {
        let row = props.rangliste180er[i];
        let spielername = props.getSpielerName(row.id);
        let wert = row.anzahl;
        if( lastValue === undefined) {
            lastValue = wert;
        } else if (lastValue !== wert ) {
            platz = i + 1;
            lastValue = wert;
        }
        rows.push({rang:platz, spieler:spielername, wert:wert, id: row.id});
    }
    return <div className="col-12 md:col-12 lg:col-4" style={{padding:10}}>
        <h2 style={{color: props.color !== undefined ? props.color : "black"}}>180er</h2>
        <HighlightTable keyPrefix="key_180er" col3="Anzahl" col3Width="70px" rows={rows} />
    </div>;
}

export function Tabelle171er(props) {
    if( props.rangliste171er === null || props.rangliste171er === undefined ) {
        return "";
    }
    let rows = [];
    let lastValue = undefined;
    let platz = 1;
    for(let i = 0; i < props.rangliste171er.length; i++) {
        let row = props.rangliste171er[i];
        let spielername = props.getSpielerName(row.id);
        let wert = row.anzahl;
        if( lastValue === undefined) {
            lastValue = wert;
        } else if (lastValue !== wert ) {
            platz = i + 1;
            lastValue = wert;
        }
        rows.push({rang:platz, spieler:spielername, wert:wert, id: row.id});
    }
    return <div className="col-12 md:col-12 lg:col-4" style={{padding:10}}>
        <h2 style={{color: props.color !== undefined ? props.color : "black"}}>171er</h2>
        <HighlightTable keyPrefix="key_171er" col3="Anzahl" col3Width="70px" rows={rows} />
    </div>;
}

export function TabelleAverage(props) {
    if( props.ranglisteAverage === null || props.ranglisteAverage === undefined ) {
        return "";
    }
    let rows = [];
    let lastValue = undefined;
    let platz = 1;
    for(let i = 0; i < props.ranglisteAverage.length; i++) {
        let row = props.ranglisteAverage[i];
        let spielername = props.getSpielerName(row.id);
        let wert = row.average;
        if( lastValue === undefined) {
            lastValue = wert;
        } else if (lastValue !== wert ) {
            platz = i + 1;
            lastValue = wert;
        }
        rows.push({rang:platz, spieler:spielername, wert:wert, id: row.id});
    }
    return <div className="col-12 md:col-12 lg:col-4" style={{padding:10}}>
        <h2 style={{color: props.color !== undefined ? props.color : "black"}}>Average</h2>
        <HighlightTable keyPrefix="key_average_tabelle" col3="Average" col3Width="70px" rows={rows} />
    </div>;
}

export function ShortStatistikArea(props) {

    return <div style={{textAlign: "left", padding: 20}}>
        {props.statistik !== null && props.statistik !== undefined && <div>
            <h5 style={{margin: 0, marginBottom: 10}}>{props.label}</h5>
            <div className="grid">
                <div className="col-fixed" style={{width: 160}}>
                    Average:
                </div>
                <div className="col">{props.statistik.average}</div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: 160}}>
                    First 9 Avg.:
                </div>
                <div className="col">{props.statistik.first9Average}</div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: 160}}>
                    Spiele G / U / V:
                </div>
                <div
                    className="col">{props.statistik.gewonneneSpiele} / {props.statistik.unentschiedenSpiele} / {props.statistik.verloreneSpiele}</div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: 160}}>
                    Anzahl Würfe:
                </div>
                <div className="col">{props.statistik.anzahlWuerfeGesamt}</div>
            </div>
            {props.statistik.anzahlDoppelwuerfe > 0 && <>
                <div className="grid">
                    <div className="col-fixed" style={{width: 160}}>
                        Doppelquote:
                    </div>
                    <div className="col">{props.statistik.doppelquote}%</div>
                </div>
            </>}
            {props.statistik.anzahlDoppelwuerfe > 0 && <>
                <div className="grid">
                    <div className="col-fixed" style={{width: 160}}>
                        Doppel:
                    </div>
                    <div className="col">{props.statistik.anzahlDoppelgetroffen}/{props.statistik.anzahlDoppelwuerfe}</div>
                </div>
            </>}
            {props.statistik.timestamp &&
                <div className="grid" style={{fontSize: 12}}>
                    <div className="col">Stand: {DateUtil.localDateTimeToString(props.statistik.timestamp)}</div>
                </div>
            }
        </div>
        }
    </div>
}

export function TournamentGameShortComponent(props) {

    let userGame = props.userGame;

    let gegnerName = userGame.gegnerName;

    return <div style={{marginBottom: 5}}>
        <NavLink
            to={NAVIGATION_ITEM.SCORING.route + "?gameid=" + userGame.gameId + "&playerid=" + userGame.playerId}
            style={{textDecoration: "none"}}>
            <img src="/images/dart_select.png" alt="Spiel starten"
                 style={{cursor: "pointer", width: 30, marginRight: 5}}/>
        </NavLink>
        {gegnerName}
    </div>
}

export function FunGameShortComponent(props) {

    let [showGameDetailDialog, setShowGameDetailDialog] = useState(false);
    let [showDeleteDialog, setShowDeleteDialog] = useState(false);

    let loeschen = () => {
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.fungame + "/" + props.funGame.id,
            {},
            () => {
                console.log("Spiel wurde erfolgreich gelöscht.");
                setShowDeleteDialog(false);
                props.callBackAfterDelete();
            },
            responseNotOk => {
                console.log("Fehler beim Löschen: " + responseNotOk.message);
                setShowDeleteDialog(false);
            },
            error => {
                console.log("Fehler beim Löschen: " + error.message);
                setShowDeleteDialog(false);
            }
        );
    }

    let funGame = props.funGame;
    let text = "";
    if (funGame.gast) {
        text += "Gast | ";
    }
    if (funGame.spielerId === props.userId || funGame.playerIdUserId1 === props.userId || funGame.playerIdUserId2 === props.userId) {
        if (funGame.gegnerName) {
            text += funGame.gegnerName + " | ";
        }
    } else {
        text += funGame.spielerName + " | ";
    }
    if( funGame.gameType === "x01") {
        text += "x01 | ";
    }
    else if( funGame.gameType === "cricket") {
        text += "cricket | ";
    }
    if (funGame.modusid) {
        text += getModusNameById(funGame.modusid) + " | ";
    }
    if (funGame.zufallszahlen) {
        text += "Zufallsz. | ";
    }
    if (funGame.cutthroat) {
        text += "Cutthroat | ";
    }

    let text2 = "";
    text2 += "ft sets: " + funGame.sets + " - ";
    if (funGame.firstToLegs) {
        text2 += "ft: " + funGame.firstToLegs + " - ";
    }
    if (funGame.bestOfLegs) {
        text2 += "bo: " + funGame.bestOfLegs + " - ";
    }
    if (funGame.startpunkte) {
        text2 += funGame.startpunkte;
    }
    let text3 = ""
    if (funGame.scorekey) {
        text3 += "Scorekey: " + funGame.scorekey;
    }
    if (funGame.herausforderung) {
        text3 += "Herausforderung - Avg.: " + funGame.spielerAverage;
    }
    let aktuellerUserIstImGame = funGame.spielerId === props.userId || funGame.gegnerId === props.userId
        || funGame.playerIdUserId1 === props.userId
        || funGame.playerIdUserId2 === props.userId
        || funGame.gegnerIdUserId1 === props.userId
        || funGame.gegnerIdUserId2 === props.userId;
    let playerId;
    if (funGame.doppelMatch) {
        playerId = funGame.playerIdUserId1 === props.userId || funGame.playerIdUserId2 === props.userId ? funGame.spielerId : funGame.gegnerId;
    } else {
        playerId = props.userId;
    }
    let gameLinkAnzeigen = aktuellerUserIstImGame && funGame.gameId !== null && funGame.gameId !== undefined && !funGame.abgeschlossen;
    let ergebnis = "n.a.";
    if( funGame.sets !== null && funGame.sets !== undefined && funGame.sets > 1) {
        ergebnis = funGame.playerGewonnenSets + " : " + funGame.playerVerloreneSets;
    } else {
        ergebnis = funGame.playerGewonnenLegs + " : " + funGame.playerVerloreneLegs;
    }
    return <div style={{marginBottom: 5}}>
        {gameLinkAnzeigen ?
            <NavLink
                to={NAVIGATION_ITEM.SCORING.route + "?gameid=" + funGame.gameId + "&playerid=" + playerId}
                style={{textDecoration: "none"}}>
                <img src="/images/dart_select.png" alt="Spiel starten"
                     style={{cursor: "pointer", width: 30, marginRight: 5}}/>
            </NavLink>
            : ""}
        {funGame.gameId && funGame.abgeschlossen ?
            <Button icon="pi pi-search-plus"
                    onClick={() => setShowGameDetailDialog(true)}
                    tooltip="Zeige Details" style={{marginRight: 5}}/>
            : ""
        }
        {text}
        {text2 !== "" &&
            <div>{text2}</div>}
        {text3 !== "" &&
            <div>{text3}</div>}
        {funGame.abgeschlossen &&
        <div style={{marginTop: 5}}>Ergebnis: {ergebnis}</div>
        }
        {(aktuellerUserIstImGame && props.showDelete) &&
        <Button icon="pi pi-trash"
                onClick={() => setShowDeleteDialog(true)}
                tooltip="Löschen" style={{marginLeft: 5}}/>
        }
        <div style={{fontSize: 12}}>{funGame.timestampString}</div>
        {showGameDetailDialog &&
            <StatisticGameDetailDialog
                                       callBackDialogClose={() => setShowGameDetailDialog(false)}
                                       visible={true} gameId={funGame.gameId} gameType={funGame.gameType}/>
        }
        <ConfirmDialog visible={showDeleteDialog}
                       callBackOnNein={() => setShowDeleteDialog(false)}
                       callBackOnJa={() => loeschen()}
                       header="Spiel wirklich löschen"
                       textOben="Sobald du das Spiel löscht, wird dieses komplett gelöscht und ist nicht mehr aufrufbar!!!"
                       textUnten="Möchtest du das Spiel wirklich löschen?"/>

    </div>
}

function getLigaStatistikArea(bezeichnung, wert) {
    let width = 130;
    return <div className="grid">
        <div className="col-fixed" style={{width: width}}>
            {bezeichnung}
        </div>
        <div className="col">
            {wert}
        </div>
    </div>
}

export function TournamentShortComponent(props) {
    if (props.tournaments === null || props.tournaments === undefined || props.tournaments.length === 0) {
        return <div style={{fontSize: 12}}>- Keine Daten vorhanden...</div>
    }

    let rows = [];
    for (const tournament of props.tournaments) {
        let modus = undefined;
        let ftString = "";
        let startpunkteString = undefined;
        let cricketString = undefined;

        let navlink = "";
        if (tournament.turniersystem === TOURNAMENTSYSTEMS.WEEKENDLEAGUE.id) {
            navlink = NAVIGATION_ITEM.WEEKENDLEAGUEDETAIL.route;
        } else if (tournament.turniersystem === TOURNAMENTSYSTEMS.EINFACHERKO.id || tournament.turniersystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id) {
            navlink = NAVIGATION_ITEM.TURNIERDETAIL.route;
        } else if (tournament.turniersystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id || tournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id || tournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id) {
            navlink = NAVIGATION_ITEM.TURNIERGRUPPENDETAIL.route;
        } else if (tournament.turniersystem === TOURNAMENTSYSTEMS.KRATZER.id) {
            navlink = NAVIGATION_ITEM.KRATZERDETAIL.route;
        }

        if( props.showDetails ) {
            if (tournament.turniersystem === TOURNAMENTSYSTEMS.WEEKENDLEAGUE.id) {
                let tournamentModusEntity = tournament.tournamentModusEntities[0];
                if( tournamentModusEntity.firstToLegs !== null && tournamentModusEntity.firstToLegs !== undefined) {
                    ftString = "ft legs:" + tournamentModusEntity.firstToLegs;
                } else {
                    ftString = "bo legs:" + tournamentModusEntity.bestOfLegs;
                }
                ftString = ftString + " - Modus: " + getModusNameById(tournamentModusEntity.modusid) + " - Startpunkte: " + tournamentModusEntity.startpunkte;
            } else if (tournament.turniersystem === TOURNAMENTSYSTEMS.EINFACHERKO.id) {
                let modusGewinnerseite = tournament.tournamentModusEntities.find(element => element.modusTyp === "gewinnerseite");
                ftString = (modusGewinnerseite.sets > 1 ? "Sets: " + modusGewinnerseite.sets + " " : "") + "ft legs:" + modusGewinnerseite.firstToLegs;
                if( modusGewinnerseite.modusid !== null && modusGewinnerseite.modusid !== undefined ) {
                    modus = "Modus: " + getModusNameById(modusGewinnerseite.modusid);
                }
                if( modusGewinnerseite.startpunkte !== null && modusGewinnerseite.startpunkte !== undefined ) {
                    startpunkteString = "Startpunkte: " + modusGewinnerseite.startpunkte;
                }
                if( modusGewinnerseite.zufallszahlen !== null && modusGewinnerseite.zufallszahlen !== undefined ) {
                    cricketString = "Gewinnerseite Zufallsz.: " + (modusGewinnerseite.zufallszahlen ? "ja" : "nein") + " - Cutthroat: " + (modusGewinnerseite.cutthroat ? "ja" : "nein")
                }
                navlink = NAVIGATION_ITEM.TURNIERDETAIL.route;
            } else if (tournament.turniersystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id) {
                let modusVerliererseite = tournament.tournamentModusEntities.find(element => element.modusTyp === "verliererseite");
                let modusGewinnerseite = tournament.tournamentModusEntities.find(element => element.modusTyp === "gewinnerseite");
                ftString = "Gewinnerseite " + (modusGewinnerseite.sets > 1 ? "Sets: " + modusGewinnerseite.sets + " " : "") + "ft legs:" + modusGewinnerseite.firstToLegs + " - Verliererseite " + (modusVerliererseite.sets > 1 ? "Sets: " + modusVerliererseite.sets + " " : "") + " ft legs:" + modusVerliererseite.firstToLegs;
                if( modusGewinnerseite.modusid !== null && modusGewinnerseite.modusid !== undefined ) {
                    modus = "Modus Gewinnerseite: " + getModusNameById(modusGewinnerseite.modusid) + " - Verliererseite: " + getModusNameById(modusVerliererseite.modusid);
                }
                if( modusGewinnerseite.startpunkte !== null && modusGewinnerseite.startpunkte !== undefined ) {
                    startpunkteString = "Startpunkte Gewinnerseite: " + modusGewinnerseite.startpunkte + " - Verliererseite: " + modusVerliererseite.startpunkte;
                }
                if( modusGewinnerseite.zufallszahlen !== null && modusGewinnerseite.zufallszahlen !== undefined ) {
                    cricketString = "Gewinnerseite Zufallsz.: " + (modusGewinnerseite.zufallszahlen ? "ja" : "nein") + " Cutthroat: " + (modusGewinnerseite.cutthroat ? "ja" : "nein")
                    cricketString += " Verliererseite Zufallsz.: " + (modusVerliererseite.zufallszahlen ? "ja" : "nein") + " - Cutthroat: " + (modusVerliererseite.cutthroat ? "ja" : "nein")
                }

            } else if (tournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id || tournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id) {
                let tournamentModusEntity = tournament.tournamentModusEntities[0];
                if( tournamentModusEntity.firstToLegs !== null && tournamentModusEntity.firstToLegs !== undefined) {
                    ftString = "Gruppenphase " + (tournamentModusEntity.sets > 1 ? "Sets: " + tournamentModusEntity.sets + " " : "") + "ft legs:" + tournamentModusEntity.firstToLegs;
                } else {
                    ftString = "Gruppenphase bo legs:" + tournamentModusEntity.bestOfLegs;
                }
                if( tournamentModusEntity.modusid !== null && tournamentModusEntity.modusid !== undefined ) {
                    modus = "Gruppenphase Modus: " + getModusNameById(tournamentModusEntity.modusid);
                }
                if( tournamentModusEntity.startpunkte !== null && tournamentModusEntity.startpunkte !== undefined ) {
                    startpunkteString = "Gruppenphase Startpunkte: " + tournamentModusEntity.startpunkte;
                }
                if( tournamentModusEntity.zufallszahlen !== null && tournamentModusEntity.zufallszahlen !== undefined ) {
                    cricketString = "Zufallsz.: " + (tournamentModusEntity.zufallszahlen ? "ja" : "nein") + " - Cutthroat: " + (tournamentModusEntity.cutthroat ? "ja" : "nein")
                }
            }  else if (tournament.turniersystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id) {
                let tournamentModusEntity = tournament.tournamentModusEntities[0];
                if( tournamentModusEntity.firstToLegs !== null && tournamentModusEntity.firstToLegs !== undefined) {
                    ftString = (tournamentModusEntity.sets > 1 ? "Sets: " + tournamentModusEntity.sets + " " : "") + "ft legs:" + tournamentModusEntity.firstToLegs;
                } else {
                    ftString = "bo legs:" + tournamentModusEntity.bestOfLegs;
                }
                if( tournamentModusEntity.modusid !== null && tournamentModusEntity.modusid !== undefined ) {
                    ftString += " / Modus: " + getModusNameById(tournamentModusEntity.modusid) + " / Startpunkte: " + tournamentModusEntity.startpunkte;
                }
            }
        }
        let userinfo = "";
        if( tournament.tournamentPlayerEntities !== null && tournament.tournamentPlayerEntities !== undefined) {
            userinfo = "Angemeldet:" +  tournament.tournamentPlayerEntities.length;
        }
        if( tournament.minAnzahlTeilnehmer !== null && tournament.minAnzahlTeilnehmer !== undefined ) {
            userinfo += " - Min.Teilnehmer:" + tournament.minAnzahlTeilnehmer;
        }
        if( tournament.maxAnzahlTeilnehmer !== null && tournament.maxAnzahlTeilnehmer !== undefined ) {
            userinfo += " - Max.Teilnehmer:" + tournament.maxAnzahlTeilnehmer;
        }
        rows.push(<div style={{marginBottom: 5}} key={"key_tournament_short_component_" + tournament.id}>
            <NavLink to={navlink + "?turnierid=" + tournament.id} style={{textDecoration: "none"}}>
                <img alt="Zeige Details" style={{marginRight: 10, width: 20}} src={"/images/showdetails.png"}/>
                {tournament.kennwort !== null && tournament.kennwort !== undefined && tournament.kennwort !== "" && <img src="/images/key.png" alt="Kennwortgeschützt" style={{marginRight:5, width:20}}/>}
                <span style={{color: "black"}}>{tournament.bezeichnung}</span>
                {tournament.doppelturnier && <div style={{color: "black", marginTop:5}}>--- Doppelturnier ---</div>}
                {props.showDetails && tournament.anmeldeende !== null && tournament.anmeldeende !== undefined &&
                    <div style={{marginTop: 5, color: "black"}}>Anmeldeende: {tournament.anmeldeendeMitUhrzeitString}</div>
                }
                {props.showDetails && <>
                    <div style={{marginTop: 5, color: "black"}}>Beginn: {tournament.beginnMitUhrzeitString}</div>
                    <div style={{marginTop: 5, color: "black", fontSize: 12}}>Turniersystem: '{getTournamentsystem(tournament.turniersystem).text}' - Gametype: {tournament.gameType}</div>
                    <div style={{marginTop: 5, color: "black", fontSize: 12}}>{userinfo}</div>
                    {modus !== undefined &&<div style={{marginTop: 5, color: "black", fontSize: 12}}>{modus}</div>}
                    <div style={{marginTop: 5, color: "black", fontSize: 12}}>{ftString}</div>
                    {startpunkteString !== undefined && <div style={{marginTop: 5, color: "black", fontSize: 12}}>{startpunkteString}</div>}
                    {cricketString !== undefined && <div style={{marginTop: 5, color: "black", fontSize: 12}}>{cricketString}</div>}
                </>
                }
            </NavLink>
            <hr/>
        </div>)
    }
    return <div style={{width:"100%"}}>
        {rows.length === 0 && "Keine Daten vorhanden..."}
        {rows}
    </div>
}


export function LigaShortComponent(props) {
    if (props.ligen === null || props.ligen === undefined || props.ligen.length === 0) {
        return <div style={{fontSize: 12}}>- Keine Daten vorhanden...</div>
    }

    let rows = [];
    for (const liga of props.ligen) {
        let modus = undefined;
        let ftString = "";
        let boString = "";
        if( props.showDetails ) {
            // ist beim Laden über das Profil nicht vorhanden. Deshalb nur ermitteln, wenn Details gewünscht
            let tournamentModus = liga.tournamentModusEntities[0];
            if(tournamentModus.modusid !== null && tournamentModus.modusid !== undefined) {
                modus = getModusNameById(tournamentModus.modusid);
            }
            ftString = tournamentModus.firstToLegs ?  (tournamentModus.sets > 1 ? "Sets: " + tournamentModus.sets + ", " : "") + "first to " + tournamentModus.firstToLegs + " legs"  : "";
            boString = tournamentModus.bestOfLegs ? (tournamentModus.sets > 1 ? "Sets: " + tournamentModus.sets + ", " : "") + "best of " + tournamentModus.bestOfLegs + " legs" : "";
        }

        let userinfo = "";
        if( liga.tournamentPlayerEntities !== null && liga.tournamentPlayerEntities !== undefined) {
            userinfo = "Angemeldet:" +  liga.tournamentPlayerEntities.length;
        }
        if( liga.minAnzahlTeilnehmer !== null && liga.minAnzahlTeilnehmer !== undefined ) {
            userinfo += " - Min.Teilnehmer:" + liga.minAnzahlTeilnehmer;
        }
        if( liga.maxAnzahlTeilnehmer !== null && liga.maxAnzahlTeilnehmer !== undefined ) {
            userinfo += " - Max.Teilnehmer:" + liga.maxAnzahlTeilnehmer;
        }

        rows.push(<div style={{marginBottom: 5}} key={"key_liga_short_component_" + liga.id}>
            <NavLink to={NAVIGATION_ITEM.LIGADETAIL.route + "?ligaid=" + liga.id} style={{textDecoration: "none"}}>
                <img alt="Zeige Details" style={{marginRight: 10, width: 20}} src={"/images/showdetails.png"}/>
                <span style={{color: "black"}}>{liga.bezeichnung}</span>
                {liga.doppelturnier && <div style={{color: "black", marginTop:5}}>--- Doppelliga ---</div>}
                {props.showDetails && liga.anmeldeende !== null && liga.anmeldeende !== undefined &&
                    <div style={{marginTop: 5, color: "black"}}>Anmeldeende: {liga.anmeldeendeMitUhrzeitString}</div>
                }
                {props.showDetails &&
                    <div style={{marginTop: 5, color: "black"}}>Beginn: {liga.beginnString} | {ftString}{boString} |
                        {modus !== undefined && "Modus: " + modus}</div>
                }
                <div style={{marginTop: 5, color: "black", fontSize: 12}}>GameType: {liga.gameType} - {userinfo}</div>
            </NavLink>
            <hr/>
        </div>)
    }
    return <div style={{width:"100%"}}>
        {rows.length === 0 && "Keine Daten vorhanden..."}
        {rows}
    </div>
}


export function LigaSpieltagComponent(props) {
    let rows = [];
    for (const game of props.games) {
        rows.push(<LigaGame readonly={props.readonly}
                            tournament={props.liga}
                            selectedPlayerId={props.selectedPlayerId}
                            onPlayerSelect={props.onPlayerSelect}
                            boardGames={props.boardGames}
                            userId={props.userId}
                            showGames={props.showGames}
                            currentUserPlayerId={props.currentUserPlayerId}
                            gruppennummer={1} game={game}
                            callbackRefresh={() => props.refresh()}
                            callbackGetSpielerName={props.getSpielerName}
                            callbackIsSpielerInaktiv={props.callbackIsSpielerInaktiv}
                            callbackHasPlayersMeetingkeyAllowed={props.callbackHasPlayersMeetingkeyAllowed}
                            key={"liga_spieltag_game_key_" + game.id}/>);
    }
    return <div className="col-12 md:col-12 lg:col-4" style={{padding: 10}} key={"spieltag_key_" + props.spieltag}>
        <div className="ligashortoverview" style={{height: "100%"}}>
            <h3>Spieltag {props.spieltag}</h3>
            {rows}
        </div>
    </div>
}
