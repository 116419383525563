import React from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Messages} from "primereact/messages";
import {Toast} from 'primereact/toast';

import {Button} from "primereact/button";

import ConfirmDialog from "../../general/ConfirmDialog";
import {Sidebar} from "primereact/sidebar";
import Trainingsergebnisse from "../../general/Trainingsergebnisse";

import Table from "./spieltagTable";

import {ScrollPanel} from 'primereact/scrollpanel';

import SpieltagEditDialog from "./SpieltagEditDialog";

import {USER_ROLES} from "../../../constants/userRoles";

class SpieltagOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            spieltage: [],
            showConfirmDialogAbschliessen: false,
            showDeleteDialog: false,
            showErgebnisse: false,
            showEditDialog: false,
        };

        this.showToast = this.showToast.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);

        this.changeSpieltagToAbgeschlossen = this.changeSpieltagToAbgeschlossen.bind(this);
        this.changeSpieltagToNichtAbgeschlossen = this.changeSpieltagToNichtAbgeschlossen.bind(this);
        this.showConfirmAbschliessenDialog = this.showConfirmAbschliessenDialog.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.showErgebnisseSidebar = this.showErgebnisseSidebar.bind(this);
        this.editSpieltag = this.editSpieltag.bind(this);
        this.callBackRefresh = this.callBackRefresh.bind(this);
    }

    showToast(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showWarn(message) {
        this.messages.show({
            severity: 'warn',
            sticky: 'true',
            summary: 'Achtung!!!',
            detail: message
        });
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    showConfirmAbschliessenDialog(id) {
        this.setState({...this.state, showConfirmDialogAbschliessen: true, selectedSpieltag: id})
    }

    showDeleteDialog(id) {
        this.setState({...this.state, showDeleteDialog: true, selectedSpieltag: id})
    }

    showErgebnisseSidebar(id) {
        this.setState({...this.state, showErgebnisse: true, selectedSpieltag: id})
    }

    changeSpieltagToAbgeschlossen() {
        this.messages.clear();
        let id = this.state.selectedSpieltag;
        let url = ConfigUtil.getConfig().resourceUrls.gameday + "/" + id + "/abgeschlossen";
        FetchUtil.fetchPut(url,
            {},
            () => {
                this.showToast("Spieltag wurde abgeschlossen.");
                this.loadData();
                this.setState({
                    ...this.state,
                    showConfirmDialogAbschliessen: false,
                    selectedSpieltag: undefined
                });
            },
            responseNotOk => {
                this.showError("Fehler beim Ändern des Status: " + responseNotOk.message);
                this.setState({
                    ...this.state,
                    showConfirmDialogAbschliessen: false,
                    selectedSpieltag: undefined
                })
            },
            error => {
                this.showError("Fehler beim Ändern des Status: " + error.message);
                this.setState({
                    ...this.state,
                    showConfirmDialogAbschliessen: false,
                    selectedSpieltag: undefined
                })
            })

    }


    changeSpieltagToNichtAbgeschlossen(id) {
        this.messages.clear();
        let url = ConfigUtil.getConfig().resourceUrls.gameday + "/" + id + "/nichtabgeschlossen";
        FetchUtil.fetchPut(url,
            {},
            () => {
                this.showToast("Spieltag wurde aktiviert.");
                this.loadData();
            },
            responseNotOk => {
                this.showError("Fehler beim Ändern des Status auf aktiv: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Ändern des Status: " + error.message);
            })
    }



    deleteSpieltag() {
        this.messages.clear();
        let id = this.state.selectedSpieltag;
        let url = ConfigUtil.getConfig().resourceUrls.gameday;
        FetchUtil.fetchDelete(url,
            {id:id},
            () => {
                this.showToast("Spieltag wurde gelöscht.");
                this.loadData();
                this.setState({
                    ...this.state,
                    showDeleteDialog: false,
                    selectedSpieltag: undefined
                });
            },
            responseNotOk => {
                this.showError("Fehler beim Löschen des Spieltages: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Löschen des Spieltages: " + error.message);
            })
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.gameday + "/all",
            json => {
                this.setState({spieltage: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Spieltags: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Spieltags: " + error.message);
            })
    }

    neuenSpieltagAnlegen() {
        this.setState({
            showEditDialog: true,
            selectedSpieltag: undefined
        });
    }

    editSpieltag(Spieltag) {
        this.setState({showEditDialog:true, selectedSpieltag:Spieltag});
    }

    callBackRefresh() {
        this.loadData();
    }

    render() {
        let isAdmin = false;
        if( this.props.userRoles !== undefined && this.props.userRoles !== null) {
            isAdmin = this.props.userRoles.some(userRole => userRole === USER_ROLES.ADMINISTRATOR.text || userRole === USER_ROLES.SUPERADMIN.text);
        }

        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="pageHeader" style={{backgroundColor:"#ecb3ff"}}>
                Spieltage
            </div>
            <div className="grid" style={{padding:20, marginLeft:0, marginRight:0}}>
                <div className="col" style={{textAlign:"left"}}>
                    <Button label="Neuen Spieltag anlegen" icon="pi pi-pencil" tooltip="Neuen Spieltag anlegen" onClick={() => this.neuenSpieltagAnlegen()}/>
                </div>
            </div>
            <div className="grid" style={{padding:20, marginLeft:0, marginRight:0}}>
                <Table isAdmin={isAdmin}
                       spieltage={this.state.spieltage}
                       showErgebnisseSidebar={this.showErgebnisseSidebar}
                       showConfirmAbschliessenDialog={this.showConfirmAbschliessenDialog}
                       changeSpieltagToNichtAbgeschlossen={this.changeSpieltagToNichtAbgeschlossen}
                       deleteSpieltag={this.showDeleteDialog}
                       editSpieltag={this.editSpieltag}/>
            </div>
            <ConfirmDialog visible={this.state.showConfirmDialogAbschliessen}
                           callBackOnNein={() => this.setState({
                               showConfirmDialogAbschliessen: false,
                               selectedSpieltag: undefined
                           })}
                           callBackOnJa={() => this.changeSpieltagToAbgeschlossen()}
                           header="Spieltag wirklich abschliessen"
                           textOben="Sobald ein Spieltag abgeschlossen wird, können keine Daten mehr geändert werden."
                           textUnten="Möchtest du den Spieltag wirklich abschliessen?"/>
            <ConfirmDialog visible={this.state.showDeleteDialog}
                           callBackOnNein={() => this.setState({
                               showDeleteDialog: false,
                               selectedSpieltag: undefined
                           })}
                           callBackOnJa={() => this.deleteSpieltag()}
                           header="Spieltag wirklich löschen"
                           textOben="Sobald ein Spieltag gelöscht wird, werden alle Daten dazu gelöscht."
                           textUnten="Möchtest du den Spieltag wirklich löschen?"/>
            <Sidebar visible={this.state.showErgebnisse} style={{width: "100%"}}
                     onHide={(e) => this.setState({showErgebnisse: false})}
                     position="left" showCloseIcon={false}>
                <ScrollPanel style={{width: '100%', height: '100%'}}>
                    <Trainingsergebnisse Spieltagid={this.state.selectedSpieltag} counter={0}/>
                    <div className="grid" style={{padding: "0px", marginTop: "30px"}}>
                        <Button label="Abbrechen / Schliessen" className="p-button-danger"
                                style={{marginLeft: "5px", height: "50px", width: "240px"}}
                                onClick={() => this.setState({...this.state, showErgebnisse: false})}/>
                    </div>
                </ScrollPanel>
            </Sidebar>
            {this.state.showEditDialog &&
            <SpieltagEditDialog visible={this.state.showEditDialog}
                                spieltag={this.state.selectedSpieltag}
                                callBackRefresh={this.callBackRefresh}
                                callBackEditDialogClose={() => this.setState({showEditDialog: false, selectedSpieltag: undefined})}/>
            }
        </div>
    }
}

SpieltagOverview.propTypes = {};

export default SpieltagOverview