import React from 'react'

import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Messages} from 'primereact/messages';

import Table from "./playerTable";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";

import PlayerEditDialog from "./PlayerEditDialog";
import PropTypes from "prop-types";
import {MANDANTTYPES} from "../../../constants/mandantTypes";
import {InputText} from "primereact/inputtext";

class PlayerOverview extends React.Component {

    constructor() {
        super();
        this.state = {
            filter: "",
            spieler: [],
            showEditDialog:false,
        };

        this.editSpieler = this.editSpieler.bind(this);
        this.changeSpielerState = this.changeSpielerState.bind(this);
        this.callBackRefresh = this.callBackRefresh.bind(this);
        this.loadData = this.loadData.bind(this);
        this.showToast = this.showToast.bind(this);
        this.showError = this.showError.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    showToast(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    callBackRefresh() {
       this.loadData();
    }

    loadData() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.player + "/all",
            json => {
                this.setState({spieler: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Spielerdaten: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Spielerdaten: " + error.message);
            })
    }

    editSpieler(spieler) {
        this.setState({showEditDialog:true, selectedPlayer:spieler});
    }

    changeSpielerState(id) {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.player + "/changestate",
            {id},
            () => {
                this.showToast("Spielerstatus wurde geändert.");
                this.loadData();
            },
            responseNotOk => {
                this.showError("Fehler beim Ändern des Status: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Ändern des Status: " + error.message);
            })

    }

    neuenSpielerAnlegen() {
        this.setState({showEditDialog:true, selectedPlayer:undefined});
    }

    getSpieler() {
        let filter = this.state.filter;
        if( filter === null || filter === undefined || filter === "" || this.state.spieler === null || this.state.spieler === undefined) {
            return this.state.spieler;
        }
        let tmpSpielerListe = [];
        for (const tmpSpieler of this.state.spieler) {
            let enthaeltVorname = false;
            if( tmpSpieler.firstname !== null && tmpSpieler.firstname !== undefined) {
                enthaeltVorname = tmpSpieler.firstname.includes(filter);
            }
            let enthaeltName = false;
            if( tmpSpieler.name !== null && tmpSpieler.name !== undefined) {
                enthaeltName = tmpSpieler.name.includes(filter);
            }
            let enthaeltUsername = false;;
            if( tmpSpieler.username !== null && tmpSpieler.username !== undefined) {
                enthaeltUsername = tmpSpieler.username.includes(filter);
            }
            if( enthaeltName || enthaeltVorname || enthaeltUsername) {
                tmpSpielerListe.push(tmpSpieler);
            }
        }
        return tmpSpielerListe;
    }

    render() {
        return <div style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="pageHeader" style={{backgroundColor:"#ff99c2"}}>
                Personen
            </div>
            {this.props.mandantType === MANDANTTYPES.TEAM.bezeichnung &&
                <div className="grid" style={{padding:20, marginLeft:0, marginRight:0}}>
                    <div className="col" style={{textAlign:"left"}}>
                        <Button label="Neuen Spieler anlegen" icon="pi pi-pencil" tooltip="Neuen Spieler anlegen" onClick={() => this.neuenSpielerAnlegen()}/>
                    </div>
                </div>
            }
            <div className="grid" style={{padding:20, marginLeft:0, marginRight:0}}>
                <div className="col" style={{textAlign:"left"}}>
                    <span style={{marginRight:10}}>Filter:</span>
                    <InputText value={this.state.filter} style={{width: "300px"}}
                               onChange={(e) => this.setState({filter:e.target.value})}/>
                </div>
            </div>
            <div className="grid" style={{padding:20, marginLeft:0, marginRight:0}}>
                <Table spieler={this.getSpieler()}
                       readonly={this.props.mandantType !== MANDANTTYPES.TEAM.bezeichnung }
                       changeSpielerState={this.changeSpielerState}
                       editSpieler={this.editSpieler}/>
            </div>
            {this.state.showEditDialog &&
                <PlayerEditDialog visible={this.state.showEditDialog}
                                  player={this.state.selectedPlayer}
                                  callBackRefresh={this.callBackRefresh}
                                  callBackEditDialogClose={() => this.setState({
                                      showEditDialog: false,
                                      selectedPlayer: undefined
                                  })}/>
            }
        </div>
    }

}

PlayerOverview.propTypes = {
    mandantType: PropTypes.string.isRequired,
};

export default PlayerOverview