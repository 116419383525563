import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Fieldset} from "primereact/fieldset";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";

function SpielbarePhaseDialog({tournamentId, visible, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [loaded, setLoaded] = useState(false);
    let [phase, setPhase] = useState(undefined);

    useEffect(() => {
        if( loaded) {
            save();
        }
    }, [phase]);

    useEffect(() => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/spielbarephase/" + tournamentId,
            wert => {
                setPhase(wert);
                setLoaded(true);
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
            },
            error => {
                setErrorMessage(error.message);
            })
    }, []);

    const save = () => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/spielbarephase/"  + tournamentId,
            {value: phase},
            () => {
                setInfoMessage("Erfolgreich gespeichert.")
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
            },
            error => {
                setErrorMessage(error.message);
            })
    }

    return <DHDialog onClose={() => onClose()} title="Spielbare Phase" show={visible} showCloseButton={true}
                     comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <Fieldset legend="Hinweis..." style={{marginBottom: 20}}>
            <div>Bei DKO Turnieren bezieht sich die Einstellung ausschliesslich auf die Gewinnerseite.</div>
            <div>Es gibt somit ein Finale, ein Halbfinale, zwei Viertelfinale usw.</div>
            <br/>
            <div>Sofern du 'keine Auswahl' triffst, können alle Spiele gespielt werden.</div>
            <div>Beispiel 1: du wählst 'Halbfinale', es kann somit alles bis einschließlich Halbfinale gespielt werden.</div>
            <div>Beispiel 2: du wählst 'Achtelfinale', es kann somit alles bis einschließlich Achtelfinale gespielt werden. Viertelfinale, Halbfinale und Finale könnte mit dieser Einstellung nicht gespielt werden.</div>
        </Fieldset>
        <div onClick={() => setPhase("")} style={{marginTop:20}}>
            {phase === "" && <img src="/images/check.png" style={{width: "30px", marginLeft: "20px"}} alt="ausgewählt"/>}
            keine Auswahl
        </div>
        <div onClick={() => setPhase("achtelfinale")} style={{marginTop:20}}>
            {phase === "achtelfinale" && <img src="/images/check.png" style={{width: "30px", marginLeft: "20px"}} alt="ausgewählt"/>}
            Achtelfinale
        </div>
        <div onClick={() => setPhase("viertelfinale")} style={{marginTop:20}}>
            {phase === "viertelfinale" && <img src="/images/check.png" style={{width: "30px", marginLeft: "20px"}} alt="ausgewählt"/>}
            Viertelfinale
        </div>
        <div onClick={() => setPhase("halbfinale")} style={{marginTop:20}}>
            {phase === "halbfinale" && <img src="/images/check.png" style={{width: "30px", marginLeft: "20px"}} alt="ausgewählt"/>}
            Halbfinale
        </div>
        <div onClick={() => setPhase("finale")} style={{marginTop:20}}>
            {phase === "finale" && <img src="/images/check.png" style={{width: "30px", marginLeft: "20px"}} alt="ausgewählt"/>}
            Finale
        </div>
    </>}/>
}

SpielbarePhaseDialog.propTypes = {
    tournamentId: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default SpielbarePhaseDialog;
