import React from "react";
import PropTypes from "prop-types";
import {Button} from 'primereact/button';
import {Fieldset} from "primereact/fieldset";
import DHDialog from "../../general/DHDialog";

class AnzahlWuerfeDialog extends React.Component {

    constructor(props) {
        super(props);

        this.keydownEventFunc = this.keydownEventFunc.bind(this);
        this.sendAnzahlWuerfe = this.sendAnzahlWuerfe.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    keydownEventFunc(event) {
        const sendAnzahlWuerfe = this.sendAnzahlWuerfe;
        switch (event.key) {
            case "F1":
            case "1":
                sendAnzahlWuerfe(1);
                break;
            case "F2":
            case "2":
                sendAnzahlWuerfe(2);
                break;
            case "F3":
            case "3":
                sendAnzahlWuerfe(3);
                break;
            default:
                event.preventDefault();
        }
        event.preventDefault();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( !prevProps.visible && this.props.visible) {
            window.addEventListener('keydown',  this.keydownEventFunc);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keydownEventFunc);
    }

    closeDialog() {
        window.removeEventListener('keydown', this.keydownEventFunc);
        this.props.callBackOnClose();
    }

    sendAnzahlWuerfe(anz) {
        this.props.sendAnzahlWuerfe(anz);
        this.closeDialog();
    }

    render() {
        if( !this.props.visible ) {
            return "";
        }
        return <DHDialog onClose={() => this.closeDialog()} title="Erfasse Würfe" show={this.props.visible} showCloseButton={true}
                         comp={<>
            Erfasse die Anzahl der Würfe die du zum 'finishen' gebraucht hast.<br/><br/>
            <div style={{fontWeight:"bold", marginBottom:20}}>Schnellaktion:
                <span style={{marginLeft:10, marginRight:10}}>F1 oder 1 = 1</span>
                <span style={{marginLeft:10, marginRight:10}}>F2 oder 2 = 2</span>
                <span style={{marginLeft:10, marginRight:10}}>F3 oder 3 = 3</span>
            </div>
            <div>
                <Button label="1" style={{width:50, height:50, marginRight:5}} onClick={() => this.sendAnzahlWuerfe(1)}/>
                <Button label="2" style={{width:50, height:50, marginRight:5}} onClick={() => this.sendAnzahlWuerfe(2)}/>
                <Button label="3" style={{width:50, height:50, marginRight:5}} onClick={() => this.sendAnzahlWuerfe(3)}/>
            </div>
            <Fieldset legend="Hinweis..." style={{marginTop:20}}>
                <div>Als angemeldeter Benutzer kannst du diese Eingabe verhindern.</div>
                <div>Unter Einstellungen kannst du einstellen, dass diese Eingabe nicht mehr von dir verlangt wird.</div>
                <div>Damit wird automatisch immer der dritte Dart für dich eingegeben, allerdings ist dein Average dann nicht genau.</div>
            </Fieldset>
        </>}/>
    }
}


AnzahlWuerfeDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    callBackOnClose: PropTypes.func.isRequired,
    sendAnzahlWuerfe: PropTypes.func.isRequired,
};

export default AnzahlWuerfeDialog;
