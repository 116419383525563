import React, {useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import FetchUtil from "../util/FetchUtil";
import ConfigUtil from "../util/ConfigUtil";
import {Checkbox} from "primereact/checkbox";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import ErrorInfoComponent from "./general/ErrorInfoComponent";
import DHDefaultDrawer from "./general/DHDefaultDrawer";
import DHButtonClose from "./general/DHButtonClose";

export default function RegistrierenDialog({onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [agb, setAgb] = useState(false);
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [name, setName] = useState("");
    const [firstname, setFirstname] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [saving, setSaving] = useState(false);


    const handelKeyDown = (e) => {
        if (e.keyCode === 13) {
            // enter
            this.save();
        }
    }

    const save = () => {
        setErrorMessage(undefined);

        let alleFelderGefuellt = checkInput();
        if (!alleFelderGefuellt) {
            return;
        }

        setSaving(true);
        let url = ConfigUtil.getConfig().resourceUrls.user + "/registration";
        let data = {
            email: email,
            username: username,
            name: name,
            firstname: firstname,
            password1: password1,
            password2: password2,
        };
        FetchUtil.fetchPost(url,
            data,
            json => {
                setInfoMessage("Bitte prüfe deine Mails (auch SPAM) und bestätige deine Registrierung innerhalb von 2 Stunden. Danach läuft deine Registrierung ab und du musst eventuell eine weitere Registrierung durchführen.");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSaving(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setSaving(false);
            }
        );
    }

    const checkInput = () => {
        let alleFelderGefuellt = name !== "" && firstname !== "" && username !== "" &&
            email !== "" && password1 !== "" && password2 !== "" && agb;
        return alleFelderGefuellt;
    }


    return <DHDefaultDrawer title="Registrierung" onClose={() => onClose()} show={true} children={<div style={{padding:20}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>

        <div className="col-12 md:col-12 lg:col-8">
            <div className="grid">
                <div className="col-12 md:col-6 lg:col-12">
                    <InputText value={username} style={{width: "100%"}}
                               placeholder="Benutzername"
                               onChange={(e) => setUsername(e.target.value)}
                               onKeyDown={(e) => handelKeyDown(e)}/>
                </div>
                <div className="col-12 md:col-6 lg:col-12">
                    <InputText value={firstname} style={{width: "100%"}}
                               placeholder="Vorname"
                               onChange={(e) => setFirstname(e.target.value)}
                               onKeyDown={(e) => handelKeyDown(e)}/>
                </div>
                <div className="col-12 md:col-6 lg:col-12">
                    <InputText value={name} style={{width: "100%"}}
                               placeholder="Nachname"
                               onChange={(e) => setName(e.target.value)}
                               onKeyDown={(e) => handelKeyDown(e)}/>
                </div>
                <div className="col-12 md:col-6 lg:col-12">
                    <InputText value={email} style={{width: "100%"}}
                               placeholder="Email"
                               onChange={(e) => setEmail(e.target.value)}
                               onKeyDown={(e) => handelKeyDown(e)}/>
                </div>
                <div className="col-12 md:col-6 lg:col-12">
                    <Password value={password1}
                              placeholder="Kennwort" toggleMask={true}
                              onChange={(e) => setPassword1(e.target.value)}
                              onKeyDown={(e) => handelKeyDown(e)}
                              feedback={true}/>
                </div>
                <div className="col-12 md:col-6 lg:col-12">
                    <Password value={password2}
                              placeholder="Kennwort wiederholen" toggleMask={true}
                              onChange={(e) => setPassword2(e.target.value)}
                              onKeyDown={(e) => handelKeyDown(e)}
                              feedback={false}/>
                </div>
            </div>
        </div>
        <div className="col-12 md:col-12 lg:col-12">
            Mit dem Klick auf "Registrieren" willige ich in die Verarbeitung meiner personenbezogenen Daten gemäß der
            <NavLink to={NAVIGATION_ITEM.DATENSCHUTZ.route} style={{textDecoration: "none"}}>
                <span style={{marginLeft: 5, marginRight: 5}}>Datenschutzerklärung</span>
            </NavLink>von darthelfer.de ein.
            <br/>
            <br/>
            <Checkbox checked={agb}
                      onChange={() => setAgb(!agb)} style={{marginRight:5}}/>
            <label className="p-checkbox-label">Ich stimme der
                <NavLink to={NAVIGATION_ITEM.AGB.route} style={{textDecoration: "none"}}>
                    <span style={{marginLeft: 5, marginRight: 5}}>AGB</span>
                </NavLink>
                von darthelfer.de zu.</label>
        </div>
        <div className="col-12 md:col-12 lg:col-12" style={{marginTop: 10}}>
            <div className="grid">
                <div className="col">
                    <Button label="Registrieren"
                            icon="pi pi-user-plus"
                            disabled={!checkInput() || saving}
                            type="button" onClick={() => save()} style={{marginRight: 5}}/>
                    <DHButtonClose onClick={() => onClose()}/>
                </div>
            </div>
        </div>
    </div>}/>
}
