import React, {useEffect, useState} from 'react'
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import UserStatistikGamesTable from "../public/UserStatistikGamesTable";
import DHDatePicker from "../../general/DHDatePicker";
import StatistikDialog from "../public/tournaments/StatistikDialog";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import DateUtil from "../../../util/DateUtil";

export default function UserStatstik({userId}) {

    let aktuellesDatum = new Date();
    aktuellesDatum.setMonth(aktuellesDatum.getMonth() - 1);
    var vDatum = new Date(aktuellesDatum.getFullYear(), aktuellesDatum.getMonth(), 1);
    let bDatum = new Date();
    let [vonDatum, setVonDatum] = useState(vDatum);
    let [bisDatum, setBisDatum] = useState(bDatum);

    const [tabValue, setTabValue] = useState("statistik");
    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [games, setGames] = useState([]);
    const [statistik, setStatistik] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [selectedStatistik, setSelectedStatistik] = useState(false);
    const [selectedStatistikLabel, setSelectedStatistikLabel] = useState("");

    useEffect(() => {
        loadGames(vonDatum, bisDatum);
        loadStatistikDaten();
    }, [vonDatum, bisDatum]);

    const loadStatistikDaten = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.statistics + "/profilstatistik",
            json => {
                setStatistik({...json});
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const loadGames = (von, bis) => {
        setErrorMessage(undefined);
        if (von === null || von === undefined || bis === null || bis === undefined) {
            return;
        }
        let data = {von: von, bis: bis};
        let url = ConfigUtil.getConfig().resourceUrls.game + "/games/" + userId;
        setLoading(true);
        setGames([]);
        FetchUtil.fetchPost(url,
            data,
            json => {
                setGames([...json]);
                setLoading(false);
            },
            responseNotOk => {
                setLoading(false);
                setErrorMessage("Fehler beim Laden der Gamedaten: " + responseNotOk.message);
            },
            error => {
                setLoading(false);
                setErrorMessage("Fehler beim Laden der Gamedaten: " + error.message);
            }
        );
    }
    const erzeugeTab = (value, key) => {
        return <div key={"key_tab_" + key} style={{
            fontSize: 20, width: "50%", marginLeft: 5, cursor: "pointer",
            borderBottom: tabValue === key ? "2px solid red" : "1px dashed  red", textAlign: "center"
        }}
                    onClick={() => setTabValue(key)}>
            {value ? value.toUpperCase() : "Gast"}
        </div>
    }

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Statistik", "statistik"));
        returnValue.push(erzeugeTab("Games", "games"));
        return returnValue;
    }

    const getStatistikRow = (label, detailStatistik) => {
        return <>
            <div style={{marginTop: 20, fontWeight: "bold"}}>{label}</div>
            {detailStatistik && detailStatistik.id !== null && <>
                <div style={{marginTop: 10, display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Button icon={PrimeIcons.SEARCH} onClick={() => {
                        setSelectedStatistik(detailStatistik);
                        setSelectedStatistikLabel(label);
                    }} style={{marginRight: 10}}/>
                    Average: {detailStatistik.average} / First 9 Avg.: {detailStatistik.first9Average}<br/>
                </div>
                <div style={{marginTop: 5}}>
                    Spiele G / U / V : {detailStatistik.gewonneneSpiele} / {detailStatistik.unentschiedenSpiele} / {detailStatistik.verloreneSpiele}
                </div>
                <div style={{marginTop: 10, fontSize:10}}>
                    Erstellt: {DateUtil.localDateTimeToDateTimeString(detailStatistik.timestamp)}
                </div>
            </>}
        </>

    }

    const getStatistikTab = () => {
        if (!statistik) {
            return "";
        }
        return <div>
            <div style={{fontSize:12}}>
                * In die Spielerstatistik werden nur Spiele mit dem Modus 501 D.O. einberechnet.
            </div>
            {getStatistikRow("Letzten 30 Tage", statistik.dreissigTage)}
            {getStatistikRow("Letzten 60 Tage", statistik.sechzigTage)}
            {getStatistikRow("Letzten 90 Tage", statistik.neunzigTage)}
            {getStatistikRow("Letzten 180 Tage", statistik.hundertachzigTage)}
            {getStatistikRow("Dieses Jahr", statistik.diesesJahr)}
            {getStatistikRow("Letztes Jahr", statistik.letztesJahr)}
            {getStatistikRow("Gesamt Statistik", statistik.gesamtStatistik)}
            {selectedStatistik  &&
                <StatistikDialog statistik={selectedStatistik}
                                 showPlayerGesamtStatistik={true}
                                 name={selectedStatistikLabel}  onClose={() => setSelectedStatistik(false)}/>}
        </div>
    }

    const getGamesTab = () => {
        return <>
            <div style={{padding:20,  textAlign:"left"}}>
                {loading && <div className="col-fixed" style={{width: 50}}><i className="pi pi-spin pi-spinner" style={{fontSize: 50, marginLeft: 30}}/></div>}
                <div style={{fontWeight:"bold"}}>
                    Filter
                </div>
                <div style={{display: "flex", alignItems: "center", marginTop: 15}}>
                    <span style={{width: 70}}>Von:</span><DHDatePicker disabled={false} value={vonDatum} changeValue={setVonDatum}/>
                </div>
                <div style={{display: "flex", alignItems: "center", marginTop:5}}>
                    <span style={{width: 70}}>Bis:</span><DHDatePicker disabled={false} value={bisDatum} changeValue={setBisDatum}/>
                </div>
            </div>
            <div className="grid" style={{padding:10}}>
                <UserStatistikGamesTable games={games}/>
            </div>
        </>
    }

    const getTabArea = () => {
        if( tabValue === "statistik" ) {
            return getStatistikTab();
        } else if( tabValue === "games") {
            return getGamesTab();
        } else {
            return "-";
        }
    }
    return <div
        style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div className="pageHeader" style={{backgroundColor: "#ef6c31"}}>
            Statistik / Games
        </div>
        <div style={{width: "100%", padding: 20}}>
            <div style={{display: "flex", marginTop: 20}}>
                {getTabs()}
            </div>
        </div>
        <div style={{padding:20}}>
            {getTabArea()}
        </div>
    </div>
}

