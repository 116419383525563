import React, {useEffect, useState} from 'react'
import KachelOverviewContainer from "../../../containers/views/start/KachelOverviewContainer";
import LigaDashboardContainer from "../../../containers/views/start/LigaDashboardContainer";
import {MANDANTTYPES} from "../../../constants/mandantTypes";
import PropTypes, {instanceOf} from "prop-types";
import {Cookies} from "react-cookie";
import VeranstalterDashboardContainer from "../../../containers/views/veranstalter/VeranstalterDashboardContainer";
import DashboardSelectionArea from "./DashboardSelectionArea";
import NewsArea from "../../general/NewsArea";
import {getFromLocalStorageWithDefault} from "../../../util/localStorageZugriff";

function Dashboard({mandantType, cookies}) {

    let [showNews, setShowNews] = useState(true);
    let [area, setArea] = useState(undefined);

    let ligaDashboard = mandantType === MANDANTTYPES.DEFAULT.bezeichnung;

    useEffect(() => {
        setArea(getDashboardArea());
    }, []);

    const getDashboardArea = () => {
        return getFromLocalStorageWithDefault("dh_dashboard", undefined);
    }

    if( ligaDashboard && !area) {
        return <DashboardSelectionArea isOnlineDashboard={ligaDashboard} onChange={(tmp) => setArea(tmp)}/>
    }


    return <div>
        {ligaDashboard && <DashboardSelectionArea isOnlineDashboard={ligaDashboard && area === "online"} onChange={(tmp) => setArea(tmp)}/>}
        {ligaDashboard && area === "online" && <LigaDashboardContainer/>}
        {ligaDashboard && area === "eventoperator" && <VeranstalterDashboardContainer onDashboardChange={(tmp) => setArea(tmp)}/>}
        {!ligaDashboard && <KachelOverviewContainer/>}
        {showNews && <NewsArea onClose={() => setShowNews(false)}/>}
    </div>
}

Dashboard.propTypes = {
    mandantType: PropTypes.string.isRequired,
    cookies: instanceOf(Cookies).isRequired,
};

export default Dashboard
