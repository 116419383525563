import React from 'react'
import {Messages} from "primereact/messages";
import {Toast} from 'primereact/toast';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {getModusNameById} from "../../general/GameModusComponent";
import {TabPanel, TabView} from "primereact/tabview";
import {
    Sponsoren,
    Tabelle171er,
    Tabelle180er,
    TabelleAverage,
    TabelleBestDarts,
    TabelleHighfinishes,
    TabelleHighscores,
    TabelleLowDarts,
    TabelleWeekendLeague,
    TabelleWorstDarts,
} from "./ligaComponents";
import {Button} from "primereact/button";
import LigaTeilnehmerArea from "./LigaTeilnehmerArea";
import PropTypes from "prop-types";
import LigaZuschauenArea from "./LigaZuschauenArea";
import Chat from "../../general/Chat";
import ConfirmKennwortDialog from "../../general/ConfirmKennwortDialog";
import ConfirmDialog from "../../general/ConfirmDialog";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import {Panel} from "primereact/panel";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import StatisticGameDetailDialog from "../statistics/StatisticGameDetailDialog";
import SoundComponent from "../../general/SoundComponent";
import PublicTournamentStatistikTab from "../public/tournaments/PublicTournamentStatistikTab";
import {ProgressSpinner} from "primereact/progressspinner";
import TournamentAuslosungDialog from "../tournament/auslosung/TournamentAuslosungDialog";

let stompClient = undefined;

class WeekendLeagueDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {readonly: false, zeigeBeschreibungVoll: false,
            showKennwortDialog:false,kennwort:"",
            showTournamentStartDialog: false, playSound: false};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
        this.getSpielerName = this.getSpielerName.bind(this);
        this.getUsernamesByUserId = this.getUsernamesByUserId.bind(this);
        this.isSpielerInaktiv = this.isSpielerInaktiv.bind(this);
        this.websocketConnect = this.websocketConnect.bind(this);
        this.websocketDisConnect = this.websocketDisConnect.bind(this);

    }

    websocketDisConnect() {
        if( stompClient !== null && stompClient !== undefined) {
            stompClient.disconnect(() => {});
        }
    }

    websocketConnect(turnierid) {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                const destinationLogin = "/topic/public/weekendleague/" + this.props.userId + "/" + turnierid;
                console.log("Websocket - connect success");
                console.log("subscribe to " + destinationLogin);
                stompClient.subscribe(destinationLogin, (payload) => {
                    const body = JSON.parse(payload.body);
                    this.setState({onlineUsers: body.onlineUsers,
                        gamesInviter: body.gamesInviter,
                        gamesInvited: body.gamesInvited,
                        games: body.games,
                        players: body.players});
                });
                const destinationPing = "/topic/public/weekendleagueping/" + this.props.userId;
                console.log("subscribe to " + destinationPing);
                stompClient.subscribe(destinationPing, () => {
                    this.setState({playSound: true})});
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    componentDidMount() {
        this.ladeDaten();
    }

    componentWillUnmount() {
        this.websocketDisConnect();
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }


    ladeDaten() {
        this.messages.clear();
        this.ladeTurnierDaten();
    }

    einladen(userId) {
        this.messages.clear();
        let id = this.state.turnier.id;
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.weekendleague + "/einladen/" + id + "/" + userId,
            {},
            json => {
                this.showInfo("Spieler wurde eingeladen.");
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
                this.setState({showTournamentStartDialog: false});
            },
            error => {
                this.showError("Fehler: " + error.message);
                this.setState({showTournamentStartDialog: false});
            }
        );
    }

    einladungLoeschen(id) {
        this.messages.clear();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.weekendleague + "/einladungloeschen/" + id,
            {},
            json => {
                this.showInfo("Einladung wurde gelöscht.");
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
                this.setState({showTournamentStartDialog: false});
            },
            error => {
                this.showError("Fehler: " + error.message);
                this.setState({showTournamentStartDialog: false});
            }
        );
    }

    einladungAnnehmen(id) {
        this.messages.clear();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.weekendleague + "/einladungannehmen/" + id,
            {},
            json => {
                this.showInfo("Einladung wurde angenommen.");
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
                this.setState({showTournamentStartDialog: false});
            },
            error => {
                this.showError("Fehler: " + error.message);
                this.setState({showTournamentStartDialog: false});
            }
        );
    }

    gameLoeschen() {
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.weekendleague + "/loeschegame/" + this.state.selectedDeleteGameId,
            {},
            () => {
                this.showInfo("Game wurde gelöscht.");
                this.setState({showGameDeleteDialog: false, selectedDeleteGameId:undefined});
                this.ladeDaten();
            },
            responseNotOk => {
                this.showError("Fehler beim Löschen: " + responseNotOk.message);
                this.setState({showGameDeleteDialog: false, selectedDeleteGameId:undefined});
            },
            error => {
                this.showError("Fehler beim Löschen: " + error.message);
                this.setState({showGameDeleteDialog: false, selectedDeleteGameId:undefined});
            }
        );
    }

    teilnahmeAendern() {
        this.messages.clear();
        let mussKennwortAbgefragtWerden = this.state.turnier.kennwort !== null && this.state.turnier.kennwort !== undefined && this.state.turnier.kennwort !== "";
        if( mussKennwortAbgefragtWerden && this.state.kennwort === "") {
            this.setState({showKennwortDialog:true});
            return
        }

        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.weekendleague + "/teilnahmeAendern?tournamentid=" + this.state.turnier.id + "&kennwort=" + this.state.kennwort,
            {},
            message => {
                if( message === "") {
                    this.showInfo("Speichern war erfolgreich!")
                    this.ladeDaten();
                } else {
                    this.showError(message);
                    this.setState({showKennwortDialog: false, kennwort:""});
                }
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
                this.setState({showKennwortDialog: false, kennwort:""});
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
                this.setState({showKennwortDialog: false, kennwort:""});
            }
        );
    }

    teilnahmeBeenden() {
        this.messages.clear();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.weekendleague + "/teilnahmeAendern?tournamentid=" + this.state.turnier.id,
            {},
            json => {
                this.showInfo("Speichern war erfolgreich!")
                this.ladeDaten();
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );
    }


    ladeTurnierDaten() {
        if (this.props.turnierid === null || this.props.turnierid === undefined) {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.weekendleague + "/lade/" + this.props.turnierid;
        FetchUtil.fetchGet(url,
            json => {
                let turnier = json.turnier;

                let readonly = turnier.tournamentstatus === "abgeschlossen";
                let started = turnier.tournamentstatus === "gestartet";

                const userInPlayers = this.isAktuellerUserInPlayersVorhanden(turnier.tournamentPlayerEntities);
                if( userInPlayers && started) {
                    this.websocketConnect(turnier.id);
                }

                this.setState({turnier: turnier,
                    players: turnier.tournamentPlayerEntities,
                    tabelle: json.tabelle,
                    allGames: json.games,
                    usernames: json.usernameDtos,
                    anzahlGames: json.anzahlGames,
                    anzahlLegs: json.anzahlLegs,
                    gameMeetingKeyDtos: json.gameMeetingKeyDtos,
                    ranglisteHighfinishes: json.ranglisteWrapper.ranglisteHighfinishes,
                    ranglisteHighscores: json.ranglisteWrapper.ranglisteHighscores,
                    rangliste180er: json.ranglisteWrapper.rangliste180er,
                    rangliste171er: json.ranglisteWrapper.rangliste171er,
                    ranglisteAverage: json.ranglisteWrapper.ranglisteAverage,
                    bestDarts: json.ranglisteWrapper.bestDarts,
                    lowDarts: json.ranglisteWrapper.lowDarts,
                    worstDarts: json.ranglisteWrapper.worstDarts,
                    statistik: json.ranglisteWrapper.statistikDto,
                    playerStatistiks: json.ranglisteWrapper.playerStatistiks,
                    readonly: readonly,
                    showKennwortDialog: false,
                    kennwort:"",
                    });
            },
            responseNotOk => {
                this.showError("Fehler beim Laden des Turniers: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden des Turniers: " + error.message);
            });
    }

    getUsernamesByUserId(userId) {
        for (const username of this.state.usernames) {
            if (username.userId === userId) {
                return username;
            }
        }
        return undefined;
    }

    isAktuellerUserAdministrator() {
        if( this.state.turnier.erstellerUserId === this.props.userId) {
            return true;
        }
        for (const admin of this.state.turnier.administratoren) {
            if (admin.userId === this.props.userId) {
                return true;
            }
        }
        return false;
    }

    isAktuellerUserInPlayersVorhanden(players) {
        if (players === null || players === undefined) {
            return false;
        }

        for (const player of players) {
            if (player.userId === this.props.userId && !player.inaktiv) {
                return true;
            }
        }

        return false;
    }

    isAktuellerUserInLigaVorhanden() {
        return this.isAktuellerUserInPlayersVorhanden(this.state.players);
    }

    getTurnierBetreiber() {
        for (const leagueoperator of this.props.leagueoperators) {
            if( leagueoperator.id === this.state.turnier.leagueOperatorId) {
                return leagueoperator;
            }
        }
        return undefined;
    }

    getTurnierDetailArea() {
        if (this.state.turnier === null || this.state.turnier === undefined) {
            return <div>Es sind noch keine Daten geladen...</div>
        }

        let ligaBetreiber = this.getTurnierBetreiber();
        let ligaBetreiberName = "";
        let ligaBetreiberHomepage = "";
        let ligaBetreiberSocialmedia = "";
        if( ligaBetreiber !== undefined) {
            ligaBetreiberName = ligaBetreiber.name + " / " + ligaBetreiber.shortname;
            ligaBetreiberHomepage = ligaBetreiber.homepage;
            ligaBetreiberSocialmedia = ligaBetreiber.socialmedia;
        }

        let turnier = this.state.turnier;
        let ftString = "";
        let boString = "";
        let tournamentModusEntity = turnier.tournamentModusEntities[0];
        if( tournamentModusEntity.firstToLegs !== null && tournamentModusEntity.firstToLegs !== undefined) {
            ftString = tournamentModusEntity.firstToLegs;
        } else {
            boString = tournamentModusEntity.bestOfLegs;
        }
        let tournamentModus = turnier.tournamentModusEntities[0];

        let fixedColumWidth = 140;
        return <div style={{textAlign: "left"}}>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Bezeichnung:</div>
                        <div className="col">{turnier.bezeichnung}</div>
                    </div>
                    {turnier.tournamentSponsorenEntities.length > 0 && <Sponsoren sponsoren={turnier.tournamentSponsorenEntities} />}
                    {ligaBetreiberName !== "" && <>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Turnierbetreiber:</div>
                            <div className="col">{ligaBetreiberName}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}></div>
                            <div className="col">
                                {ligaBetreiberHomepage ? <a href={ligaBetreiberHomepage} target="_blank" rel="noopener noreferrer" style={{color: "white"}}><img src="/images/homepage.png" alt="Homepage" style={{width:30}}/></a> :"" }
                                {ligaBetreiberSocialmedia ? <a href={ligaBetreiberSocialmedia} target="_blank" rel="noopener noreferrer" style={{color: "white"}}><img src="/images/facebook.png" alt="Facebook" style={{marginLeft:10, width:30}}/></a> :"" }
                            </div>
                        </div>
                    </>
                    }
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Startzeitpunkt:</div>
                        <div className="col">{turnier.beginnMitUhrzeitString}</div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Endezeitpunkt:</div>
                        <div className="col">{turnier.endeMitUhrzeitString}</div>
                    </div>
                    {this.getBeschreibungArea(fixedColumWidth)}
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Ersteller:</div>
                        <div className="col">{turnier.erstellerName}</div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Turnierleitung:</div>
                        <div className="col">{this.getAdministratoren()}</div>
                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Turnierstatus:</div>
                        <div className="col">{turnier.tournamentstatus}</div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>GameType:</div>
                        <div className="col">{turnier.gameType}</div>
                    </div>
                    {turnier.gameType === "cricket" && <>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Cutthroat:</div>
                            <div className="col">{tournamentModus.cutthroat ? "ja" : "nein"}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Zufallszahlen:</div>
                            <div className="col">{tournamentModus.zufallszahlen ? "ja" : "nein"}</div>
                        </div>
                        {tournamentModus.zufallszahlen && <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Zufallsz. j. Leg:</div>
                            <div className="col">{tournamentModus.zufallszahlenjedesleg ? "ja" : "nein"}</div>
                        </div>
                        }
                    </>
                    }
                    {turnier.gameType === "x01" && <>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Modus:</div>
                            <div className="col">{getModusNameById(tournamentModus.modusid)}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Startpunkte:</div>
                            <div className="col">{tournamentModus.startpunkte}</div>
                        </div>
                    </>}
                    {ftString &&
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>first to legs:</div>
                        <div className="col">{ftString}</div>
                    </div>
                    }
                    {boString &&
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>best of legs:</div>
                        <div className="col">{boString}</div>
                    </div>
                    }
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Anzahl Spieler:</div>
                        <div
                            className="col">{this.state.players !== null && this.state.players !== undefined ? this.state.players.length : "0"}</div>
                    </div>
                    {(turnier.maxAnzahlTeilnehmer !== null && turnier.maxAnzahlTeilnehmer !== undefined) &&
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Max. Anzahl Teilnehmer:</div>
                        <div
                            className="col">{turnier.maxAnzahlTeilnehmer}</div>
                    </div>
                    }

                </div>
            </div>
            <div className="grid" style={{marginTop:10}}>
                {turnier.tournamentstatus !== "abgeschlossen" && !this.isAktuellerUserInLigaVorhanden() &&
                <Button label="Am Turnier teilnehmen" icon="pi pi-plus" onClick={() => this.teilnahmeAendern()}
                        style={{height: 40, width: 350, marginLeft: 10, marginRight: 10}}/>
                }
                {turnier.tournamentstatus === "neu" && this.isAktuellerUserInLigaVorhanden() &&
                <Button label="Aus Turnier austreten, nicht teilnehmen" icon="pi pi-minus"
                        onClick={() => this.teilnahmeBeenden()}
                        style={{height: 40, width: 350, marginLeft: 10, marginRight: 10}}/>
                }
                {turnier.tournamentstatus === "neu" && this.isAktuellerUserAdministrator() &&
                    <Button label="Turnier starten" icon="pi pi-align-justify"
                            onClick={() => this.setState({showTournamentStartDialog: true})}
                            style={{height: 40, width: 350, marginLeft: 10, marginRight: 10}}/>
                }
            </div>
        </div>
    }

    getHightlightsArea() {
        return <div className="grid">
            <Tabelle180er rangliste180er={this.state.rangliste180er} getSpielerName={this.getSpielerName}/>
            <Tabelle171er rangliste171er={this.state.rangliste171er} getSpielerName={this.getSpielerName}/>
            <TabelleHighfinishes ranglisteHighfinishes={this.state.ranglisteHighfinishes} getSpielerName={this.getSpielerName}/>
            <TabelleHighscores ranglisteHighscores={this.state.ranglisteHighscores} getSpielerName={this.getSpielerName}/>
            <TabelleLowDarts lowDarts={this.state.lowDarts}  getSpielerName={this.getSpielerName} />
            <TabelleBestDarts bestDarts={this.state.bestDarts}  getSpielerName={this.getSpielerName} />
            <TabelleWorstDarts worstDarts={this.state.worstDarts}  getSpielerName={this.getSpielerName} />
            <TabelleAverage ranglisteAverage={this.state.ranglisteAverage} getSpielerName={this.getSpielerName}/>
        </div>;
    }

    getAllGamingArea() {
        if( this.state.allGames === null || this.state.allGames === undefined || this.state.allGames.length === 0) {
            return <div>Keine Spiele vorhanden!!!</div>;
        }
        let games = this.state.allGames.filter(game => game.anzahlSpieler1 !== null);
        if( games.length === 0) {
            return <div>Keine Spiele vorhasdfasdfsaanden!!!</div>;
        }
        let rows = [];
        for (const game of games) {
            let usernameObject1 = this.getUsernamesByUserId(game.user1Id);
            let username1 = "";
            if( usernameObject1 !== null && usernameObject1 !== undefined ) {
                // es kam vor, dass usernameObject nicht gefunden wurde, vermutlich noch nicht geladen war. Deshalb diese Sicherheitsgeschichte hier.
                username1 = usernameObject1.name + " - " + usernameObject1.username;
            }
            let usernameObject2 = this.getUsernamesByUserId(game.user2Id);
            let username2 = "";
            if( usernameObject2 !== null && usernameObject2 !== undefined ) {
                // es kam vor, dass usernameObject nicht gefunden wurde, vermutlich noch nicht geladen war. Deshalb diese Sicherheitsgeschichte hier.
                username2 = usernameObject2.name + " - " + usernameObject2.username;
            }
            rows.push(<div>
                <div style={{marginTop: 10}}>
                    <Button icon="pi pi-search-plus"
                            onClick={() => this.setState({showGameDetailDialog: true, selectedGameId:game.gameId})}
                            tooltip="Zeige Details" style={{marginRight:10}}/>
                    {username1}  <span style={{fontWeight:"bold", marginLeft:10, marginRight:10}}>{game.anzahlSpieler1} : {game.anzahlSpieler2}</span> {username2}</div>
            </div>)
        }
        return <div style={{textAlign:"left"}}>{rows}</div>;
    }

    getGamingArea() {
        let rowsUsersOnline = [];
        let rowsGamesInviter = [];
        let rowsGamesInvited = [];
        if( this.state.onlineUsers !== undefined && this.state.onlineUsers !== null ) {
            for (const onlineUser of this.state.onlineUsers) {
                let existiertEinladungOderWurdeEingeladen = this.existiertEinladungOderWurdeErEingeladen(onlineUser.userId);
                let isSpielbar = onlineUser.spielbar;
                let usernameObject = this.getUsernamesByUserId(onlineUser.userId)
                let username = "";
                if( usernameObject !== null && usernameObject !== undefined ) {
                    // es kam vor, dass usernameObject nicht gefunden wurde, vermutlich noch nicht geladen war. Deshalb diese Sicherheitsgeschichte hier.
                    username = usernameObject.name + " - " + usernameObject.username + " Avg.: " + usernameObject.average;
                }
                rowsUsersOnline.push(<div className="grid" key={"key_online_user_" + onlineUser.userId}><div className="col">
                    {(isSpielbar && !existiertEinladungOderWurdeEingeladen)  && <Button icon="pi pi-arrow-circle-right"
                            onClick={() => this.einladen(onlineUser.userId)}
                            style={{marginRight: 10, marginTop: 5}}
                            tooltip={"Spieler einladen"}/>
                    }
                    {!isSpielbar && <img src="/images/delete_color.png" alt="Löschen" style={{width: 20, marginLeft:5, marginRight:17}}/>
                    }
                    {username}</div>
                </div>);
            }
        }
        if (this.state.gamesInvited !== undefined && this.state.gamesInvited !== null) {
            for (const game of this.state.gamesInvited) {
                let usernameObject = this.getUsernamesByUserId(game.userIdInviter)
                let username = "";
                if (usernameObject !== null && usernameObject !== undefined) {
                    // es kam vor, dass usernameObject nicht gefunden wurde, vermutlich noch nicht geladen war. Deshalb diese Sicherheitsgeschichte hier.
                    username = usernameObject.name + " - " + usernameObject.username + " Avg.: " + usernameObject.average;
                }
                rowsGamesInvited.push(<div className="grid" key={"key_invited_" + game.id}>
                    <div className="col">
                        <Button icon="pi pi-trash"
                                onClick={() => this.einladungLoeschen(game.id)}
                                style={{marginRight: 10, marginTop: 5}}
                                tooltip={"Einladung ablehnen"}/>
                        <Button icon="pi pi-eye"
                                onClick={() => this.einladungAnnehmen(game.id)}
                                style={{marginRight: 10, marginTop: 5}}
                                tooltip={"Einladung annehmen"}/>
                        {username}
                    </div>
                </div>);
            }
        }
        if (this.state.gamesInviter !== undefined && this.state.gamesInviter !== null) {
            for (const game of this.state.gamesInviter) {
                let usernameObject = this.getUsernamesByUserId(game.userIdInvited)
                let username = "";
                if (usernameObject !== null && usernameObject !== undefined) {
                    // es kam vor, dass usernameObject nicht gefunden wurde, vermutlich noch nicht geladen war. Deshalb diese Sicherheitsgeschichte hier.
                    username = usernameObject.name + " - " + usernameObject.username + " Avg.: " + usernameObject.average;
                }
                rowsGamesInviter.push(<div className="grid"  key={"key_inviter_" + game.id}>
                    <div className="col">
                        <Button icon="pi pi-trash"
                                onClick={() => this.einladungLoeschen(game.id)}
                                style={{marginRight: 10, marginTop: 5}}
                                tooltip={"Einladung löschen"}/>
                        {username}
                    </div>
                </div>);
            }
        }

        if( rowsUsersOnline.length === 0 ) {
            rowsUsersOnline.push(<div className="grid"><div className="col">--- keine Teilnehmer spielbereit ---</div></div>);
        }
        if( rowsGamesInvited.length === 0 ) {
            rowsGamesInvited.push(<div className="grid"><div className="col">--- Keine Einladungen vorhanden ---</div></div>);
        }
        if( rowsGamesInviter.length === 0 ) {
            rowsGamesInviter.push(<div className="grid"><div className="col">--- Du hast keine Einladungen vergeben ---</div></div>);
        }
        return <div style={{textAlign:"left",}}>
            <Panel header="Erklärung">
                <div style={{marginBottom:10}}><i className="pi pi-eye" style={{marginRight:10}}/> Spielanfrage annehmen.</div>
                <div style={{marginBottom:10}}><i className="pi pi-trash" style={{marginRight:10}}/>Spielanfrage ablehnen oder Spieleinladung löschen.</div>
                <div style={{marginBottom:10}}><i className="pi pi-arrow-circle-right" style={{marginRight:10}}/>Spieleinladung absenden.</div>
                <div style={{marginBottom:10}}><img src="/images/dart_select.png" alt="Spiel starten" style={{width: 20, marginRight:10}}/>Spiel ist angelegt und kann gestartet werden.</div>
                <div style={{marginBottom:10}}><img src="/images/delete_color.png" alt="Heute bereits Spiel vorhanden" style={{width: 20, marginRight:10}}/>mit diesem Spieler wurde heute bereits gespielt, aktuell kein Spiel möglich.</div>
            </Panel>
            {this.getGamesArea()}
            <div className="grid">
                <div className="col-12 md:col-96 lg:col-4">
                    <h4>Ligateilnehmer die gerade spielbereit sind:</h4>
                    {rowsUsersOnline}
                </div>
                <div className="col-12 md:col-96 lg:col-4">
                    <h4>Spieler die dich eingeladen haben:</h4>
                    {rowsGamesInvited}
                </div>
                <div className="col-12 md:col-96 lg:col-4">
                    <h4>Spieler die du eingeladen hast:</h4>
                    {rowsGamesInviter}
                </div>
            </div>
        </div>;
    }

    getSpielerName(id) {
        if (id === undefined || this.state.turnier === null || this.state.turnier === undefined) {
            return "";
        }

        for (let i = 0; i < this.state.players.length; i++) {
            let spieler = this.state.players[i];
            if (spieler.userId === id || spieler.doppelpartnerId === id) {
                return spieler.name;
            }
        }
        return "Freilos";
    }

    getGamesBySpieltag(spieltag) {
        let liga = this.state.turnier
        let gruppenEintraege = liga.tournamentGroupGameEntities;
        let games = []
        if (gruppenEintraege === null || gruppenEintraege === undefined) {
            return games;
        }
        for (const eintrag of gruppenEintraege) {
            if (eintrag.spielrunde === spieltag) {
                games.push(eintrag);
            }
        }
        return games;

    }

    isSpielerInaktiv(spielerid) {
        for (const player of this.state.players) {
            if (player.id === spielerid && player.inaktiv) {
                return true;
            }
        }
        return false;
    }

    getStatistikArea() {
        return <div style={{backgroundColor: "#022836", color:"white", textAlign:"left", padding:5}}>
            <PublicTournamentStatistikTab tournamentId={this.state.turnier.id} participants={this.state.turnier.tournamentPlayerEntities}
                                          gesamtStatistik={this.state.statistik}
                                          playerStatistiks={this.state.playerStatistiks}/>
        </div>
    }

    render() {
        let turnier = this.state.turnier;
        if (turnier === null || turnier === undefined) {
            return <div>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <ProgressSpinner style={{width:100, height:100}}/>
            </div>
        }

        let tabs = [];

        if( this.isAktuellerUserAdministrator()) {
            tabs.push(<TabPanel header="Admin-Games" key="tab_admin_games">
                    {this.getAdminGamesTab()}
                </TabPanel>
            );
        }

        let isTurnierRunning = this.state.turnier.tournamentstatus !== "neu";
        let isTurnierAbgeschlossen = this.state.turnier.tournamentstatus === "abgeschlossen";
        let isUserInTurnierRelevant = this.isAktuellerUserInLigaVorhanden() || this.isAktuellerUserAdministrator();

        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="pageHeader" style={{backgroundColor: "#b3ccff"}}>
                Weekend League - Details
            </div>
            <div style={{paddingRight: 20, paddingLeft: 20}}>
                {this.getTurnierDetailArea()}
            </div>

            <TabView style={{width: "100%", paddingTop:10, paddingLeft:10, paddingRight:10, border:"none"}}>
                <TabPanel header="Teilnehmer">
                    {this.getTeilnehmerArea()}
                </TabPanel>
                <TabPanel header="Gaming" disabled={!isTurnierRunning || !isUserInTurnierRelevant}>
                    {this.getGamingArea()}
                </TabPanel>
                <TabPanel header="Tabelle" disabled={!isTurnierRunning}>
                    <TabelleWeekendLeague rangliste={this.state.tabelle} getSpielerName={this.getSpielerName} anzahlGames={this.state.anzahlGames} anzahlLegs={this.state.anzahlLegs}/>
                </TabPanel>
                <TabPanel header="Games" disabled={!isTurnierRunning}>
                    {this.getAllGamingArea()}
                </TabPanel>
                <TabPanel header="Highlights" disabled={!isTurnierRunning || this.state.turnier.gameType !== "x01"}>
                    {this.getHightlightsArea()}
                </TabPanel>
                <TabPanel header="Statistik" disabled={!isTurnierRunning || this.state.turnier.gameType !== "x01"}>
                    {this.getStatistikArea()}
                </TabPanel>
                <TabPanel header="Zuschauen" disabled={!isTurnierRunning || isTurnierAbgeschlossen}>
                    {this.getZuschauenArea()}
                </TabPanel>
                <TabPanel header="Chat" disabled={!isTurnierRunning || isTurnierAbgeschlossen || !isUserInTurnierRelevant}>
                    {this.getChat()}
                </TabPanel>
                {tabs}
            </TabView>
            {this.state.showGameDetailDialog &&
                <StatisticGameDetailDialog
                                           callBackDialogClose={() => this.setState({showGameDetailDialog:false, selectedGameId:undefined})}
                                           visible={true}
                                           gameId={this.state.selectedGameId} gameType={turnier.gameType}/>
            }
            <ConfirmKennwortDialog visible={this.state.showKennwortDialog}
                                   header="Turnierkennwort eingeben."
                                   textOben="Der Turnierbetreiber erwartet ein Kennwort zur Teilnahme."
                                   changeKennwort={(kennwort) => this.setState({kennwort: kennwort})}
                                   callBackOnAbbrechen={() => this.setState({showKennwortDialog:false})}
                                   callBackOnAbsenden={() => this.teilnahmeAendern()}/>
            {this.state.showTournamentStartDialog && <TournamentAuslosungDialog tournament={turnier}
                                                                          onClose={() => this.setState({showTournamentStartDialog: false})}
                                                                          onRefresh={() => this.ladeDaten()}/>
            }
            <ConfirmDialog visible={this.state.showGameDeleteDialog}
                           callBackOnNein={() => this.setState({showGameDeleteDialog: false})}
                           callBackOnJa={() => this.gameLoeschen()}
                           header="Spiel wirklich löschen"
                           textOben="Sobald du das Spiel löscht, wird dieses komplett gelöscht und ist nicht mehr aufrufbar!!! Dies sollte nur bei technischen Problemen oder ähnlichem gemacht werden."
                           textUnten="Möchtest du das Spiel wirklich löschen?"/>
            {this.state.playSound  && <SoundComponent loop={false}
                                                                    file={"dingdong.mp3"}
                                                                    onEnded={() => this.setState({playSound: false})}/>
            }
        </div>
    }

    getZuschauenArea() {
        return <LigaZuschauenArea tournamentId={this.state.turnier.id}/>
    }

    getChat() {
        return <Chat type="turnier" loadData={true} id={this.state.turnier.id} userId={this.props.userId}
                     callBackOnError={(message) => this.showError(message)}/>
    }

    getAdministratoren() {
        if (this.state.turnier === null || this.state.turnier === undefined || this.state.turnier.administratoren === null || this.state.turnier.administratoren === undefined) {
            return "";
        }
        let administratoren = "";
        for (const administrator of this.state.turnier.administratoren) {
            if( administratoren !== "") {
                administratoren += " - ";
            }
            administratoren += administrator.name;
        }
        return administratoren;
    }

    getTeilnehmerArea() {
        return <LigaTeilnehmerArea liga={this.state.turnier} players={this.state.turnier.tournamentPlayerEntities} showEditButton={true} readonly={this.state.readonly}
                                   getUsernamesByUserId={this.getUsernamesByUserId}
                                   callBackRefresh={this.ladeDaten}
                                   isVerschiebenErlaubt={false}
                                   showUsernames={!this.state.turnier.doppelturnier}
                                   isAdmin={this.isAktuellerUserAdministrator()}/>
    }

    getBeschreibungArea(fixedColumWidth) {
        if (this.state.turnier.beschreibung === null || this.state.turnier.beschreibung === undefined) {
            return "";
        }
        let beschreibungGekuerzt = false;
        let beschreibung;
        if (this.state.zeigeBeschreibungVoll || this.state.turnier.beschreibung.length <= 50) {
            beschreibung = this.state.turnier.beschreibung;
        } else {
            beschreibung = this.state.turnier.beschreibung.substring(0, 50);
            beschreibungGekuerzt = true;
        }
        return <div className="grid">
            <div className="col-fixed" style={{width: fixedColumWidth}}>Beschreibung:</div>
            <div className="col" style={{whiteSpace: "pre-line", cursor: "pointer"}}
                 onClick={() => this.setState({zeigeBeschreibungVoll: !this.state.zeigeBeschreibungVoll})}>
                {beschreibung}{beschreibungGekuerzt ? <span style={{color:"blue"}}><br/>... mehr Anzeigen ...</span> : ""}
            </div>
        </div>
    }

    getGamesArea() {
        if( this.state.games === null || this.state.games === undefined || this.state.games.length === 0) {
            return "";
        }
        let cols = [];
        for (const game of this.state.games) {
            let playerId = this.props.userId === game.user1Id ? game.spieler1 : game.spieler2;
            let userIdGegner = this.props.userId === game.user1Id ? game.user2Id : game.user1Id;

            let usernameObject = this.getUsernamesByUserId(userIdGegner)
            let username = "";
            if( usernameObject !== null && usernameObject !== undefined ) {
                // es kam vor, dass usernameObject nicht gefunden wurde, vermutlich noch nicht geladen war. Deshalb diese Sicherheitsgeschichte hier.
                username = usernameObject.name + " - " + usernameObject.username;
            }

            cols.push(<div className="col" key={"key_mygame_" + game.id} style={{textAlign:"left", marginLeft:20}}>
                <div style={{marginBottom: 5}}>
                    <NavLink
                        to={NAVIGATION_ITEM.SCORING.route + "?gameid=" + game.gameId + "&playerid=" + playerId}
                        style={{textDecoration: "none"}}>
                        <img src="/images/dart_select.png" alt="Spiel starten"
                             style={{cursor: "pointer", width: 30, marginRight: 5}}/>
                    </NavLink>
                    {username}
                    <Button icon="pi pi-trash"
                            onClick={() => this.setState({showGameDeleteDialog: true, selectedDeleteGameId: game.id})}
                            tooltip="Löschen" style={{marginLeft: 5}}/>
                </div>
            </div>)
        }

        return <div style={{marginTop:20, padding:20}}>
                <div className="grid"><div className="col" style={{textAlign: "left"}}>Deine aktuell anstehende Spiele, bitte gehe umgehend in dein aktuelles Spiel:</div></div>
            {cols.length === 0 && <div style={{textAlign: "left"}}>Aktuell sind keine Spiele für dich vorhanden</div>}
            {cols.length !== 0 && <div className="grid" style={{marginTop:10}}>{cols}</div>}
        </div>
    }

    existiertEinladungOderWurdeErEingeladen(userId) {
        if( this.state.gamesInviter !== null && this.state.gamesInviter !== undefined) {
            for (const game of this.state.gamesInviter) {
                if( game.userIdInviter === this.props.userId && game.userIdInvited === userId) {
                    return true;
                }
            }
        }
        if( this.state.gamesInvited !== null && this.state.gamesInvited !== undefined) {
            for (const game of this.state.gamesInvited) {
                if( game.userIdInviter === userId && game.userIdInvited === this.props.userId) {
                    return true;
                }
            }
        }
        return false;
    }

    getAdminGamesTab() {
        let games = [];
        for (const game of this.state.allGames) {
            if( game.anzahlSpieler1 !== null ) {
                // spiel ist bereits beendet
                continue;
            }
            let showMeetingKeyAllowed = this.hasPlayersMeetingkeyAllowed(game.spieler1, game.spieler2);
            let meetingKeyDto = this.state.gameMeetingKeyDtos.find(dto => dto.gameId === game.gameId);
            if( meetingKeyDto === null  || meetingKeyDto === undefined) {
                // Hintergrund: vermutlich vom Backend nicht geliefert, da der user ersteller ist und das hier angezeigt bekommt
                // aber kein turnierleiter und damit kommen die meetingkeys nicht vom server
                continue;
            }
            let meetingKey = this.state.gameMeetingKeyDtos.find(dto => dto.gameId === game.gameId).meetingKey;
            games.push(<div className="grid">
                <div className="col">
                    {this.getSpielerName(game.user1Id)} : {this.getSpielerName(game.user2Id)} - Meetingkey: {showMeetingKeyAllowed ? meetingKey : "Anzeige nicht erlaubt!!!"}
                </div>
            </div>);
        }
        return <div className="grid" style={{padding:20, textAlign:"left"}}>
            <div className="col">
                <div className="grid" style={{marginBottom:50}}>
                    <div className="col">
                        Hier bekommen Liga- und Turnierleiter alle aktuell vorhandenen und nicht abgeschlossenen Spiele angezeigt.<br/>Hintergrund ist, dass evtl.Meetingkeys zum Beobachten von Spiele bzw. Streaming freigegeben wurden.
                    </div>
                </div>
                {games}
            </div>
        </div>
    }

    hasPlayersMeetingkeyAllowed(playerId1, playerId2) {
        if( playerId1 === undefined || playerId1 === null || playerId2 === undefined || playerId2 === null) {
            return false;
        }
        let player1 = this.state.turnier.tournamentPlayerEntities.find(player => player.id === playerId1);
        let player2 = this.state.turnier.tournamentPlayerEntities.find(player => player.id === playerId2);
        let usernameObject1 = this.getUsernamesByUserId(player1.userId);
        let usernameObject2 = this.getUsernamesByUserId(player2.userId);
        return usernameObject1.showMeetingkey && usernameObject2.showMeetingkey;
    }

}

WeekendLeagueDetail.propTypes = {
    userId: PropTypes.string.isRequired,
    turnierid: PropTypes.string.isRequired,
    leagueoperators: PropTypes.any,
};

export default WeekendLeagueDetail
