import React from "react";
import DHDefaultDrawer from "./DHDefaultDrawer";
import DHButtonClose from "./DHButtonClose";

export default function InfoDialog({title, textArray, visible, onClose}) {

    const getFooter = () => {
        return <div>
            <DHButtonClose onClick={() => onClose()}/>
        </div>
    }

    const getRows = () => {
        let rows = [];
        let counter = 0;
        for (const text of textArray) {
            counter++;
            rows.push(<div key={"info_row_" + counter} style={{marginBottom:5}}>{text}</div>);
        }
        return rows;
    }

    if( !visible ) {
        return "";
    }

    return <DHDefaultDrawer onClose={() => onClose()} title={title} show={true} children={<div style={{padding:20}}>
        {getRows()}
        {getFooter()}
    </div>}/>
}
