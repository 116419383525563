import React, {useEffect, useState} from 'react'
import DHDialog from "../../general/DHDialog";
import DHButtonSave from "../../general/DHButtonSave";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHTextField from "../../general/DHTextField";
import DHNumberTextField from "../../general/DHNumberTextField";
import DHSwitch from "../../general/DHSwitch";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DHButtonNew from "../../general/DHButtonNew";

export default function LigaSpielortVerwaltenDialog({eventOperatorId, selectedSpielort, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [working, setWorking] = useState(false);

    const [id, setId] = useState(undefined);
    const [bezeichnung, setBezeichnung] = useState("");
    const [strasse, setStrasse] = useState("");
    const [plz, setPLZ] = useState("");
    const [ort, setOrt] = useState("");
    const [telefon, setTelefon] = useState("");
    const [anzahlSteeldartBoards, setAnzahlSteeldartBoards] = useState(0);
    const [anzahlEdartBoards, setAnzahlEdartBoards] = useState(0);
    const [raucher, setRaucher] = useState(false);
    const [aktiv, setAktiv] = useState(true);

    useEffect(() => {
        if( selectedSpielort ) {
            setId(selectedSpielort.id);
            setBezeichnung(selectedSpielort.bezeichnung);
            setStrasse(selectedSpielort.strasse);
            setPLZ(selectedSpielort.plz);
            setOrt(selectedSpielort.ort);
            setTelefon(selectedSpielort.telefon);
            setAnzahlSteeldartBoards(selectedSpielort.anzahlSteeldartBoards);
            setAnzahlEdartBoards(selectedSpielort.anzahlEdartBoards);
            setRaucher(selectedSpielort.raucher);
            setAktiv(selectedSpielort.aktiv);
        }
    }, [selectedSpielort]);


    const initDialog = () => {
        setId(undefined);
        setBezeichnung("");
        setStrasse("");
        setPLZ("");
        setOrt("");
        setTelefon("");
        setAnzahlSteeldartBoards(0);
        setAnzahlEdartBoards(0);
        setRaucher(false);
        setAktiv(true);
    }

    const save = () => {
        let data = {id: id, bezeichnung: bezeichnung, strasse: strasse, plz: plz, ort: ort, telefon: telefon, anzahlSteeldartBoards: anzahlSteeldartBoards,
            anzahlEdartBoards: anzahlEdartBoards, raucher: raucher, aktiv: aktiv}
        let url = ConfigUtil.getConfig().resourceUrls.league + "/spielort/save/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            data,
            json => {
                setId(json.id);
                setInfoMessage("Speichern erfolgreich...");
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const isDisabled = () => {
        return bezeichnung === "";
    }

    const getDataArea = () => {
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-bezeichnung" label="Bezeichnung" width={500} required={true} value={bezeichnung} onChange={(text) => setBezeichnung(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-strasse" label="Strasse" width={500} required={false} value={strasse} onChange={(text) => setStrasse(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-plz" label="PLZ" width={100} required={false} value={plz} onChange={(text) => setPLZ(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-ort" label="Ort" width={500} required={false} value={ort} onChange={(text) => setOrt(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-telefon" label="Telefon" width={500} required={false} value={telefon} onChange={(text) => setTelefon(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHNumberTextField id="id-steeldart-boards" label="Anzahl Steeldart-Boards" width={200} min={0} max={200} required={false} value={anzahlSteeldartBoards}
                                   onChange={(text) => setAnzahlSteeldartBoards(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHNumberTextField id="id-edart-boards" label="Anzahl Edart-Boards" width={200} min={0} max={200} required={false} value={anzahlEdartBoards}
                                   onChange={(text) => setAnzahlEdartBoards(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHSwitch id="id-raucher" label="Raucherort" value={raucher} onChange={(value) => setRaucher(value)} required={false}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHSwitch id="id-aktiv" label="Aktiv" value={aktiv} onChange={(value) => setAktiv(value)} required={false}/>
            </div>
        </div>
    }

    const getButtons = () => {
        return [<DHButtonSave onClick={() => save()} working={working} disabled={isDisabled()}/>,
            <DHButtonNew onClick={() => initDialog()}/>];
    }
    return <DHDialog onClose={() => onClose()} title="Spielort verwalten" show={true} showCloseButton={true}
                     buttons={getButtons()} comp={getDataArea()}/>
}
