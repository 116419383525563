import React, {useState, useEffect} from 'react'
import {PayPalButtons, PayPalScriptProvider, FUNDING} from "@paypal/react-paypal-js";
import ConfigUtil from "../../util/ConfigUtil";
import PropTypes from "prop-types";
import FetchUtil from "../../util/FetchUtil";
import {ProgressSpinner} from "primereact/progressspinner";

const FUNDING_SOURCES = [
    FUNDING.PAYPAL,
    FUNDING.SEPA,
    FUNDING.CARD,
]

export default function PaypalComponent(props) {

    let [clientId, setClientId] = useState();
    useEffect(() => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.paypalClientId,
            result => {
                console.log("Paypal: Get clientId");
                setClientId(result.clientId)
            }
        )
    }, [props]);

    let paypalOptions = {
        clientId: clientId,
        currency: "EUR",
        enableFunding: FUNDING_SOURCES
    }

    const paypalStyle = {
        layout: "horizontal",
        label: "pay",
    }

    async function createOrder(type) {
        console.log("Paypal: createOrder " + type);
        let returnPath = "";
        if (props.returnPath) {
            returnPath = props.returnPath;
        }
        const data = {
            option: props.option,
            returnPath: returnPath,
            price: props.price
        };
        return FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.paypalCheckout,
            data,
            json => {
                console.log("Paypal: Buchung Teil 1 wurde erfolgreich durchgeführt.");
                console.log(json);
                return json.id;
            },
            responseNotOk => {
                props.onResponseNotOK("Fehler beim Buchen (" + props.option + "): " + responseNotOk.message);
            },
            error => {
                props.onError("Fehler beim Buchen (" + props.option + "): " + error.message);
            }
        );
    }

    async function onApprove(data) {
        console.log("Paypal: onApprove");
        console.log(data);
        await FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.paypalCapture + "/" + props.option,
            {
                ...data,
                option: props.option,
                price: props.price,
                data: props.data,
            },
            json => {
                console.log("Buchung: Teil 2 wurde erfolgreich durchgeführt.");
                console.log(json);
                props.onSuccess(json);
            },
            responseNotOk => {
                props.onResponseNotOK("Fehler beim Buchen (" + props.option + "): " + responseNotOk.message);
            },
            error => {
                props.onError("Fehler beim Buchen (" + props.option + "): " + error.message);
            }
        );
    }
    function onCancel(data) {
        console.log("Paypal: onCancel");
        console.log(data);
        props.onError("Buchung abgebrochen (" + props.option + ")");
    }

    function onError(error) {
        console.log("Paypal: onError");
        props.onError("Fehler beim Buchen (" + props.option + "): " + error.message);
    }

    function buildButtons() {
        let buttons =[];
        for(const type of FUNDING_SOURCES) {
            buttons.push(
                <PayPalButtons style={paypalStyle} fundingSource={type} key={type}
                               createOrder={() => createOrder(type)}
                               onApprove={onApprove}
                               onCancel={onCancel}
                               onError={onError}
                               forceReRender={[paypalOptions]}/>);
        }
        return buttons;
    }

    if (clientId) {
        return <PayPalScriptProvider options={ paypalOptions }>
            {buildButtons()}
        </PayPalScriptProvider>
    } else {
        return <ProgressSpinner style={{width: 50, height: 50}}/>
    }

}

PaypalComponent.propTypes = {
    option: PropTypes.string.isRequired,
    returnPath: PropTypes.string,
    price: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onResponseNotOK: PropTypes.func,
    onError: PropTypes.func
}
