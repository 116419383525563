import React from 'react'
import Iframe from "react-iframe";

export default function ScoringVideoComponent({gameid, displayName, showVideo, videoAnzeigeart, showVideoMenu, videoWidth, videoHeight, isMobileDevice}) {

    let styleVideo = {display: showVideo ? "" : "none"};
    let videoRechtsBuendig = true;
    if( isMobileDevice ) {
        styleVideo = {...styleVideo, width: videoWidth + "%", height: videoHeight + "%"};
    } else {
        videoRechtsBuendig = !showVideoMenu || videoAnzeigeart === 3;
        if( videoAnzeigeart === 3) {
            styleVideo = {...styleVideo, width: "100%", height:"100%", padding:0, paddingTop:60};
        } else if( videoAnzeigeart === 2) {
            styleVideo = {...styleVideo, width:  videoWidth + "%", height: videoHeight + "%"};
        } else {
            styleVideo = {...styleVideo, width:  videoWidth + "%", height: videoHeight + "%"};
        }
    }

    // große Liste jitsi server
    // https://vpntester.org/blog/kostenlose-jitsimeet-server-liste/
    // https://jitsi.freifunk-duesseldorf.de/
    // https://fairmeeting.net/ weitere jtisi möglichkeiten
    return <div style={{bottom: 0, right: videoRechtsBuendig ? 0 : 50, position: "fixed", zIndex: 500, ...styleVideo}}>
        {showVideo && <Iframe url={"https://meet.ffmuc.net/dh_" + gameid + "#userInfo.displayName=\"" + displayName + "\""}
                              width="100%"
                              height="100%"
                              allow="camera; microphone;"
                              id={"frame_" + gameid}
                              display="initial"
                              position="relative"/>
        }
    </div>
}


