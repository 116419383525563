import React, {useEffect, useState} from 'react'
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Button, TableContainer} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import LigaSpielortVerwaltenDialog from "./LigaSpielortVerwaltenDialog";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from "../../general/ConfirmDialog";


export default function LigaSpielorteComp({eventOperatorId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showVerwaltenDialog, setShowVerwaltenDialog] = useState(false);
    const [selectedSpielort, setSelectedSpielort] = useState(undefined);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const CELL_PADDING_VALUE = 10;

    const [spielorte, setSpielorte] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/spielorte/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setSpielorte(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const deleteSpielort = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/spielort/delete/" + selectedSpielort.id;
        FetchUtil.fetchDelete(url,
            {},
            json => {
                setInfoMessage("Löschen war erfolgreich...");
                setSelectedSpielort(undefined);
                setShowDeleteDialog(false);
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSelectedSpielort(undefined);
                setShowDeleteDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setSelectedSpielort(undefined);
                setShowDeleteDialog(false);
            });
    }

    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        spielorte.forEach(spielort => {
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={spielort.id}>
                <TableCell style={style}>{spielort.bezeichnung}</TableCell>
                <TableCell style={style}>{spielort.strasse}</TableCell>
                <TableCell style={style}>{spielort.plz}</TableCell>
                <TableCell style={style}>{spielort.ort}</TableCell>
                <TableCell style={style}>{spielort.aktiv ? <DoneIcon/> : <BlockIcon/>}</TableCell>
                <TableCell style={style}>
                    <EditIcon style={{cursor:"pointer"}} onClick={() => {
                        setSelectedSpielort(spielort);
                        setShowVerwaltenDialog(true);
                    }}/>
                    <DeleteIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setSelectedSpielort(spielort);
                        setShowDeleteDialog(true);
                    }}/>
                </TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{marginTop:20}}>
            <Button variant="contained" startIcon={<AddCircleOutlineIcon/>} onClick={() => setShowVerwaltenDialog(true)}>Spielort hinzufügen</Button>
        </div>
        <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop:20}}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", padding:0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE}}>Bezeichnung</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE}}>Strasse</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, textAlign:"center", width: 70, padding:CELL_PADDING_VALUE}}>PLZ</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE}}>Ort</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE, width: 50,}}>Aktiv</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE, width: 100,}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows()}
                </TableBody>
            </Table>
        </TableContainer>

        {showDeleteDialog && <ConfirmDialog visible={true} header={"Spielort löschen"} textOben={<div>
            <div style={{color:"red", marginBottom:20}}>Sobald du den Spielort löscht, werden alle Zuordnungen des Spieltages, z.B. bei Mannschaften, gelöscht.</div>
            <div>Möchtest du den Spielort wirklich löschen?</div>
        </div>} callBackOnNein={() => {
            setShowDeleteDialog(false);
            setSelectedSpielort(undefined);
        }} callBackOnJa={() => deleteSpielort()}/>}
        {showVerwaltenDialog && <LigaSpielortVerwaltenDialog selectedSpielort={selectedSpielort} onRefresh={() => ladeDaten()} onClose={() => {
            setShowVerwaltenDialog(false);
            setSelectedSpielort(undefined);
        }} eventOperatorId={eventOperatorId}/> }
    </div>
}
