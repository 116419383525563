import React, {useEffect, useRef, useState} from 'react'
import {FunGameShortComponent} from "./ligaComponents";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import {Card} from "primereact/card";
import {Messages} from 'primereact/messages';
import DHDatePicker from "../../general/DHDatePicker";

export default function GameStatistik(props) {

    let aktuellesDatum = new Date();
    aktuellesDatum.setMonth(aktuellesDatum.getMonth() - 1);
    var vDatum = new Date(aktuellesDatum.getFullYear(), aktuellesDatum.getMonth(), 1);
    let bDatum = new Date();
    let [games, setGames] = useState([]);
    let [vonDatum, setVonDatum] = useState(vDatum);
    let [bisDatum, setBisDatum] = useState(bDatum);
    let [loading, setLoading] = useState(false);

    const messages = useRef(null);

    useEffect( () => {
        loadData(vonDatum, bisDatum);
    } ,[]);

    const showError = (message) => {
        messages.current.show({ severity: 'error', sticky: true, summary: 'Fehler...', detail: message });
    }

    const clearMessages = () => {
        messages.current.clear();
    }

    const loadData = (von, bis) => {
        clearMessages();
        if( von === null || von === undefined || bis === null || bis === undefined ) {
            return;
        }
        let data = {von: von, bis: bis};
        let url;
        if( props.userId !== null && props.userId !== undefined) {
            url = ConfigUtil.getConfig().resourceUrls.game + "/games/" + props.userId;
        } else {
            url = ConfigUtil.getConfig().resourceUrls.game + "/doppelgames/" + props.doppelpartnerId;
        }
        setLoading(true);
        setGames([]);
        FetchUtil.fetchPost(url,
            data,
            json => {
                setGames([...json]);
                setLoading(false);
            },
            responseNotOk => {
                setLoading(false);
                showError("Fehler beim Laden der Gamedaten: " + responseNotOk.message);
            },
            error => {
                setLoading(false);
                showError("Fehler beim Laden der Gamedaten: " + error.message);
            }
        );
    }

    let getRows = () => {
        let rows = [];
        for (const game of games) {
            rows.push(
                <Card style={{marginRight:10, marginTop:20}}>
                    <FunGameShortComponent funGame={game} userId={props.userId} key={"key_game_statistik_gameid" + game.gameId}/>
                </Card>);
        }
        return rows;
    }

    return <div
        style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
        <div className="pageHeader" style={{backgroundColor: "#aa99c2"}}>
            Gameübersicht
        </div>
        <div style={{padding:20,  textAlign:"left"}}>
            {loading && <div style={{width: 50}}><i className="pi pi-spin pi-spinner" style={{fontSize: 50, marginLeft: 30}}/></div>}
            <div style={{fontWeight: "bold"}}>
                Filter
            </div>
            <div style={{display: "flex", alignItems: "center", marginTop: 15}}>
                <span style={{width: 70}}>Von:</span><DHDatePicker disabled={false} value={vonDatum} changeValue={setVonDatum}/>
            </div>
            <div style={{display: "flex", alignItems: "center", marginTop: 5}}>
                <span style={{width: 70}}>Bis:</span><DHDatePicker disabled={false} value={bisDatum} changeValue={setBisDatum}/>
            </div>
        </div>
        <div>
            <Messages ref={messages}></Messages>
        </div>
        {games.length === 0 && <div style={{marginTop: 20, textAlign: "left", marginLeft: 20}}>Es wurden keine Spiele zum angegeben Zeitraum gefunden!</div>}
        <div className="grid" style={{marginTop: 20}}>
            {getRows()}
        </div>
    </div>
}