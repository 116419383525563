import React from "react";
import {Button, createTheme, TextField,} from "@mui/material";
import {ThemeProvider} from "@mui/styles";

import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';

export default function DHTextField({id, label, width, required, value, onChange}) {

    return <TextField id={id} label={label} variant="filled" style={{width: width}} required={required} value={value} onChange={(event) => onChange(event.target.value)}/>

}