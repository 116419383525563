import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";
import RefreshButtonComp from "./RefreshButtonComp";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import ConfigUtil from "../util/ConfigUtil";

let stompClient = undefined;

export default function RefreshTournamentAdminAreaButtonComp({tournamentId, text, autorefresh, onRefresh, onAutoRefresh, shutdownminutes, onData}) {

    useEffect(() => {
        if( autorefresh ) {
            connectStomp();
        } else {
            disconnectStomp();
        }
    }, [autorefresh]);

    useEffect(() => {
        return () => {
            disconnectStomp();
        };
    }, []);

    const disconnectStomp = () => {
        if( stompClient !== null && stompClient !== undefined) {
            console.log("Websocket - Chat - disconnect:");
            stompClient.disconnect(() => {});
        }
    }

    const connectStomp = () => {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        // stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                console.log("Websocket - connect success");
                const destination = "/topic/event/" + tournamentId;
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, (payload) => {
                    const body = JSON.parse(payload.body);
                    onData(body);
                });
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    return <RefreshButtonComp text={text} autorefresh={autorefresh} onRefresh={() => onRefresh()} shutdownminutes={shutdownminutes}
                                              onAutoRefresh={(schalter) => {
                                                  onAutoRefresh(schalter);
                                              }}/>
}
