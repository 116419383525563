const PRODUCTION_CLIENT_HOSTNAME = "www.darthelfer.de";
const PRODUCTION_CLIENT_HOSTNAME_OHNE_WWW = "darthelfer.de";
const PRODUCTION_HOST = "https://www.darthelfer.de/api";

const TEST_CLIENT_HOSTNAME = "test.darthelfer.de";
const TEST_HOST = "https://test.darthelfer.de/api";

const DEV_PROTOCOL = "https:";
const DEV_PORT = "8443";

class ConfigUtil {
    static getConfig() {
        if (window.location.hostname === PRODUCTION_CLIENT_HOSTNAME || window.location.hostname === PRODUCTION_CLIENT_HOSTNAME_OHNE_WWW) {
            return configForHost(PRODUCTION_HOST);
        } else if (window.location.hostname === TEST_CLIENT_HOSTNAME) {
            return configForHost(TEST_HOST);
        } else {
            return configForHost(devHostForHostname(window.location.hostname));
        }
    }

    static getBasicClientUrl() {
        if (window.location.hostname === PRODUCTION_CLIENT_HOSTNAME || window.location.hostname === PRODUCTION_CLIENT_HOSTNAME_OHNE_WWW) {
            return "https://" + PRODUCTION_CLIENT_HOSTNAME;
        } else if (window.location.hostname === TEST_CLIENT_HOSTNAME) {
            return "https://" + TEST_CLIENT_HOSTNAME;
        } else {
            return DEV_PROTOCOL + "//localhost:3000";
        }
    }

}

function devHostForHostname(hostname) {
    return DEV_PROTOCOL + "//" + hostname + ":" + DEV_PORT + "/api";
}

function configForHost(host) {
    return {
        resourceUrls: {
            ranglisteNG: host + "/ranglisteng",
            rangliste: host + "/rangliste",
            user: host + "/user",
            news: host + "/news",
            sponsoren: host + "/sponsoren",
            event: host + "/event",
            game: host + "/game",
            leagueoperator: host + "/leagueoperator",
            player: host + "/player",
            training: host + "/training",
            ligaspieltag: host + "/ligaspieltag",
            externeliga: host + "/externeliga",
            settings: host + "/settings",
            werbung: host + "/werbung",
            bestleistung: host + "/bestleistung",
            gameday: host + "/gameday",
            userproaccount: host + "/userproaccount",
            fungame: host + "/fungame",
            doppelpartner: host + "/doppelpartner",
            automat: host + "/automat",
            statistics: host + "/statistics",
            tournament: host + "/tournament",
            jitsi: host + "/jitsi",
            league: host + "/league",
            eventoperator: host + "/eventoperator",
            gutschein: host + "/gutschein",
            gamingarea: host + "/gamingarea",
            weekendleague: host + "/weekendleague",
            chat: host + "/chat",
            rating: host + "/rating",
            admin: host + "/admin",
            autodarts: host + "/autodarts",
            notification: host + "/notification",
            profil: host + "/profil",
            freundschaft: host + "/freundschaft",
            webpush: host + "/public/webpush",
            score: host + "/score",
            public: host + "/public",
            receipt: host + "/receipt",
            tournamentpayment: host + "/tournamentpayment",
            urkunden: host + "/urkunden",
            paypalClientId: host + "/paypal/client-id",
            paypalCheckout: host + "/paypal/checkout",
            paypalCapture: host + "/paypal/capture",
            nu: host + "/nu",
        },
        loginUrl: host + "/login",
        logoutUrl: host + "/logout",
        websocketUrl: host + "/websocket"
    };
}

export default ConfigUtil;
