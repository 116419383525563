import React, {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import {Button} from "primereact/button";
import {ProgressSpinner} from "primereact/progressspinner";
import ConfirmWithQuestionDialog from "../general/ConfirmWithQuestionDialog";
import ErrorInfoComponent from "../general/ErrorInfoComponent";
import {Link} from "@mui/material";
import {Dropdown} from "primereact/dropdown";

export default function NuCallbackView({}) {

    const urlParams = new URLSearchParams(useLocation().search);
    const code = urlParams.get('code');

    let [infoMessage, setInfoMessage] = useState(undefined);
    let [errorMessage, setErrorMessage] = useState(undefined);

    let [nuAuthLink, setNuAuthLink] = useState(undefined);

    let [readOnly, setReadOnly] = useState(false);

    let [selectedVeranstalter, setSelectedVeranstalter] = useState(undefined);
    let [veranstalterListe, setVeranstalterListe] = useState(undefined);
    let [nuUser, setNuUser] = useState(undefined);
    let [nuClubs, setNuClubs] = useState(undefined);
    let [selectedClub, setSelectedClub] = useState(undefined);

    useEffect(() => {
        if( !selectedVeranstalter ) {
            return;
        }
        if( nuAuthLink !== "") {
            return;
        }
        return FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/config/" + selectedVeranstalter.id,
            {},
            result => {
                setNuAuthLink(result.logoutUrl);
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
            },
            error => {
                setErrorMessage(error.message);
            }
        );
    }, [selectedVeranstalter]);

    useEffect(() => {
        getEventOperators();
    }, []);

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        if( nuUser !== null && nuUser !== undefined) {
            getClubs();
        }
    }, [nuUser]);

    const getEventOperators = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/byCurrentUser",
            json => {
                let tmp = [];
                for (const veranstalter of json) {
                    if( veranstalter.proVeranstalter) {
                        tmp.push(veranstalter);
                    }
                }
                if( tmp.length === 1) {
                    setSelectedVeranstalter(tmp[0]);
                }
                setVeranstalterListe(tmp);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Veranstalter: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Veranstalter: " + error.message);
            });
    }

    function getUser() {
        return FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/user",
            {
                code: code,
                redirectUrl: ConfigUtil.getBasicClientUrl() + "/#/extern/nucallback"
            },
            result => {
                setNuUser(result);
            },
            error => console.log(error),
            error => console.log(error)
        )
    }

    function getClubs() {
        return FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/clubs",
            {
                userId: nuUser.userId,
                code: code,
                redirectUrl: ConfigUtil.getBasicClientUrl() + "/#/extern/nucallback"
            },
            result => {
                setNuClubs(result);
            },
            error => setErrorMessage(error.message),
            error => setErrorMessage(error.message)
        )
    }

    function saveClub() {
        setErrorMessage(undefined);
        let data = {...selectedClub};
        return FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/saveClub/" + selectedVeranstalter.id,
            data,
            result => {
                setInfoMessage("Der BDV/nu Verein wurde mit deinem Veranstalter verknüpft.");
                setSelectedClub(undefined);
                setReadOnly(true);
            },
            error => {
                setErrorMessage(error.message);
                setSelectedClub(undefined);
            },
            error => {
                setErrorMessage(error.message);
                setSelectedClub(undefined);
            }
        )
    }

    function printClubs(nuClubs) {
        const clubs = [];
        for (let i = 0; i < nuClubs.length; i++) {
            clubs.push(<div key={nuClubs[i].clubNr} style={{marginBottom:20}}>
                <div>Name: {nuClubs[i].name}</div>
                <div>Verband: {nuClubs[i].federationName}</div>
                <div>Vereinsnummer: <strong>{nuClubs[i].clubNr}</strong></div>
                {!readOnly &&
                    <div style={{marginTop:5}}>
                        <Button label={"Diesen Verein (" + nuClubs[i].clubNr + ") mit Darthelfer verknüpfen"} onClick={() => setSelectedClub(nuClubs[i])} disabled={!selectedVeranstalter}/>
                    </div>
                }
            </div>);
            clubs.push(<br key={nuClubs[i].clubNr + "_br"}/>);
        }
        return clubs;
    }

    return <div style={{padding: 20}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div>Vielen Dank! Die Verbindung zu NU konnte hergestellt werden. Wir laden nun deine Daten...</div>
        {veranstalterListe === undefined && <div>
            <ProgressSpinner style={{width: 50, height: 50}}/>
            <div>Laden Veranstalterdaten...</div>
        </div>}
        {veranstalterListe && veranstalterListe.length > 1 && <div>
            <div style={{marginTop:20}}>
                <Dropdown value={selectedVeranstalter} options={veranstalterListe}
                          optionLabel="name" style={{width: 300}}
                          onChange={(e) => {
                              setSelectedVeranstalter(e.value);
                          }}
                          placeholder="Eingabe..."/>
            </div>
        </div>}
        {veranstalterListe && veranstalterListe.length === 1 && selectedVeranstalter && <div style={{marginTop:20}}>
            <div style={{fontWeight: "bold", marginBottom: 10}}>Veranstalter</div>
            <div>{selectedVeranstalter.name}</div>
        </div>}
        {nuUser === undefined && <div>
            <ProgressSpinner style={{width: 50, height: 50}}/>
            <div>Laden Benutzerdaten...</div>
        </div>}
        {nuUser !== undefined && <div style={{marginTop:20}}>
                <div style={{fontWeight:"bold", marginBottom:10}}>Benutzerdaten</div>
                <div>Benutzer-Id: {nuUser.userId}</div>
                <div>Emailadresse: {nuUser.email}</div>
                <div>Vorname: {nuUser.givenName}</div>
                <div>Nachname: {nuUser.familyName}</div>
            </div>
        }
        {nuClubs === undefined && <div>
            <ProgressSpinner style={{width: 50, height: 50}}/>
            <div>Lade Vereine...</div>
        </div>}
        {nuClubs !== undefined && <div style={{marginTop: 20}}>
            <div style={{fontWeight: "bold", marginBottom: 10}}>BDV/nu Vereine</div>
            {printClubs(nuClubs)}
        </div>
        }
        {nuAuthLink !== undefined && <div style={{marginTop:50}}>
            <Link href={nuAuthLink}>Am nu-Portal Abmelden...</Link>
        </div>
        }
        {selectedClub !== undefined && <ConfirmWithQuestionDialog
            header="BDV/nu Verein mit Darhelfer verknüpfen"
            textOben="Du kannst je Veranstalter nur einen BDV/nu Verein verknüpfen. Überlege deshalb genau, ob du die Verknüpfung nun durchführen möchtest."
            textUnten="Bist du dir sicher, dass du den Verein mit Darthelfer verknüpfen möchtest?"
            visible={true} callBackOnNein={() => setSelectedClub(undefined)} callBackOnJa={() => saveClub()}/>}
    </div>
}
