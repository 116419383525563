import React, {useState, useEffect} from 'react'
import ConfigUtil from "../../../../util/ConfigUtil";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import {Link} from "@mui/material";
import {saveToLocalStorage} from "../../../../util/localStorageZugriff";
import {Navigate} from "react-router-dom";

export default function PublicLigaspielttagQRCodeCheck({tournamentid, qrcode}) {

    const [timerCounter, setTimerCounter] = useState(30);

    useEffect(() => {
        saveToLocalStorage("dh_ligaspieltag_gast_input", {tournamentid: tournamentid, qrcode: qrcode});
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if( timerCounter <= 0) {
                return;
            }
            setTimerCounter(timerCounter - 1);
        }, 1000);
    }, [timerCounter]);

    const getPublicTournamentWithBasicLink = () => {
        let url = ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route + "/" +  tournamentid;
        console.log(url);
        return url;
    }
    const getPublicTournamentLink = () => {
        let url = NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route + "/" +  tournamentid;
        console.log(url);
        return url;
    }

    if( timerCounter <= 0) {
        return <Navigate to={getPublicTournamentLink()}/>;
    }

    return <div style={{padding: 0, backgroundColor: "#022836", minHeight: "100vh", height: "100%", color: "white"}}>
        <div style={{padding: 20}}>
            <img alt="Logo" src={"/images/darthelfer_quer_weiss.png"} style={{width: 350}}/>
            <br/>
            <br/>
            Deine Gasteingabe wurde erfolgreich vermerkt. Du wirst in {timerCounter} Sekunden zum Ligaspieltag umgeleitet.
            <br/>
            <br/>
            Du kannst jederzeit über Darthelfer den Ligaspieltag wieder suchen und erneut Eingaben tätigen.
            <br/>
            <br/>
            Nachdem du im Ligaspieltag bist, klicke den Knopf 'Gasteingabe'.
            <br/>
            <br/>
            <Link href={getPublicTournamentWithBasicLink()}>Direkt weiter zum Ligaspieltag...</Link>
        </div>
    </div>
}
