import React, {useState} from 'react'
import DHDialog from "../../general/DHDialog";
import DHButtonSave from "../../general/DHButtonSave";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHTextField from "../../general/DHTextField";
import DHSwitch from "../../general/DHSwitch";

export default function LigaVereinVerwaltenDialog({eventOperatorId, id, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [working, setWorking] = useState(false);

    const [name, setName] = useState("");
    const [aktiv, setAktiv] = useState(true);

    const save = () => {
        setInfoMessage("Speichern...");
    }

    const isDisabled = () => {
        return name === "";
    }

    const getDataArea = () => {
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <DHTextField id="id-name" label="Name" width={500} required={true} value={name} onChange={(text) => setName(text)}/>
            <div style={{marginTop: 10}}>
                <DHSwitch id="id-aktiv" label="Aktiv" value={aktiv} onChange={(value) => setAktiv(value)} required={false}/>
            </div>
        </div>
    }

    return <DHDialog onClose={() => onClose()} title="Verein verwalten" show={true} showCloseButton={true}
                     buttons={[<DHButtonSave onClick={() => save()} working={working} disabled={isDisabled()}/>]} comp={getDataArea()}/>
}
