import React from 'react'
import PropTypes from "prop-types";
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import PlusMinusComponent from "../../general/PlusMinusComponent";
import ScoreWertComponent from "../../general/ScoreWertComponent";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import StatisticGameDetailDialog from "../statistics/StatisticGameDetailDialog";
import BoardSelectionDialog from "../veranstalter/BoardSelectionDialog";
import StatistikGameDialog from "../public/tournaments/StatistikGameDialog";

class LigaGame extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showGameDetailDialog: false,
            edit: false,
            showBoardSelectionDialog: false,
            anzahlSpieler1: this.props.game.anzahlSpieler1 != null ? this.props.game.anzahlSpieler1 : "",
            anzahlSpieler2: this.props.game.anzahlSpieler2 != null ? this.props.game.anzahlSpieler2 : "",
            spieler1Anzahl171: this.props.game.spieler1Anzahl171 !== null && this.props.game.spieler1Anzahl171 !== undefined ? this.props.game.spieler1Anzahl171 : 0,
            spieler1Anzahl180: this.props.game.spieler1Anzahl180 !== null && this.props.game.spieler1Anzahl180 !== undefined ? this.props.game.spieler1Anzahl180 : 0,
            spieler1Highfinishes: this.props.game.spieler1Highfinishes !== null && this.props.game.spieler1Highfinishes !== undefined ? this.props.game.spieler1Highfinishes : [],
            spieler2Anzahl171: this.props.game.spieler2Anzahl171 !== null && this.props.game.spieler2Anzahl171 !== undefined ? this.props.game.spieler2Anzahl171 : 0,
            spieler2Anzahl180: this.props.game.spieler2Anzahl180 !== null && this.props.game.spieler2Anzahl180 !== undefined ? this.props.game.spieler2Anzahl180 : 0,
            spieler2Highfinishes: this.props.game.spieler2Highfinishes !== null && this.props.game.spieler2Highfinishes !== undefined ? this.props.game.spieler2Highfinishes : [],
            spieler1Highscores: this.props.game.spieler1Highscores !== null && this.props.game.spieler1Highscores !== undefined ? this.props.game.spieler1Highscores : [],
            spieler2Highscores: this.props.game.spieler2Highscores !== null && this.props.game.spieler2Highscores !== undefined ? this.props.game.spieler2Highscores : [],
        };

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.openEdit = this.openEdit.bind(this);
        this.addHighfinishSpieler1 = this.addHighfinishSpieler1.bind(this);
        this.removeHighfinishSpieler1 = this.removeHighfinishSpieler1.bind(this);
        this.addHighfinishSpieler2 = this.addHighfinishSpieler2.bind(this);
        this.removeHighfinishSpieler2 = this.removeHighfinishSpieler2.bind(this);
        this.addHighscoreSpieler1 = this.addHighscoreSpieler1.bind(this);
        this.removeHighscoreSpieler1 = this.removeHighscoreSpieler1.bind(this);
        this.addHighscoreSpieler2 = this.addHighscoreSpieler2.bind(this);
        this.removeHighscoreSpieler2 = this.removeHighscoreSpieler2.bind(this);
    }

    handelKeyDown(e) {
        if (e.keyCode === 13) {
            // enter
            this.save();
        }
    }

    openEdit(spieler1Inaktiv, spieler2Inaktiv) {
        if( this.props.readonly) {
            return;
        }
        if( spieler1Inaktiv || spieler2Inaktiv) {
            return;
        }
        if( !this.isAktuerUserAdministrator()) {
            return;
        }
        if (this.props.game.spielOhneErgebnis) {
            return;
        }
        this.setState({edit: true});
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    changeInput(e, name) {
        if (!isNaN(e.target.value)) {
            this.setState({[name]: e.target.value});
        }
    }

    save() {
        this.messages.clear();
        if (this.state.anzahlSpieler1 === "" || this.state.anzahlSpieler2 === "") {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/changegroupgame";
        let data = {
            tournamentid: this.props.tournament.id,
            tournamentGameId: this.props.game.id,
            feld: undefined,
            player1Id: this.props.game.spieler1,
            player2Id: this.props.game.spieler2,
            anzahlPlayer1: this.state.anzahlSpieler1,
            anzahlPlayer2: this.state.anzahlSpieler2,
            spieler1Anzahl171: this.state.spieler1Anzahl171,
            spieler1Anzahl180: this.state.spieler1Anzahl180,
            spieler1Highfinishes: this.state.spieler1Highfinishes,
            spieler1Highscores: this.state.spieler1Highscores,
            spieler2Anzahl171: this.state.spieler2Anzahl171,
            spieler2Anzahl180: this.state.spieler2Anzahl180,
            spieler2Highfinishes: this.state.spieler2Highfinishes,
            spieler2Highscores: this.state.spieler2Highscores,
        };
        FetchUtil.fetchPost(url,
            data,
            () => {
                this.showInfo("Ergebnis wurde erfolgreich gespeichert.");
                this.setState({edit: false});
                this.props.callbackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern des Ergebnisses: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern des Ergebnisses: " + error.message);
            });
    }

    saveSpielImmerSpielbar() {
        this.messages.clear();
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/groupgame/immerspielbar/" + this.props.game.id;
        FetchUtil.fetchPost(url,
            {},
            () => {
                this.showInfo("Spiel immer spielbar wurde gespeichert");
                this.props.callbackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            });
    }

    delete() {
        this.messages.clear();
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/changegroupgame";
        let data = {
            tournamentid: this.props.tournament.id,
            tournamentGameId: this.props.game.id,
            feld: undefined,
            player1Id: this.props.game.spieler1,
            player2Id: this.props.game.spieler2,
            anzahlPlayer1: undefined,
            anzahlPlayer2: undefined,
            spieler1Anzahl171: undefined,
            spieler1Anzahl180: undefined,
            spieler1Highfinishes: undefined,
            spieler2Anzahl171: undefined,
            spieler2Anzahl180: undefined,
            spieler2Highfinishes: undefined,
        };
        FetchUtil.fetchPost(url,
            data,
            () => {
                this.showInfo("Ergebnis wurde erfolgreich gelöscht.");
                this.setState({edit: false,
                    anzahlSpieler1: "", anzahlSpieler2: "",
                    spieler1Anzahl171:0, spieler1Anzahl180:0, spieler1Highfinishes:[],
                    spieler2Anzahl171:0, spieler2Anzahl180:0, spieler2Highfinishes:[]});
                this.props.callbackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Löschen des Ergebnisses: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Löschen des Ergebnisses: " + error.message);
            });
    }

    getMeetingkey(turniergameid) {
        if (turniergameid !== undefined && turniergameid !== null && this.props.tournament.games !== null && this.props.tournament.games !== undefined) {
            for (const game of this.props.tournament.games) {
                if (game.turniergameid === turniergameid) {
                    return game.meetingKey;
                }
            }
        }
        return undefined;
    }

    getGameId(turniergameid) {
        if (turniergameid !== undefined && turniergameid !== null && this.props.tournament.games !== null && this.props.tournament.games !== undefined) {
            for (const game of this.props.tournament.games) {
                if (game.turniergameid === turniergameid) {
                    return game.id;
                }
            }
        }
        return undefined;
    }

    isAktuellerUserImGame() {
        return this.props.game.spieler1 === this.props.currentUserPlayerId || this.props.game.spieler2 === this.props.currentUserPlayerId;
    }

    isAktuerUserAdministrator() {
        for (const admin of this.props.tournament.administratoren) {
            if( admin.userId === this.props.userId) {
                return true;
            }
        }
        return false;
    }

    isEventOperator() {
        return this.props.tournament.eventOperatorId !== null && this.props.tournament.eventOperatorId !== undefined;
    }

    render() {
        let gameId = this.getGameId(this.props.game.id);
        let spieler1Id = this.props.game.spieler1;
        let spieler2Id = this.props.game.spieler2;
        let spieler1 = this.props.callbackGetSpielerName(this.props.game.spieler1);
        let spieler2 = !this.props.game.spielOhneErgebnis ? this.props.callbackGetSpielerName(this.props.game.spieler2) : "-";
        let spieler1Inaktiv = this.props.callbackIsSpielerInaktiv(this.props.game.spieler1);
        let spieler2Inaktiv = this.props.callbackIsSpielerInaktiv(this.props.game.spieler2);
        let inaktiveSpielerVorhanden = spieler1Inaktiv || spieler2Inaktiv;
        let anzahlSpieler1 = this.props.game.anzahlSpieler1 !== null ? this.props.game.anzahlSpieler1 : "";
        let anzahlSpieler2 = this.props.game.anzahlSpieler2 !== null ? this.props.game.anzahlSpieler2 : "";
        let ergebnis = anzahlSpieler1 !== "" && anzahlSpieler2 !== "" ? anzahlSpieler1 + ":" + anzahlSpieler2 : "";
        let ergebnisVorhanden = anzahlSpieler1 !== null && anzahlSpieler1 !== undefined && anzahlSpieler1 !== "";
        let userImGame = this.isAktuellerUserImGame();
        let isOnline = this.props.tournament.online;
        let showMeetingKey = !this.props.tournament.doppelturnier && this.props.callbackHasPlayersMeetingkeyAllowed(this.props.game.spieler1, this.props.game.spieler2);
        let boardGame = this.getBoardGame();

        let player1Style = {backgroundColor: ergebnisVorhanden && Number(anzahlSpieler1) > Number(anzahlSpieler2) ? "#6b9d25" : ""};
        let player2Style = {backgroundColor: ergebnisVorhanden && Number(anzahlSpieler2) > Number(anzahlSpieler1) ? "#6b9d25" : ""};
        if( this.props.selectedPlayerId !== undefined && this.props.selectedPlayerId === spieler1Id ) {
            player1Style = {...player1Style, backgroundColor: "#FD8943"};
        }
        if( this.props.selectedPlayerId !== undefined && this.props.selectedPlayerId === spieler2Id ) {
            player2Style = {...player2Style, backgroundColor: "#FD8943"};
        }

        return <div style={{textAlign: "left"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            {boardGame !== undefined && <div style={{textAlign:"center", backgroundColor:"#ac1c27", color:"white", fontSize:20, marginTop:10}}>Wird gespielt an: {boardGame.boardBezeichnung}</div>}
            {boardGame !== undefined && boardGame.schreiberId !== null && boardGame.schreiberId !== undefined && <div style={{textAlign:"center", backgroundColor:"#ac1c27", color:"white", fontSize:20}}>Schreiber: {boardGame.schreiberName}</div>}
            <div className="grid" style={this.isAktuerUserAdministrator() && !inaktiveSpielerVorhanden ? {padding: 10, cursor: "pointer"} : {padding: 10}}>
                <div className="col" style={{marginRight: 10,...player1Style}}
                     onMouseOver={() => this.props.onPlayerSelect(spieler1Id)} onMouseOut={() => this.props.onPlayerSelect(undefined)}
                     onClick={() => this.openEdit(spieler1Inaktiv, spieler2Inaktiv)}>{spieler1Inaktiv ? <s>{spieler1}</s> : spieler1}</div>
                <div className="col-fixed" style={{width: 10}}>:</div>
                <div className="col" style={player2Style}
                     onMouseOver={() => this.props.onPlayerSelect(spieler2Id)} onMouseOut={() => this.props.onPlayerSelect(undefined)}
                     onClick={() => this.openEdit(spieler1Inaktiv, spieler2Inaktiv)}>{spieler2Inaktiv ? <s>{spieler2}</s> : spieler2}</div>
                <div className="col-fixed" style={{width: 30}}>
                    {ergebnis !== "" &&
                        <span style={{cursor:"pointer"}} onClick={() => this.setState({showGameDetailDialog: true}) }>{ergebnis}</span>
                    }
                    {( isOnline && (this.props.showGames || this.props.game.spielKannImmerBespieltWerden) && !this.props.readonly && gameId !== undefined && ergebnis === "" && userImGame && !inaktiveSpielerVorhanden) &&
                        <NavLink to={NAVIGATION_ITEM.SCORING.route + "?gameid=" + this.getGameId(this.props.game.id) + "&playerid=" + this.props.currentUserPlayerId} style={{textDecoration: "none"}}>
                            <img src="/images/dart_select.png" style={{cursor:"pointer", width: 25}} alt=""/>
                        </NavLink>
                    }
                    {(ergebnis === "" && !this.props.readonly && this.isEventOperator()) &&
                        <img src="/images/kneipe.png" alt="Board vergeben" title="Board vergeben" style={{width: 20}}
                             onClick={() => this.setState({showBoardSelectionDialog: true}) }/>
                    }
                </div>
            </div>
            {this.isAktuerUserAdministrator() && showMeetingKey && this.getMeetingkey(this.props.game.id) !== null &&
                <>
                <div className="grid" style={{paddingLeft: 10}}>
                    <div className="col" style={{marginRight: 10}}>
                        {"Meetingkey: " + this.getMeetingkey(this.props.game.id)}
                        <NavLink to={NAVIGATION_ITEM.GAME_VIDEO.route + "?gameid=" + gameId + "&username=player&testhinweis=false"} style={{textDecoration: "none"}}>
                            <img src="/images/dart_select.png" alt="Watch Scoring" style={{cursor: "pointer", width: 30, marginLeft: 5}}/>
                        </NavLink>
                        <NavLink to={NAVIGATION_ITEM.GAMEWATCH.route + "?gameid=" + gameId} style={{textDecoration: "none"}}>
                            <img src="/images/webcam_color.png" alt="Watch Video" style={{cursor: "pointer", width: 30, marginLeft: 5}}/>
                        </NavLink>
                    </div>
                </div>
                <hr/>
                </>
            }
            {this.state.edit && this.getEditArea()}
            {this.state.showGameDetailDialog &&
                <StatistikGameDialog tournamentId={this.props.tournament.id}
                                     tournamentGameId={this.props.game.id} onClose={() => {
                    this.setState({showGameDetailDialog: false});
                }}/>
            }
            {this.state.showBoardSelectionDialog && <BoardSelectionDialog onClose={() => this.setState({showBoardSelectionDialog: false})}
                                                                          eventOperatorId={this.props.tournament.eventOperatorId}
                                                                          spieler1Name={spieler1} spieler2Name={spieler2}
                                                                          showSchreiberArea={true}
                                                                          schreiberName={""}
                                                                          callBackLoadData={this.props.callbackRefresh}
                                                                          players={this.props.tournament.tournamentPlayerEntities}
                                                                          tournamentId={this.props.tournament.id} tournamentGameId={this.props.game.id}/>}
        </div>;
    }

    handleMinus(field, value) {
        this.setState({[field]: this.state[field] - 1});
    }

    handlePlus(field) {
        this.setState({[field]: this.state[field] + 1});
    }

    removeHighfinishSpieler1(value) {
        let newHighfinishes = this.entferneErstenGefundenWertAusArray(this.state.spieler1Highfinishes, value);
        this.setState({...this.state, spieler1Highfinishes: newHighfinishes});
    }

    addHighfinishSpieler1(value) {
        let highfinishes = this.state.spieler1Highfinishes;
        highfinishes.push(value);
        this.setState({...this.state, spieler1Highfinishes: highfinishes});
    }

    removeHighfinishSpieler2(value) {
        let newHighfinishes = this.entferneErstenGefundenWertAusArray(this.state.spieler2Highfinishes, value);
        this.setState({...this.state, spieler2Highfinishes: newHighfinishes});
    }

    addHighfinishSpieler2(value) {
        let highfinishes = this.state.spieler2Highfinishes;
        highfinishes.push(value);
        this.setState({...this.state, spieler2Highfinishes: highfinishes});
    }

    removeHighscoreSpieler1(value) {
        let newHighscores = this.entferneErstenGefundenWertAusArray(this.state.spieler1Highscores, value);
        this.setState({...this.state, spieler1Highscores: newHighscores});
    }

    addHighscoreSpieler1(value) {
        let highscores = this.state.spieler1Highscores;
        highscores.push(value);
        this.setState({...this.state, spieler1Highscores: highscores});
    }

    removeHighscoreSpieler2(value) {
        let newHighscores = this.entferneErstenGefundenWertAusArray(this.state.spieler2Highscores, value);
        this.setState({...this.state, spieler2Highscores: newHighscores});
    }

    addHighscoreSpieler2(value) {
        let highscores = this.state.spieler2Highscores;
        highscores.push(value);
        this.setState({...this.state, spieler2Highscores: highscores});
    }

    entferneErstenGefundenWertAusArray(myarray, value) {
        let newMyArray = [];
        let found = false;
        for (var i = 0; i < myarray.length; i++) {
            if (myarray[i] !== value || found === true) {
                newMyArray.push(myarray[i]);
            } else {
                // hat sonst wenn z.b. 111 zwei mal drinnen war, beide gelöscht.
                found = true;
            }
        }
        return newMyArray;
    }

    getBoardGame() {
        if( this.props.boardGames === null || this.props.boardGames === undefined) {
            return undefined;
        }
        for (const boardGame of this.props.boardGames) {
            if( boardGame.tournamentGameId === this.props.game.id) {
                return boardGame;
            }
        }
        return undefined;
    }

    getEditArea() {
        return <div>
            <div className="grid" style={{padding: 0}}>
                <div className="col">
                    <Button label="Bereich ohne Änderung schliessen" onClick={() => this.setState({edit: false})}
                            tooltip="Bereich ohne Änderung schliessen"
                            style={{marginLeft: 5, marginRight: 5, width: "100%"}}/>
                </div>
            </div>
            <div className="grid" style={{padding: 0}}>
                <div className="col">
                    <span style={{marginRight: 10}}>Ergebnis:</span>
                    <InputText style={{width: 30}} value={this.state.anzahlSpieler1}
                               onChange={(e) => this.changeInput(e, "anzahlSpieler1")}
                               onKeyDown={(e) => this.handelKeyDown(e)}/>
                    <span style={{marginLeft: 5, marginRight: 5}}>:</span>
                    <InputText style={{width: 30}} value={this.state.anzahlSpieler2}
                               onChange={(e) => this.changeInput(e, "anzahlSpieler2")}
                               onKeyDown={(e) => this.handelKeyDown(e)}/>
                </div>
            </div>
            <div className="grid" style={{padding: 0, marginTop: 10}}>
                <div className="col-fixed" style={{width: 120}}>
                    <Button icon="pi pi-check"
                            label="Speichern"
                            disabled={this.state.anzahlSpieler1 === "" || this.state.anzahlSpieler2 === ""}
                            tooltip="Spiel speichern" style={{marginLeft: 5, marginRight: 5}}
                            onClick={() => this.save()}/>
                </div>
                <div className="col">
                    <Button icon="pi pi-times-circle" label="Ergebnis löschen/entfernen" onClick={() => this.delete()}
                            tooltip="Spielergebnis entfernen"
                            style={{marginLeft: 5, marginRight: 5}}/>
                </div>
            </div>
            {!this.isEventOperator() && <>
                <div className="grid" style={{padding: 0, marginTop: 20}}>
                    <div className="col">
                        Hier kannst du ein Spiel auf immer spielbar stellen, damit kann das Spiel auch gespielt werden, wenn der Spieltag nicht zum Spielen freigeben ist.<br/>
                        Aktuell ist das Spiel {this.props.game.spielKannImmerBespieltWerden ? " 'IMMER' " : " 'NICHT IMMER'"} spielbar.
                    </div>
                </div>
                <div className="grid" style={{padding: 0, marginBottom: 20}}>
                    <div className="col">
                        <Button label={this.props.game.spielKannImmerBespieltWerden ? "Spiel auf nicht immer spielbar stellen" : "Spiel auf immer spielbar stellen"}
                                style={{width: "100%"}}
                                onClick={() => this.saveSpielImmerSpielbar()}/>
                    </div>
                </div>
            </>
            }
            <hr/>
        </div>;
    }
}

LigaGame.propTypes = {
    tournament: PropTypes.object.isRequired,
    readonly: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired,
    gruppennummer: PropTypes.number.isRequired,
    showGames: PropTypes.bool.isRequired,
    game: PropTypes.object.isRequired,
    callbackGetSpielerName: PropTypes.func.isRequired,
    callbackRefresh: PropTypes.func.isRequired,
    callbackIsSpielerInaktiv: PropTypes.func.isRequired,
    boardGames: PropTypes.array,
    selectedPlayerId:PropTypes.string.isRequired,
    onPlayerSelect:PropTypes.func.isRequired,
};

export default LigaGame
