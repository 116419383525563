import React, {useState} from 'react'
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Button, TableContainer} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import LigaMannschaftVerwaltenDialog from "./LigaMannschaftVerwaltenDialog";

export default function LigaMannschaftComp({eventOperatorId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showVerwaltenDialog, setShowVerwaltenDialog] = useState(false);

    const CELL_PADDING_VALUE = 10;

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{marginTop:20}}>
            <Button variant="contained" startIcon={<AddCircleOutlineIcon/>} onClick={() => setShowVerwaltenDialog(true)}>Mannschaft hinzufügen</Button>
        </div>
        <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop:20}}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", padding:0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE}}>Mannschaft</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                </TableBody>
            </Table>
        </TableContainer>

        {showVerwaltenDialog && <LigaMannschaftVerwaltenDialog onClose={() => setShowVerwaltenDialog(false)} eventOperatorId={eventOperatorId}/> }
    </div>
}
