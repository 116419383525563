import React, {useEffect, useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {ToggleButton} from "primereact/togglebutton";
import {InputTextarea} from "primereact/inputtextarea";
import {TabPanel, TabView} from "primereact/tabview";
import {SelectButton} from "primereact/selectbutton";
import {TOURNAMENTMODUS} from "../../../constants/tournamentModus";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import TurnierAdminTabPanel from "./tabs/TurnierAdminTabPanel";
import LigaAktionenComponent from "./tabs/LigaAktionenComponent";
import TournamentX01Area from "./TournamentX01Area";
import TurnierQRCode from "../liga/TurnierQRCode";
import BestleistungenDialog from "./BestleistungenDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import {LigaSpieltagModusComponent} from "../../general/LigaSpieltagModusComponent";
import InfoDialogEventNichtVeroeffentlichen from "../../general/InfoDialogEventNichtVeroeffentlichen";
import DHDialog from "../../general/DHDialog";
import DHDateTimePicker from "../../general/DHDateTimePicker";
import DHDefaultDrawer from "../../general/DHDefaultDrawer";
import {LigaSpieltagPaarkreuzsystemComponent} from "../../general/LigaSpieltagPaarkreuzsystemComponent";

export default function LigaspieltagVerwaltenDialog({tournamentId, propEventOperatorId, callBackOnClose, callBackRefresh, callBackOnDelete}) {

    const darttypes = [
        {name: 'Steeldarts', code: 'STEELDARTS'},
        {name: 'E-Dart', code: 'EDART'},
    ];

    let [tournament, setTournament] = useState(undefined);

    let [admins, setAdmins] = useState([]);

    let [showBestleistungenDialog, setShowBestleistungenDialog] = useState(false);

    let [eventOperatorId, setEventOperatorId] = useState(propEventOperatorId);

    let [id, setId] = useState(undefined);
    let [turnierstatus, setTurnierstatus] = useState(undefined);
    let [mannschaftHeim, setMannschaftHeim] = useState("");
    let [mannschaftGast, setMannschaftGast] = useState("");
    let [ligaspieltagModus, setLigaspieltagModus] = useState(undefined);
    let [beginn, setBeginn] = useState(undefined);
    let [bezahlung, setBezahlung] = useState(false);
    let [bezahlungbetrag, setBezahlungbetrag] = useState("");
    let [mailtext, setMailtext] = useState("");
    let [homepage, setHomepage] = useState("");
    let [socialmedia, setSocialmedia] = useState("");
    let [austragungsortBezeichnung, setAustragungsortBezeichnung] = useState("");
    let [austragungsortStrasse, setAustragungsortStrasse] = useState("");
    let [austragungsortPLZ, setAustragungsortPLZ] = useState("");
    let [austragungsortOrt, setAustragungsortOrt] = useState("");
    let [austragungsortEmail, setAustragungsortEmail] = useState("");
    let [austragungsortTelefon, setAustragungsortTelefon] = useState("");
    let [publishTournament, setPublishTournament] = useState(true);
    // sobald der EventOperator nicht gesetzt ist, handelt es sich um keinen Veranstalter somit um einen Onlineveranstalter. Dieser kann nur Online spielen.
    let [online, setOnline] = useState(eventOperatorId === null || eventOperatorId === undefined);
    let [scoringWithDarthelfer, setScoringWithDarthelfer] = useState(true);
    let [edartDetailStatistik, setEdartDetailStatistik] = useState(false);
    let [dartType, setDartType] = useState(darttypes[0]);
    let [registrationAllowed, setRegistrationAllowed] = useState(true);
    let [participantsMailConfirmation, setParticipantsMailConfirmation] = useState(true);
    let [beschreibung, setBeschreibung] = useState("");

    let [sets, setSets] = useState(undefined);
    let [bestToLegs, setBestToLegs] = useState(undefined);
    let [firstToLegs, setFirstToLegs] = useState(undefined);
    let [modusId, setModusId] = useState(undefined);
    let [paarkreuzSystemId, setPaarkreuzSystemId] = useState(undefined);
    let [startpunkte, setStartpunkte] = useState(undefined);

    let [showStartzeitpunktNotice, setShowStartzeitpunktNotice] = useState(false);
    let [showPublishTournamentNotice, setShowPublishTournamentNotice] = useState(false);
    let [showScoringNotice, setShowScoringNotice] = useState(false);
    let [showEdartDetailStatisik, setShowEdartDetailStatisik] = useState(false);
    let [showTypeNotice, setShowTypeNotice] = useState(false);
    let [showDartTypeNotice, setShowDartTypeNotice] = useState(false);
    let [showPaarkreuzsytemNotice, setShowPaarkreuzsytemNotice] = useState(false);

    let [showPublishTournamentInfo, setShowPublishTournamentInfo] = useState(false);

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {
        if(!publishTournament) {
            setShowPublishTournamentInfo(true);
        }
    }, [publishTournament]);

    function ladeTurnier(id) {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/ladekodko/" + id,
            json => {
                let tournament = json.tournamentDto;
                setTournament(tournament);
                setId(tournament.id);
                setEventOperatorId(tournament.eventOperatorId);
                setTurnierstatus(tournament.tournamentstatus);
                setBezahlung(tournament.bezahlung);
                setBezahlungbetrag(tournament.bezahlungBetrag);
                setMailtext(tournament.mailtext);
                setOnline(tournament.online);
                setAustragungsortBezeichnung(tournament.austragungsortBezeichnung);
                setAustragungsortStrasse(tournament.austragungsortStrasse);
                setAustragungsortPLZ(tournament.austragungsortPLZ);
                setAustragungsortOrt(tournament.austragungsortOrt);
                setAustragungsortTelefon(tournament.telefon);
                setAustragungsortEmail(tournament.email);
                setSocialmedia(tournament.socialmedia);
                setHomepage(tournament.homepage);
                setParticipantsMailConfirmation(tournament.participantsMailConfirmation);
                setRegistrationAllowed(tournament.registrationAllowed);
                setPublishTournament(tournament.publishTournament);
                setEdartDetailStatistik(tournament.edartDetailStatistik);
                setScoringWithDarthelfer(tournament.scoringWithDarthelfer);
                if( tournament.dartType === null || tournament.dartType === undefined) {
                    setDartType(darttypes[0]);
                } else {
                    let tmpDartType = darttypes[0];
                    for (const tmp of darttypes) {
                        if( tmp.code === tournament.dartType) {
                            tmpDartType = tmp;
                        }
                    }
                    setDartType(tmpDartType);
                }
                setMannschaftHeim(tournament.mannschaftHeim);
                setMannschaftGast(tournament.mannschaftGast);
                setLigaspieltagModus(tournament.ligaspieltagModus);
                setPaarkreuzSystemId(tournament.ligaspieltagPaarkreuzsystemId);
                if (tournament.beginn !== undefined && tournament.beginn !== null) {
                    setBeginn(tournament.beginn);
                }
                setBeschreibung(tournament.beschreibung);
                let adminsNachDemLaden = [];
                for (const tmpAdmin of tournament.administratoren) {
                    adminsNachDemLaden.push(tmpAdmin.userId);
                }
                setAdmins(adminsNachDemLaden);
                let modusEntity = tournament.tournamentModusEntities[0];
                if (modusEntity !== null && modusEntity !== undefined) {
                    setSets(modusEntity.sets);
                    setStartpunkte(modusEntity.startpunkte);
                    setFirstToLegs(modusEntity.firstToLegs);
                    setBestToLegs(modusEntity.bestOfLegs);
                    setModusId(modusEntity.modusid);
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    useEffect(() => {
        if (tournamentId === null || tournamentId === undefined) {
            return;
        }
        ladeTurnier(tournamentId);
    }, []);

    function eventOperatorDatenVorbelegen() {
        setErrorMessage(undefined);

        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/byId/" + eventOperatorId,
            json => {
                setHomepage(json.homepage);
                setSocialmedia(json.socialmedia);
                setAustragungsortBezeichnung(json.austragungsortBezeichnung);
                setAustragungsortStrasse(json.austragungsortStrasse);
                setAustragungsortPLZ(json.austragungsortPLZ);
                setAustragungsortOrt(json.austragungsortOrt);
                setAustragungsortEmail(json.email);
                setAustragungsortTelefon(json.telefon);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Veranstalterdaten: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Veranstalterdaten: " + error.message);
            }
        );
    }

    function save() {
        setErrorMessage(undefined);
        if( eventOperatorId !== undefined && eventOperatorId !== null && bezahlung && bezahlungbetrag === "") {
            setErrorMessage("Sofern du Bezahlung gewählt hast, musst du auch einen Bezahlbetrag eingeben.");
            return;
        }
        let modusArray = [];
        modusArray.push({
            modusTyp: TOURNAMENTMODUS.LIGA.id,
            gameType: "x01",
            modusid: modusId,
            sets: sets,
            firstToLegs: firstToLegs,
            bestOfLegs: bestToLegs,
            startpunkte: startpunkte,
        });

        let adminsZumSpeichern = [];
        for (const admin of admins) {
            adminsZumSpeichern.push({userId: admin});
        }

        let myPublishTournament;
        if( eventOperatorId === null || eventOperatorId === undefined) {
            // kein eventOperator, daher immer veröffentlichen
            myPublishTournament = true;
        } else {
            myPublishTournament =  publishTournament;
        }

        let data = {
            id: id, gameType: "x01", eventOperatorId: eventOperatorId,
            participantsMailConfirmation: participantsMailConfirmation,
            bezahlung: bezahlung,
            bezahlungBetrag: bezahlungbetrag,
            socialmedia: socialmedia,
            homepage: homepage,
            austragungsortBezeichnung: austragungsortBezeichnung,
            austragungsortStrasse: austragungsortStrasse,
            austragungsortPLZ: austragungsortPLZ,
            austragungsortOrt: austragungsortOrt,
            email: austragungsortEmail,
            telefon: austragungsortTelefon,
            mailtext: mailtext,
            registrationAllowed: registrationAllowed,
            publishTournament: myPublishTournament,
            scoringWithDarthelfer: scoringWithDarthelfer,
            dartType: dartType.code,
            edartDetailStatistik: isEdart() ? edartDetailStatistik : false,
            online: online,
            bezeichnung: mannschaftHeim + " vs. " + mannschaftGast,
            beschreibung: beschreibung,
            mannschaftHeim: mannschaftHeim,
            mannschaftGast: mannschaftGast,
            ligaspieltagModus: ligaspieltagModus,
            ligaspieltagPaarkreuzsystemId: paarkreuzSystemId,
            beginn: beginn,
            turniersystem: TOURNAMENTSYSTEMS.LIGASPIELTAG.id,
            administratoren: adminsZumSpeichern,
            tournamentModusEntities: modusArray,
        };
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/save",
            data,
            json => {
                ladeTurnier(json.id);
                setInfoMessage("Turnier wurde erfolgreich gespeichert.");
                callBackRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );
    }


    function showSaveButton() {
        if (!mannschaftHeim || !mannschaftGast || !beginn || !ligaspieltagModus) {
            return false;
        }
        if( eventOperatorId !== null && eventOperatorId !== undefined && (publishTournament === undefined || publishTournament === null)) {
            return false;
        }
        return (firstToLegs || bestToLegs) && modusId && startpunkte;
    }

    function istTurnierGestartet() {
        return turnierstatus === "gestartet";
    }

    function isExternerLigaspieltag() {
        if( !tournament) {
            return false;
        }
        return tournament.externMeetingId;
    }
    function istTurnierAbgeschlossen() {
        return turnierstatus === "abgeschlossen";
    }


    function getX01Area() {
        return <TournamentX01Area showSets={true} showBestOfLegs={true} showPflichtfelder={true}
                           sets={sets}
                           firstToLegs={firstToLegs}
                           bestOfLegs={bestToLegs}
                           modusId={modusId} startpunkte={startpunkte}
                           onSetsChanged={(sets) => setSets(sets)}
                           onFirstToLegsChanged={(firstToLegs) => setFirstToLegs(firstToLegs)}
                           onBestOfLegsChanged={(bestOfLegs) => setBestToLegs(bestOfLegs)}
                           onModusIdChanged={(modusId) => setModusId(modusId)}
                           onStartpunkteChanged={(startpunkte) => setStartpunkte(startpunkte)}/>
    }

    const getButtons = () => {
        return <>
            <Button label="Speichern" icon="pi pi-save" style={{marginRight: 5}} disabled={!showSaveButton()} onClick={() => save()}/>
        </>
    }

    const isEdart = () => {
        return dartType && dartType.code === "EDART";
    }

    return <DHDialog onClose={callBackOnClose} title="Ligaspieltag verwalten" buttons={getButtons()} showCloseButton={true} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div className="grid">
            <DHDefaultDrawer onClose={() => setShowStartzeitpunktNotice(false)} show={showStartzeitpunktNotice} title={"Hinweis"} children={
                <div style={{padding: 20}}>
                    <div style={{marginBottom: 5}}>
                        Sofern du keine Uhrzeit angeben möchtest, wähle bitte 00:00 für die Uhrzeit aus. In diesem Fall werden wir dem Benutzer/Teilnehmer die Uhrzeit nicht anzeigen. Alle anderen
                        Uhrzeiten werden dem Benutzer/Teilnehmer zusätzlich zum Datum angezeigt.
                    </div>
                </div>
            }/>
            <div className="col-fixed" style={{width: 190, color: beginn === undefined || beginn === null ? "red" : ""}}>Startzeitpunkt: *
                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                   onClick={() => setShowStartzeitpunktNotice(!showStartzeitpunktNotice)}/>
            </div>
            <div className="col">
                <DHDateTimePicker disabled={istTurnierGestartet() || istTurnierAbgeschlossen()} value={beginn} changeValue={setBeginn}/>
            </div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width: 190, color: mannschaftHeim === "" ? "red" : ""}}>Name Heimmannschaft: *</div>
            <div className="col"><InputText style={{width: 300}} value={mannschaftHeim} maxLength={80}
                                            onChange={(e) => setMannschaftHeim(e.target.value)}/></div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width: 190, color: mannschaftGast === "" ? "red" : ""}}>Name Gastmannschaft: *</div>
            <div className="col"><InputText style={{width: 300}} value={mannschaftGast}
                                            onChange={(e) => setMannschaftGast(e.target.value)}/></div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width: 190, color: ligaspieltagModus === undefined || ligaspieltagModus === "" ? "red" : ""}}>Modus: *</div>
            <div className="col">
                <LigaSpieltagModusComponent id={ligaspieltagModus} disabled={istTurnierGestartet() || istTurnierAbgeschlossen() || isExternerLigaspieltag()}
                                            onChange={(id) => setLigaspieltagModus(id)}
                />
            </div>
        </div>
        <div className="grid">
            <DHDefaultDrawer onClose={() => setShowPaarkreuzsytemNotice(false)} show={showPaarkreuzsytemNotice} title={"Hinweis"} children={
                <div style={{padding: 20}}>
                    <div style={{marginBottom: 5}}>
                        Paarkreuzsysteme kannst du auf dem Dashboard unter Einstellungen verwalten.
                    </div>
                </div>
            }/>
            <div className="col-fixed" style={{width: 190}}>Paarkreuzsystem:
                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                   onClick={(e) => setShowPaarkreuzsytemNotice(!showPaarkreuzsytemNotice)}/>
            </div>
            <div className="col">
                <LigaSpieltagPaarkreuzsystemComponent onChange={(paarkreuzsystem) => {
                    setPaarkreuzSystemId(paarkreuzsystem ? paarkreuzsystem.id : undefined );
                }}
                                                      showChangeDialog={false}
              eventOperatorId={eventOperatorId} disabled={istTurnierGestartet() || istTurnierAbgeschlossen()} selectedPaarkreuzsystemId={paarkreuzSystemId}/>
            </div>
        </div>
        <div className="grid">
            <DHDefaultDrawer onClose={() => setShowDartTypeNotice(false)} show={showDartTypeNotice} title={"Hinweis"} children={
                <div style={{padding: 20}}>
                    <div style={{marginBottom: 5}}>
                        Dart-Type wird im öffentlichen Bereich angezeigt.
                    </div>
                    <div style={{marginBottom: 5}}>
                        Bei Events von einem Veranstalter ist die richtige Auswahl wichtig, da der Scorer damit gesteuert wird ob E-Dart (Legeingabe) oder Scoring mit dem Scorer möglich ist.
                    </div>
                </div>
            }/>
            <div className="col-fixed" style={{width: 190}}>Dart-Typ:
                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                   onClick={(e) => setShowDartTypeNotice(!showDartTypeNotice)}/>
            </div>
            <div className="col">
                <SelectButton optionLabel="name" value={dartType} options={darttypes} onChange={(e) => setDartType(e.value)}
                              disabled={istTurnierGestartet() || istTurnierAbgeschlossen() || isExternerLigaspieltag()}/>
            </div>
        </div>
        <TabView style={{width: "100%", paddingTop: 10, paddingLeft: 10, paddingRight: 10, border: "none"}}>
            <TabPanel header="Modus">
                {getX01Area()}
            </TabPanel>
            <TabPanel header="Optionale Eingaben">
                <div className="grid">
                    <div className="col-fixed" style={{width: 200}}>Beschreibung:</div>
                </div>
                <div className="grid">
                    <div className="col" style={{width: "100%"}}>
                        <InputTextarea rows={5} cols={50} maxLength={10000} value={beschreibung}
                                       onChange={(e) => setBeschreibung(e.target.value)}/>
                    </div>
                </div>
                {eventOperatorId !== undefined && eventOperatorId !== null && <>
                    <hr/>
                    {(id === null || id === undefined) && <div>Bestleistungen können erst nach dem ersten Speichern eines Turniers festgelegt werden.</div>}
                    <div><Button label="Bestleistungen bearbeiten" disabled={id === null || id === undefined} onClick={() => setShowBestleistungenDialog(true)}/></div>
                </>
                }
            </TabPanel>
            <TabPanel header="Veranstalter" disabled={eventOperatorId === null || eventOperatorId === undefined}>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-6">
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowPublishTournamentNotice(false)} show={showPublishTournamentNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>Datenschutz und Anonymität ist uns sehr wichtig. Auf der öffentlichen
                                        Turnierseite von Darthelfer kannst du dein Turnier anzeigen lassen. Bitte wähle dazu hier 'Ja'.
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>Ligaspieltag veröffentlichen: *
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={(e) => setShowPublishTournamentNotice(!showPublishTournamentNotice)}/>
                            </div>
                            <div className="col">
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={publishTournament}
                                              className={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={() => setPublishTournament(!publishTournament)}/>
                            </div>
                        </div>
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowScoringNotice(false)} show={showScoringNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>
                                        Sofern du dein Turnier mit Darthelfer scoren willst, wähle hier 'Ja'. Den
                                        darthelfer-scorer findest du unter https://scorer.darthelfer.de
                                    </div>
                                    <div style={{marginBottom: 5}}>
                                        Hilfen dazu findest du unter support.darthelfer.de oder auf der Startseite von
                                        darthelfer.de
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>Scoring mit Scorer:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={() => setShowScoringNotice(!showScoringNotice)}/>
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={scoringWithDarthelfer}
                                              className={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={(e) => setScoringWithDarthelfer(!scoringWithDarthelfer)}/>
                            </div>
                        </div>
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowEdartDetailStatisik(false)} show={showEdartDetailStatisik} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>
                                        Sobald du bei einem E-Dart Ligaspieltag diese Option wählst, kannst du
                                        Live in der Kneipe Details zum Spiel erfassen. z.B. T20, ..., Schwarzer...
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>E-Dart Detail-Statistik:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={() => setShowEdartDetailStatisik(!showEdartDetailStatisik)}/>
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={edartDetailStatistik}
                                              disabled={!isEdart()}
                                              className={eventOperatorId === null || eventOperatorId === undefined || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={(e) => setEdartDetailStatistik(!edartDetailStatistik)}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-12 lg:col-6">
                        <Button label="Veranstalter- und Ausrichtungsortdaten aus Standard vorbelegen?" onClick={() => eventOperatorDatenVorbelegen()}/>
                        <h3>Veranstalterdaten</h3>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Telefon:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortTelefon} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortTelefon(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Email:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortEmail} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortEmail(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Socialmedia-Link:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={socialmedia} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setSocialmedia(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Homepage-Link:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={homepage} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setHomepage(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <h3>Daten zum Austragungsort</h3>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Bezeichnung<br/>(z.B Sportheim Hummelshausen):
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortBezeichnung}
                                               disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortBezeichnung(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Strasse:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortStrasse} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortStrasse(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>PLZ:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortPLZ} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortPLZ(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Ort:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortOrt} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortOrt(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
            {(eventOperatorId === null || eventOperatorId === undefined) &&
                <TabPanel header="Admins">
                    <TurnierAdminTabPanel changeAdmins={(admins) => setAdmins(admins)}
                                          onError={(message) => setErrorMessage(message)} admins={admins}/>
                </TabPanel>
            }
            <TabPanel header="Aktionen" disabled={id === undefined}>
                <LigaAktionenComponent showAsSidebar={false} proTournament={tournament} onInfo={(message) => setInfoMessage(message)}
                                       callBackLoadNewTournament={(newId) => {
                                          ladeTurnier(newId);
                                      }}
                                       callBackRefresh={() => {
                                          ladeTurnier(id);
                                          callBackRefresh();
                                      }}
                                       callBackCloseDialog={() => {
                                          callBackOnClose();
                                      }}
                                       callBackOnDelete={() => {
                                         callBackOnDelete();
                                      }}
                                       onClose={() => ""}
                                       onClearMessages={() => setErrorMessage(undefined)}
                                       onError={(message) => setErrorMessage(message)}/>
            </TabPanel>
            {id !== undefined &&
                <TabPanel header="QRCodes">
                    <TurnierQRCode tournamentId={id} eventOperatorId={eventOperatorId} onlineTournament={online} tournamentSystem={TOURNAMENTSYSTEMS.LIGASPIELTAG.id}
                                   bezeichnung={mannschaftHeim + " vs. " + mannschaftGast}/>
                </TabPanel>
            }
        </TabView>
        {showBestleistungenDialog && <BestleistungenDialog onClose={() => setShowBestleistungenDialog(false)} tournamentId={id}/>}
        {showPublishTournamentInfo && <InfoDialogEventNichtVeroeffentlichen onClose={() => setShowPublishTournamentInfo(false)}/>}

        <div style={{marginTop: 20, fontSize: 10}}>TurnierId: {id}</div>
    </>}/>
}
