import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";

export default function RefreshButtonComp({text, autorefresh, onRefresh, onAutoRefresh, shutdownminutes}) {

    let [counter, setCounter] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if( shutdownminutes !== undefined && autorefresh ) {
                setCounter(counter + 1);
                if( counter >= shutdownminutes) {
                    onAutoRefresh(false);
                }
            }
        }, 60000);


        return () => {
            clearInterval(intervalId);
        };
    }, [counter, autorefresh]);

    return <>
        <Button icon="pi pi-refresh"
                className="p-button-primary"
                onClick={() => onRefresh()}
                style={{height: 40, marginBottom:5, marginLeft: 10}}/>
        <Button label={text}
                        icon="pi pi-refresh"
                        className={autorefresh ? "p-button-primary" : "p-button-secondary"}
                        onClick={() => {
                            setCounter(0);
                            onAutoRefresh(!autorefresh);
                        }}
                        style={{height: 40, marginBottom:5, marginRight: 10}}/>
        </>
}
