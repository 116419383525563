import React from 'react'

class Impressum extends React.Component {

    render() {
        return <div style={{backgroundColor: "white", color: "black", textAlign: "left"}}>
            {this.getImpressum()}
        </div>
    }

    getImpressum() {
        return <div>
            <div className="pageHeader" style={{backgroundColor: "#e0e0eb"}}>
                Datenschutz
            </div>
            <div style={{padding:20}}>
                <p>Wir haben diese Datenschutzerklärung (Fassung 07.11.2019-321126352) verfasst, um Ihnen gemäß der
                    Vorgaben
                    der <a className="adsimple-321126352"
                           href="https://eur-lex.europa.eu/legal-content/DE/ALL/?tid=1234&amp;uri=celex%3A32016R0679&amp;tid=321126352"
                           rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> zu erklären, welche
                    Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als
                    Besucher dieser Webseite haben.</p>
                <p>Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns
                    bei
                    der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu
                    beschreiben.</p>
                <h2 className="adsimple-321126352">Automatische Datenspeicherung</h2>
                <p>Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und
                    gespeichert, so auch auf dieser Webseite.</p>
                <p>Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem
                    diese
                    Webseite gespeichert ist) automatisch Daten wie</p>
                <ul className="adsimple-321126352">
                    <li className="adsimple-321126352">die Adresse (URL) der aufgerufenen Webseite</li>
                    <li className="adsimple-321126352">Browser und Browserversion</li>
                    <li className="adsimple-321126352">das verwendete Betriebssystem</li>
                    <li className="adsimple-321126352">die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
                    <li className="adsimple-321126352">den Hostname und die IP-Adresse des Geräts von welchem aus
                        zugegriffen wird
                    </li>
                    <li className="adsimple-321126352">Datum und Uhrzeit</li>
                </ul>
                <p>in Dateien (Webserver-Logfiles).</p>
                <p>In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir
                    geben
                    diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
                    rechtswidrigem Verhalten eingesehen werden.<br/>
                    Die Rechtsgrundlage besteht nach <a className="adsimple-321126352"
                                                        href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=321126352"
                                                        rel="noopener">Artikel 6 Absatz 1 f
                        DSGVO</a> (Rechtmäßigkeit der Verarbeitung) darin, dass berechtigtes Interesse daran besteht,
                    den
                    fehlerfreien Betrieb dieser Webseite durch das Erfassen von Webserver-Logfiles zu ermöglichen.</p>
                <h2 className="adsimple-321126352">Cookies</h2>
                <p>Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.<br/>
                    Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
                    Datenschutzerklärung besser verstehen.</p>
                <h3 className="adsimple-321126352">Was genau sind Cookies?</h3>
                <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind
                    beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten
                    speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
                <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten
                    verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für
                    andere
                    Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem
                    Computer
                    gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi
                    dem &#8220;Hirn&#8221; Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem
                    Wert.
                    Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>
                <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
                    Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die
                    „userbezogenen“
                    Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet
                    Ihnen Ihre gewohnte Standardeinstellung. In einigen Browsern hat jedes Cookie eine eigene Datei, in
                    anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>
                <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden
                    direkt
                    von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google
                    Analytics)
                    erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die
                    Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind
                    keine
                    Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch
                    nicht auf Informationen Ihres PCs zugreifen.</p>
                <p>So können zum Beispiel Cookie-Daten aussehen:</p>
                <ul className="adsimple-321126352">
                    <li className="adsimple-321126352">Name: _ga</li>
                    <li className="adsimple-321126352">Ablaufzeit: 2 Jahre</li>
                    <li className="adsimple-321126352">Verwendung: Unterscheidung der Webseitenbesucher</li>
                    <li className="adsimple-321126352">Beispielhafter Wert: GA1.2.1326744211.152321126352</li>
                </ul>
                <p>Ein Browser sollte folgende Mindestgrößen unterstützen:</p>
                <ul className="adsimple-321126352">
                    <li className="adsimple-321126352">Ein Cookie soll mindestens 4096 Bytes enthalten können</li>
                    <li className="adsimple-321126352">Pro Domain sollen mindestens 50 Cookies gespeichert werden können
                    </li>
                    <li className="adsimple-321126352">Insgesamt sollen mindestens 3000 Cookies gespeichert werden
                        können
                    </li>
                </ul>
                <h3 className="adsimple-321126352">Welche Arten von Cookies gibt es?</h3>
                <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird
                    in
                    der folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf
                    die
                    verschiedenen Arten von HTTP-Cookies eingehen.</p>
                <p>Man kann 4 Arten von Cookies unterscheiden:</p>
                <p>
                    <strong className="adsimple-321126352">Unbedingt notwendige Cookies<br/>
                    </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum
                    Beispiel
                    braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten
                    weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht,
                    selbst wenn der User sein Browserfenster schließt.</p>
                <p>
                    <strong className="adsimple-321126352">Funktionelle Cookies<br/>
                    </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen
                    bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei
                    verschiedenen Browsern gemessen.</p>
                <p>
                    <strong className="adsimple-321126352">Zielorientierte Cookies<br/>
                    </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden
                    eingegebene
                    Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
                <p>
                    <strong className="adsimple-321126352">Werbe-Cookies<br/>
                    </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell
                    angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
                <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten
                    Sie
                    zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
                <h3 className="adsimple-321126352">Wie kann ich Cookies löschen?</h3>
                <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder
                    welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, nur
                    teilweise
                    zuzulassen oder zu deaktivieren. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber
                    alle anderen Cookies zulassen.</p>
                <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie
                    Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen
                    finden:</p>
                <p>
                    <a className="adsimple-321126352"
                       href="https://support.google.com/chrome/answer/95647?tid=321126352"
                       rel="noopener">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
                </p>
                <p>
                    <a className="adsimple-321126352"
                       href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321126352"
                       rel="noopener">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
                </p>
                <p>
                    <a className="adsimple-321126352"
                       href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321126352"
                       rel="noopener">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
                        Ihrem Computer abgelegt haben</a>
                </p>
                <p>
                    <a className="adsimple-321126352"
                       href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321126352"
                       rel="noopener">Internet Explorer: Löschen und Verwalten von Cookies</a>
                </p>
                <p>
                    <a className="adsimple-321126352"
                       href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321126352"
                       rel="noopener">Microsoft Edge: Löschen und Verwalten von Cookies</a>
                </p>
                <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er
                    Sie
                    immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
                    entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser
                    verschieden.
                    Am besten ist es Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome”
                    oder &#8220;Cookies deaktivieren Chrome&#8221; im Falle eines Chrome Browsers oder tauschen das
                    Wort &#8220;Chrome&#8221; gegen den Namen Ihres Browsers, z.B. Edge, Firefox, Safari aus.</p>
                <h3 className="adsimple-321126352">Wie sieht es mit meinem Datenschutz aus?</h3>
                <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern
                    von
                    Cookies eine Einwilligung des Website-Besuchers (also von Ihnen) verlangt. Innerhalb der EU-Länder
                    gibt
                    es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Deutschland wurden die
                    Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser
                    Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).</p>
                <p>Wenn Sie mehr über Cookies wissen möchten und vor technischen Dokumentationen nicht zurückscheuen,
                    empfehlen wir <a className="adsimple-321126352" href="https://tools.ietf.org/html/rfc6265"
                                     rel="nofollow noopener">https://tools.ietf.org/html/rfc6265</a>, dem
                    Request for Comments der Internet Engineering Task Force (IETF) namens &#8220;HTTP State Management
                    Mechanism&#8221;.</p>
                <h2 className="adsimple-321126352">Speicherung persönlicher Daten</h2>
                <p>Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name,
                    E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars
                    oder
                    Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils
                    angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.</p>
                <p>Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt
                    ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen
                    und
                    Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht
                    ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</p>
                <p>Wenn Sie uns persönliche Daten per E-Mail schicken &#8211; somit abseits dieser
                    Webseite &#8211; können
                    wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen,
                    vertrauliche
                    Daten niemals unverschlüsselt per E-Mail zu übermitteln.</p>
                <p>Die Rechtsgrundlage besteht nach <a className="adsimple-321126352"
                                                       href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=321126352"
                                                       rel="noopener">Artikel 6 Absatz 1 a
                    DSGVO</a> (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung
                    der
                    von Ihnen eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit widerrufen &#8211; eine
                    formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.</p>
                <h2 className="adsimple-321126352">Rechte laut Datenschutzgrundverordnung</h2>
                <p>Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:</p>
                <ul className="adsimple-321126352">
                    <li className="adsimple-321126352">Recht auf Berichtigung (Artikel 16 DSGVO)</li>
                    <li className="adsimple-321126352">Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17
                        DSGVO)
                    </li>
                    <li className="adsimple-321126352">Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
                    <li className="adsimple-321126352">Recht auf Benachrichtigung &#8211; Mitteilungspflicht im
                        Zusammenhang
                        mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der
                        Verarbeitung
                        (Artikel 19 DSGVO)
                    </li>
                    <li className="adsimple-321126352">Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
                    <li className="adsimple-321126352">Widerspruchsrecht (Artikel 21 DSGVO)</li>
                    <li className="adsimple-321126352">Recht, nicht einer ausschließlich auf einer automatisierten
                        Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel
                        22
                        DSGVO)
                    </li>
                </ul>
                <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
                    datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an
                    die <a
                        className="adsimple-321126352"
                        href="https://www.bfdi.bund.de/DE/Datenschutz/Ueberblick/MeineRechte/Artikel/BeschwerdeBeiDatenschutzbehoereden.html?tid=321126352"
                        rel="noopener">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
                        (BfDI)</a> wenden.</p>
                <h2 className="adsimple-321126352">Auswertung des Besucherverhaltens</h2>
                <p>In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres Besuchs
                    dieser Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir
                    können von Ihrem Verhalten auf dieser Website nicht auf Ihre Person schließen.</p>
                <p>Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der
                    folgenden
                    Datenschutzerklärung.</p>
                <h2 className="adsimple-321126352">TLS-Verschlüsselung mit https</h2>
                <p>Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch
                    Technikgestaltung <a className="adsimple-321126352"
                                         href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&#038;tid=321126352"
                                         rel="noopener">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz
                    von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im
                    Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser
                    Absicherung der Datenübertragung am kleinen Schloßsymbol links oben im Browser und der Verwendung
                    des
                    Schemas https (anstatt http) als Teil unserer Internetadresse.</p>
                <p>Quelle: Erstellt mit dem <a title="Datenschutz Generator Deutschland"
                                               href="https://www.adsimple.de/datenschutz-generator/"
                >Datenschutz Generator</a> von AdSimple in Kooperation mit <a
                    href="https://www.hashtagbeauty.de" title="">hashtagbeauty.de</a>
                </p>
            </div>
        </div>;
    }
}

export default Impressum;