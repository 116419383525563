import React from 'react'
import PropTypes from "prop-types";
import {Messages} from "primereact/messages";
import ConfigUtil from "../util/ConfigUtil";
import FetchUtil from "../util/FetchUtil";
import {Toast} from "primereact/toast";

class RegistrationComplete extends React.Component {

    constructor(props) {
        super(props);

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
    }

    componentDidMount() {
        this.registrierungAbschliessen();
    }

    showError(message) {
        this.messages.show({
            sticky: true,
            severity: 'error',
            summary: 'Fehler...',
            detail: message
        });
    }

    showInfo(message) {
        this.messages.show({
            sticky: true,
            severity: 'info',
            summary: '',
            detail: message
        });
    }

    registrierungAbschliessen() {
        let userregistrationid = this.props.userregistrationid;
        if (userregistrationid === null || userregistrationid === undefined || userregistrationid === "") {
            this.showError("Aufruf dieser Seite ohne eine Registrierungs-ID ist nicht erlaubt.");
            return;
        }

        let url = ConfigUtil.getConfig().resourceUrls.user + "/registrationcomplete?userregistrationid=" + userregistrationid;
        FetchUtil.fetchPost(url,
            {},
            message => {
                if( message === "") {
                    this.showInfo("Registrierung ist abgeschlossen, du kannst dich jetzt anmelden.");
                } else {
                    this.showError(message);
                }
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error);
            }
        );
    }

    render() {
        return <div style={{backgroundColor: "white", color: "black", textAlign:"center", marginTop:50, height:300, fontSize:25}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <a href={this.props.startseiteUrl}><img alt="Logo" src={"/images/darthelfer.svg"} style={{width: "100%", maxWidth: "100px"}}/>
            <br/>Hier gehts zur Startseite...
            </a>
        </div>
    }
}

RegistrationComplete.propTypes = {
    userregistrationid: PropTypes.string.isRequired,
};

export default RegistrationComplete;
