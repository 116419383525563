import React, {useState} from 'react'
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Button, TableContainer} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import LigaVereinVerwaltenDialog from "./LigaVereinVerwaltenDialog";

export default function LigaVereinComp({eventOperatorId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showVerwaltenDialog, setShowVerwaltenDialog] = useState(false);

    const CELL_PADDING_VALUE = 10;

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{marginTop: 20}}>
            Hinweis:<br/>
            Du kannst Vereine erfassen und im Anschluß einer Mannschaft einen Verein zuweisen. Eine Zuweisung eines Vereins zu einer Mannschaft ist keine Pflicht, eine Mannschaft kann auch ohne Verein existieren.<br/>
            Sobald mehrere Mannschaften einem Verein zugewiesen werden, kann darüber in verschiedenen Dialogen von der Mannschaft zum Verein und dann wieder zu den weiteren Mannschaften navigiert werden.
        </div>
        <div style={{marginTop: 20}}>
            <Button variant="contained" startIcon={<AddCircleOutlineIcon/>} onClick={() => setShowVerwaltenDialog(true)}>Verein hinzufügen</Button>
        </div>
        <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
            <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Verein</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                </TableBody>
            </Table>
        </TableContainer>

        {showVerwaltenDialog && <LigaVereinVerwaltenDialog onClose={() => setShowVerwaltenDialog(false)} eventOperatorId={eventOperatorId}/>}
    </div>
}
