import React, {useEffect, useRef, useState} from 'react'
import {InputText} from "primereact/inputtext";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ConfirmDialog from "../../general/ConfirmDialog";
import {ToggleButton} from "primereact/togglebutton";
import TournamentPlayerEventOperatorSelectDialog from "./TournamentPlayerEventOperatorSelectDialog";
import {Fieldset} from "primereact/fieldset";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import ProgressButton from "../../general/ProgressButton";
import {PrimeIcons} from "primereact/api";
import DHDialog from "../../general/DHDialog";
import DHSelectionComponent from "../../general/DHSeclectionCompontent";

export default function TurnierPlayerDialog({tournament, onClose, onRefresh}) {

    const nameInput = useRef(null);

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [eingabe, setEingabe] = useState("");
    let [spielerid, setSpielerid] = useState("");
    let [spielerIdDoppel, setSpielerIdDoppel] = useState(undefined);
    let [spielerIdSingle1, setSpielerIdSingle1] = useState(undefined);
    let [spielerIdSingle2, setSpielerIdSingle2] = useState(undefined);
    let [search, setSearch] = useState("");
    let [bezahlt, setBezahlt] = useState(false);
    let [anwesend, setAnwesend] = useState(false);
    let [heimmannschaft, setHeimmannschaft] = useState(false);
    let [selectedPlayer, setSelectedPlayer] = useState(undefined);
    let [kratzerPoints, setKratzerPoints] = useState([]);
    let [showDeleteDialog, setShowDeleteDialog] = useState(false);
    let [showSearchDialog, setShowSearchDialog] = useState(false);
    let [showDoppeltTournamentSearchDialog, setShowDoppeltTournamentSearchDialog] = useState(false);
    let [kratzerPointId, setKratzerPointId] = useState(undefined);
    let [searchDialogFuerSpielerIdGesamt, setSearchDialogFuerSpielerIdGesamt] = useState(false);
    let [searchDialogFuerSpielerIdSingle1, setSearchDialogFuerSpielerIdSingle1] = useState(false);
    let [searchDialogFuerSpielerIdSingle2, setSearchDialogFuerSpielerIdSingle2] = useState(false);

    let [showZuordnungArea, setShowZuorndungArea] = useState(false);

    let [workingBySpielerId, setWorkingBySpielerId] = useState(false);
    let [workingByName, setWorkingByName] = useState(false);


    useEffect(() => {
        if (!isKratzerTurnier()) {
            return;
        }
        // Bei Kratzer müssen die Kratzerpunkte geladen werden.
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/kratzerpoints/load/" + tournament.id,
            json => {
                setKratzerPoints([...json]);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Kratzer: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Kratzer: " + error.message);
            }
        );
    }, []);

    const isKratzerTurnier = () => {
        return tournament.turniersystem === TOURNAMENTSYSTEMS.KRATZER.id;
    }

    const isLigaspieltag = () => {
        return tournament.turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id;
    }

    const isEventOperator = () => {
        return tournament.eventOperatorId !== null && tournament.eventOperatorId !== undefined;
    }


    const handelKeyDown = (e) => {
        if (e.keyCode === 13) {
            // enter
            saveByName();
        }
    }

    const saveBySpielerID = () => {
        setErrorMessage(undefined);
        if (isKratzerTurnier() && kratzerPointId === undefined) {
            setErrorMessage("Kratzer müssen ausgewählt werden.");
            return;
        }
        setWorkingBySpielerId(true);
        let data = {
            tournamentId: tournament.id, idShort: spielerid, tournamentKratzerPointsId: kratzerPointId ? kratzerPointId : undefined,
            idShortSinglePlayer1: spielerIdSingle1, idShortSinglePlayer2: spielerIdSingle2, idShortDoppel: spielerIdDoppel,
            bezahlt: bezahlt, anwesend: anwesend, heimmannschaft: heimmannschaft
        };
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/tournamentteilnehmenIdShort",
            data,
            json => {
                setSelectedPlayer(undefined);
                setEingabe("");
                setSpielerid("");
                setKratzerPointId(undefined);
                setBezahlt(false);
                setAnwesend(false);
                setSpielerIdSingle1(undefined);
                setSpielerIdSingle2(undefined);
                setSpielerIdDoppel(undefined);
                setInfoMessage("Spieler wurde erfolgreich gespeichert.");
                onRefresh();
                setWorkingBySpielerId(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
                setWorkingBySpielerId(false);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
                setWorkingBySpielerId(false);
            }
        );
    }

    const saveByName = () => {
        setErrorMessage(undefined);
        if (eingabe === "" || eingabe.trim() === "") {
            setErrorMessage("Spielername muss gefüllt sein.");
            return;
        }
        if (isKratzerTurnier() && !kratzerPointId) {
            setErrorMessage("Kratzer müssen ausgewählt werden.");
            return;
        }
        setWorkingByName(true);
        let id = tournament.id;
        let playerId = selectedPlayer !== undefined ? selectedPlayer.id : undefined;
        let data = {
            tournamentId: id, playerId: playerId, name: eingabe,
            tournamentKratzerPointsId: kratzerPointId !== undefined ? kratzerPointId : undefined,
            idShortSinglePlayer1: spielerIdSingle1, idShortSinglePlayer2: spielerIdSingle2, idShortDoppel: spielerIdDoppel,
            bezahlt: bezahlt, anwesend: anwesend, heimmannschaft: heimmannschaft
        };
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/saveplayer",
            data,
            json => {
                setSelectedPlayer(undefined);
                setEingabe("");
                setKratzerPointId(undefined);
                setBezahlt(false);
                setAnwesend(false);
                setSpielerIdSingle1(undefined);
                setSpielerIdSingle2(undefined);
                setSpielerIdDoppel(undefined);
                setInfoMessage("Spieler wurde erfolgreich gespeichert.");
                onRefresh();
                setWorkingByName(false);
                if( nameInput && nameInput.current ) {
                    nameInput.current.focus();
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
                setWorkingByName(false);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
                setWorkingByName(false);
            }
        );
    }

    const deletePlayer = () => {
        setErrorMessage(undefined);
        let data = {tournamentId: tournament.id, playerId: selectedPlayer.id};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/deleteplayer",
            data,
            json => {
                setSelectedPlayer(undefined);
                setShowDeleteDialog(false);
                setInfoMessage("Spieler wurde erfolgreich gelöscht.");
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Löschen: " + responseNotOk.message);
                setShowDeleteDialog(false);
            },
            error => {
                setErrorMessage("Fehler beim Löschen: " + error.message);
                setShowDeleteDialog(false);
            }
        );
    }

    const getAnzahlSpieler = () => {
        if (tournament === undefined || tournament === null || tournament.tournamentPlayerEntities === undefined || tournament.tournamentPlayerEntities === null) {
            return 0;
        }
        return tournament.tournamentPlayerEntities.length;
    }

    const getKratzerPunkte = (playerId, kratzerPointId) => {
        if (kratzerPointId === null || kratzerPointId === undefined) {
            return "";
        }
        let tmp = kratzerPoints.filter(k => k.id === kratzerPointId)[0];
        if (tmp === null || tmp === undefined) {
            return "";
        }
        return " (" + tmp.bezeichnung + " - Punkte: " + tmp.punkte + ")";
    }

    function getPlayername(row) {
        if (tournament.doppelturnier) {
            return row.name;
        } else {
            let nachname = row.nachname !== null && row.nachname !== undefined ? " , Name: " + row.nachname : "";
            let vorname = row.vorname !== null && row.vorname !== undefined ? " , Vorname: " + row.vorname : "";
            let returnName = row.name + nachname + vorname;
            return returnName;
        }
    }


    const getRows = () => {
        if (tournament === undefined || tournament === null || tournament.tournamentPlayerEntities === undefined || tournament.tournamentPlayerEntities === null) {
            return "";
        }
        let players = tournament.tournamentPlayerEntities;

        let rows = [];
        for (let i = 0; i < players.length; i++) {
            let modulo = i % 2;
            let currentStyle = modulo === 0 ? {backgroundColor: "#f2f2f2"} : {};
            let player = players[i];
            if (search === "" || getPlayername(player).toUpperCase().includes(search.toUpperCase()) || player.idShort.toUpperCase().includes(search.toUpperCase())) {
                rows.push(<div className="grid" style={{marginTop: "5px", ...currentStyle}}>
                    <div className="col-fixed" style={{width: "30px"}}>{i + 1}.</div>
                    <div className="col-fixed" style={{width: "50px"}}>
                        <Button icon="pi pi-trash"
                                onClick={(e) => {
                                    setShowDeleteDialog(true);
                                    setSelectedPlayer(player);
                                    setEingabe("");
                                    setBezahlt(false);
                                    setAnwesend(false);
                                    setHeimmannschaft(true);
                                    setSpielerid("");
                                }}
                                style={{marginRight: "5px"}}/>
                    </div>
                    <div className="col" style={{width: "100%", cursor: "pointer"}} onClick={() => {
                        setSelectedPlayer(player);
                        setEingabe(player.name);
                        setBezahlt(player.bezahlt);
                        setAnwesend(player.anwesend);
                        setHeimmannschaft(player.heimmannschaft);
                        setSpielerIdSingle1(player.idShortSinglePlayer1 === null ? undefined : player.idShortSinglePlayer1);
                        setSpielerIdSingle2(player.idShortSinglePlayer2 === null ? undefined : player.idShortSinglePlayer2);
                        setSpielerIdDoppel(player.idShortDoppel === null ? undefined : player.idShortDoppel);
                        setKratzerPointId(player.tournamentKratzerPointsId);
                    }}>
                        <img style={{height: 30, marginRight: 20}} src={player.bezahlt ? "/images/euro_green.png" : "/images/euro_black.png"} alt="Bezahlung"/>
                        {isEventOperator() && <img style={{height: 20, marginRight: 20}} src={player.anwesend ? "/images/anwesend_color.png" : "/images/anwesend_black.png"} alt="Anwesend"/>}
                        {player.teamplayer && <img src="/images/kachel/tshirt.png" alt="Mannschaftsspieler"
                                                   style={{height: 30, marginRight: 20}}/>}
                        {getPlayername(player)}<span style={{marginLeft: 10, marginRight: 10}}>SpielerId: {player.idShort}</span>{getKratzerPunkte(player.id, player.tournamentKratzerPointsId)}
                        {isLigaspieltag() &&
                            <span style={{marginLeft: 10, marginRight: 10}}>{player.heimmannschaft ? "-Heim-" : "-Gast-"}</span>
                        }
                        {player.idShortDoppel !== null && player.idShortDoppel !== undefined && <>
                            <br/><span style={{marginLeft: 10, marginRight: 10}}>Zuordnung Doppel - SpielerId: {player.idShortDoppel}</span>
                        </>
                        }
                        {player.idShortSinglePlayer1 !== null && player.idShortSinglePlayer1 !== undefined && <>
                            <br/><span style={{marginLeft: 10, marginRight: 10}}>Zuordnung Einzel - SpielerId: {player.idShortSinglePlayer1}</span>
                        </>
                        }
                        {player.idShortSinglePlayer2 !== null && player.idShortSinglePlayer2 !== undefined && <>
                            <br/><span style={{marginLeft: 10, marginRight: 10}}>Zuordnung Einzel - SpielerId: {player.idShortSinglePlayer2}</span>
                        </>
                        }
                    </div>
                </div>);
            }
        }
        return rows;
    }

    const getPlayersArea = () => {
        return <>
            <div className="grid">
                <div className="col-fixed" style={{width: 50}}>Filter:</div>
                <div className="col"><InputText style={{width: 200}} value={search}
                                                onChange={(e) => setSearch(e.target.value)}/></div>
            </div>
            <div className="grid" style={{marginTop: "10px"}}>
                <div className="col" style={{fontWeight: "bold"}}>Bereits angelegte Spieler
                    ({getAnzahlSpieler()}):
                </div>
            </div>
            {getRows()}
        </>
    }

    const getNewPlayerArea = () => {
        return <>
            {tournament.eventOperatorId !== null && tournament.eventOperatorId !== undefined &&
                <div className="grid" style={{marginBottom: 20}}>
                    <div className="col">
                        <Button label="Vorhandenen Spieler suchen und auswählen" icon="pi pi-search-plus"
                                onClick={() => {
                                    setSearchDialogFuerSpielerIdGesamt(true);
                                    setSearchDialogFuerSpielerIdSingle1(false);
                                    setSearchDialogFuerSpielerIdSingle2(false);
                                    setShowSearchDialog(true);
                                }}/>
                    </div>
                </div>
            }
            <div className="grid">
                <div className="col-fixed" style={{width: 150}}>SpielerID:</div>
                <div className="col">
                    <InputText style={{width: 160, marginRight: 5}} value={spielerid}
                               disabled={workingBySpielerId}
                               onChange={(e) => setSpielerid(e.target.value)}/>
                    <ProgressButton text="Speichern" onClick={() => saveBySpielerID()} disabled={spielerid.length === 0} working={workingBySpielerId} icon={PrimeIcons.SAVE}/>
                    <Button icon="pi pi-file-o"
                            tooltip="Reset Eingabe"
                            onClick={(e) => {
                                setSelectedPlayer(undefined);
                                setSpielerid("");
                                setEingabe("");
                                setBezahlt(false);
                                setAnwesend(false);
                                setSpielerIdSingle1(undefined);
                                setSpielerIdSingle2(undefined);
                            }}
                            style={{marginLeft: "5px"}}/>

                </div>
            </div>
            <div className="grid">
                {tournament.doppelturnier && !tournament.dyp &&
                    <div className="col-fixed"
                         style={{width: 150}}>{selectedPlayer !== undefined ? "Doppelname ändern:" : "Doppelname:"}</div>
                }
                {(!tournament.doppelturnier || tournament.dyp) &&
                    <div className="col-fixed"
                         style={{width: 150}}>{selectedPlayer !== undefined ? "Person ändern:" : "Neue Person:"}</div>
                }
                <div className="col">
                    <InputText style={{width: 160, marginRight: 5}} value={eingabe}
                               onChange={(e) => setEingabe(e.target.value)}
                               disabled={workingByName} ref={nameInput}
                               onKeyDown={(e) => handelKeyDown(e)} />
                    <ProgressButton text="Speichern" onClick={() => saveByName()} disabled={eingabe.length === 0} working={workingByName} icon={PrimeIcons.SAVE}/>
                    <Button icon="pi pi-file-o"
                            tooltip="Reset Eingabe"
                            onClick={(e) => {
                                setSelectedPlayer(undefined);
                                setEingabe("");
                                setSpielerid("");
                                setBezahlt(false);
                                setAnwesend(false);
                                setSpielerIdSingle1(undefined);
                                setSpielerIdSingle2(undefined);
                            }}
                            style={{marginLeft: "5px"}}/>
                </div>
            </div>
            {!isLigaspieltag() &&
                <div className="grid">
                    <div className="col-fixed"
                         style={{width: 150}}>Bezahlt:
                    </div>
                    <div className="col">
                        <ToggleButton style={{width: 150}} checked={bezahlt} offLabel="Nein" onLabel="Ja"
                                      onChange={(e) => setBezahlt(!bezahlt)}/>
                    </div>
                </div>
            }
            {!isLigaspieltag() && isEventOperator() &&
                <div className="grid">
                    <div className="col-fixed"
                         style={{width: 150}}>Anwesend:
                    </div>
                    <div className="col">
                        <ToggleButton style={{width: 150}} checked={anwesend} offLabel="Nein" onLabel="Ja"
                                      onChange={(e) => setAnwesend(!anwesend)}/>
                    </div>
                </div>
            }
            <div style={{marginTop: 10, marginBottom: 10, cursor: "pointer"}} onClick={() => setShowZuorndungArea(!showZuordnungArea)}>
                <img src="/images/pfeil_rechts_rot_transparent.png" style={{height: 10, marginRight: 5}}/>{showZuordnungArea ? "Verberge " : "Zeige "} Spielerzuordnungsbereich bzgl. Boardvergabe...
            </div>
            {showZuordnungArea && tournament.doppelturnier && tournament.eventOperatorId !== null && tournament.eventOperatorId !== undefined && <div>
                <hr/>
                <div style={{marginBottom: 5, fontWeight: "bold"}}>Hinweis beachten...</div>
                <div style={{marginBottom: 5}}>Auswahl SpielerId für die Einzelspieler ist nur notwendig, wenn die Boardvergabe genutzt werden soll. Damit wird ausgeschlossen, dass ein Spieler
                    gleichzeitig doppelt aufgerufen wird.
                </div>
                <div style={{display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center", marginBottom: 10}}>
                    <span style={{width: 150}}>SpielerId - Spieler1:</span>
                    {spielerIdSingle1 !== undefined ? <>
                            {spielerIdSingle1}
                            <Button icon="pi pi-trash" style={{marginLeft: 10}}
                                    onClick={() => setSpielerIdSingle1(undefined)}/>
                        </>
                        :
                        <Button label="Spieler auswählen" icon="pi pi-search-plus"
                                onClick={() => {
                                    setSearchDialogFuerSpielerIdSingle1(true);
                                    setSearchDialogFuerSpielerIdSingle2(false);
                                    setSearchDialogFuerSpielerIdGesamt(false);
                                    setShowSearchDialog(true);
                                }}/>
                    }
                </div>
                <div style={{display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center", marginBottom: 10}}>
                    <span style={{width: 150}}>SpielerId - Spieler2:</span>
                    {spielerIdSingle2 !== undefined ? <>
                            {spielerIdSingle2}
                            <Button icon="pi pi-trash" style={{marginLeft: 10}}
                                    onClick={() => setSpielerIdSingle2(undefined)}/>
                        </>
                        :
                        <Button label="Spieler auswählen" icon="pi pi-search-plus"
                                onClick={() => {
                                    setSearchDialogFuerSpielerIdSingle2(true);
                                    setSearchDialogFuerSpielerIdSingle1(false);
                                    setSearchDialogFuerSpielerIdGesamt(false);
                                    setShowSearchDialog(true);
                                }}/>
                    }
                </div>
                <hr/>
            </div>}
            {showZuordnungArea && !tournament.doppelturnier && tournament.eventOperatorId !== null && tournament.eventOperatorId !== undefined && <div>
                <hr/>
                <div style={{marginBottom: 5, fontWeight: "bold"}}>Hinweis beachten...</div>
                <div style={{marginBottom: 5}}>Zuordnung eines Einzelspielers zu einem Doppelpartner ist nur notwendig, wenn die Boardvergabe genutzt werden soll. Damit wird ausgeschlossen, dass ein
                    Spieler gleichzeitig doppelt aufgerufen wird.
                </div>
                <div style={{display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center", marginBottom: 10}}>
                    <span style={{width: 150}}>SpielerId - Doppel:</span>
                    {spielerIdDoppel !== undefined ? <>
                            {spielerIdDoppel}
                            <Button icon="pi pi-trash" style={{marginLeft: 10}}
                                    onClick={() => setSpielerIdDoppel(undefined)}/>
                        </>
                        :
                        <Button label="Doppel auswählen" icon="pi pi-search-plus"
                                onClick={() => {
                                    setShowDoppeltTournamentSearchDialog(true);
                                }}/>
                    }
                </div>
                <hr/>
            </div>}
            {isLigaspieltag() &&
                <div className="grid">
                    <div className="col-fixed"
                         style={{width: 150}}>Spieler der Heimmanschaft:
                    </div>
                    <div className="col">
                        <ToggleButton style={{width: 200}} checked={heimmannschaft} offLabel="Nein" onLabel="Ja"
                                      onChange={(e) => setHeimmannschaft(!heimmannschaft)}/>
                    </div>
                </div>
            }
            {isKratzerTurnier() &&
                <div className="grid">
                    <div className="col-fixed"
                         style={{width: 150}}>Kratzer Gruppe:
                    </div>
                    <div className="col">
                        <DHSelectionComponent title="Kratzer" disabled={false} value={kratzerPointId} onChange={(id) => setKratzerPointId(id) }
                                              values={getKratzerPointValues()} />
                    </div>
                </div>
            }
        </>
    }

    const getKratzerPointValues = () => {
        let auswahl = [];
        auswahl.push({name: 'Auswahl löschen', code: undefined});
        for (const k of kratzerPoints) {
            auswahl.push({name: k.bezeichnung + " (Punkte: " + k.punkte + ")", code: k.id})
        }
        return auswahl;
    }

    return <DHDialog title="Spieler anlegen/bearbeiten" showCloseButton={true}
                     onClose={() => onClose()} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <Fieldset legend="Wichtiger Hinweis" toggleable={true} collapsed={true} style={{marginBottom: 20}}>
        <h4>Hinweis: Solltest du die automatische Boardvergabe nutzen wollen, ist es wichtig, dass in evtl. gleichzeitig laufenden Turnieren mit gleichen Teilnehmern der technisch gleiche
                Spieler genutzt wird. Damit wird verhindert, dass der Spieler in mehreren Turnieren gleichzeitig spielen muss. Erfasse somit den Spieler nur von Hand, wenn er noch nicht in einem
                anderen Turnier spielt. Sofern er in einem anderen Turnier bereits angemeldet ist, suche diesen Spieler über den 'Vorhandenen Spieler...' Button und füge ihn darüber hinzu. Dies
                ist ebenfalls bei Ranglisten notwendig, da die einzelnen Punkte der einzelnen Turniere über diese SpielerId zusammengefügt werden.</h4>
            <h4>Sofern du den Spieler immer mit der SpielerId erfasst, ist dies natürlich ebenfalls ok.</h4>
        </Fieldset>
        <div className="grid">
            <div className="col-12 md:col-12 lg:col-4" style={{padding: 10, borderRight:"1px solid gray"}}>
                {getNewPlayerArea()}
            </div>
            <div className="col-12 md:col-12 lg:col-8" style={{padding: 10}}>
                {getPlayersArea()}
            </div>
        </div>
        {showDoppeltTournamentSearchDialog && <TournamentPlayerEventOperatorSelectDialog
            onlyDoppelturnier={true}
            eventOperatorId={tournament.eventOperatorId}
            callBackOnClose={() => setShowDoppeltTournamentSearchDialog(false)}
            callBackOnSelect={(spielerId) => {
                setSpielerIdDoppel(spielerId);
                setShowDoppeltTournamentSearchDialog(false);
            }}/>
        }
        {showSearchDialog && <TournamentPlayerEventOperatorSelectDialog
            eventOperatorId={tournament.eventOperatorId}
            onlyEinzelturnier={searchDialogFuerSpielerIdSingle1 || searchDialogFuerSpielerIdSingle2}
            callBackOnClose={() => setShowSearchDialog(false)}
            callBackOnSelect={(spielerId) => {
                if (searchDialogFuerSpielerIdGesamt) {
                    setSpielerid(spielerId);
                    setEingabe("");
                    setSelectedPlayer(undefined);
                    setKratzerPointId(undefined);
                    setBezahlt(false);
                    setAnwesend(false);
                    setShowSearchDialog(false);
                } else if (searchDialogFuerSpielerIdSingle1) {
                    setSpielerIdSingle1(spielerId);
                    setShowSearchDialog(false);
                } else if (searchDialogFuerSpielerIdSingle2) {
                    setSpielerIdSingle2(spielerId);
                    setShowSearchDialog(false);
                }
            }}/>
        }
        {showDeleteDialog &&
            <ConfirmDialog visible={true}
                           callBackOnNein={() => setShowDeleteDialog(false)}
                           callBackOnJa={() => deletePlayer()}
                           header="Spieler löschen"
                           textOben=""
                           textUnten="Möchtest du den Spieler wirklich löschen?"/>
        }
    </>}/>
}