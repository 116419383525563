import React, {useEffect, useRef, useState} from 'react'
import Description from "./Description";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ConfirmDialog from "../../general/ConfirmDialog";
import {TextField} from "@mui/material/";
import {PDFDownloadLink} from "@react-pdf/renderer";
import BoardDocument from "../../pdf/boards/BoardDocument";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import QRCodeSidebar from "../../general/QRCodeSidebar";
import {OverlayPanel} from "primereact/overlaypanel";
import {Fieldset} from "primereact/fieldset";
import {PrimeIcons} from "primereact/api";

export default function VeranstalterScoringArea({eventOperatorId}) {

    const hilfeOP = useRef(null);

    const URL = "https://www.darthelfer.de/api/client/v1";

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showHinweis, setShowHinweis] = useState(true);

    let [eventOperatorToken, setEventOperatorToken] = useState("");
    let [showTokenQuestion, setShowTokenQuestion] = useState(false);
    let [boards, setBoards] = useState([]);
    let [boardBezeichnung, setBoardBezeichnung] = useState("");
    let [autodartsBoardId, setAutodartsBoardId] = useState("");
    let [selectedBoard, setSelectedBoard] = useState(undefined);
    let [showDeleteBoardDialog, setShowDeleteBoardDialog] = useState(false);
    let [showBoardChange, setShowBoardChange] = useState(false);
    let [showQRCodeBereich, setShowQRCodeBereich] = useState(false);
    let [openLiveViewBoardId, setOpenLiveViewBoardId] = useState(undefined);

    let [qrCodeId, setQRCodeId] = useState(undefined);

    useEffect(() => {
        loadBoards();
    }, []);

    useEffect(() => {
        setQRCodeId(undefined);
        if( showQRCodeBereich ) {
            erzeugeQRCodeId();
        }
    }, [showQRCodeBereich])

    const erzeugeQRCodeId = () => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/generate/boardconnect/" + eventOperatorId,
            {},
            json => {
                setQRCodeId(json.id);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Erzeugen des QR Codes: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Erzeugen des QR Codes: " + error.message);
            });
    }

    const loadBoards = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/getBoards/" + eventOperatorId,
            json => {
                setBoards(json.boards);
                setEventOperatorToken(json.eventOperatorToken)
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            });
    }

    const getBoardConnectLink = () => {
        let tmpURL = undefined;
        let basicURL = ConfigUtil.getBasicClientUrl();
        if( basicURL.includes("test.darthelfer.de")) {
            tmpURL = "https://client.darthelfer.de/connect?id=" + qrCodeId;
        } else if( basicURL.includes("darthelfer.de")) {
            tmpURL = "https://scorer.darthelfer.de/connect?id=" + qrCodeId;
        } else {
            tmpURL = "http://localhost:3001/connect?id=" + qrCodeId;
        }
        return tmpURL;
    }

    const deleteBoard = () => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/deleteBoard/" + selectedBoard.id,
            undefined,
            json => {
                setInfoMessage("Board wurde gelöscht.");
                loadBoards();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }


    const saveBoardChange = () => {
        let data = {id: selectedBoard !== undefined ? selectedBoard.id : undefined, bezeichnung: boardBezeichnung, autodartsBoardId: autodartsBoardId};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/saveBoard/" + eventOperatorId,
            data,
            json => {
                setInfoMessage("Board wurde gespeichert.");
                loadBoards();
                setBoardBezeichnung("");
                setAutodartsBoardId("");
                setSelectedBoard(undefined);
                setShowBoardChange(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const generateToken = () => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/generateToken/" + eventOperatorId,
            undefined,
            json => {
                setInfoMessage("Token wurde generiert.");
                loadBoards();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const getBoards = () => {
        if( boards.length === 0) {
            return <div style={{marginTop:30}}>Keine Boards vorhanden.</div>;
        }
        let rows = [];
        for (const board of boards) {
            rows.push(<div key={"key_board_" + board.id} style={{fontSize:16, marginBottom:10}}>
                <Button icon="pi pi-trash" tooltip="Board löschen"
                        disabled={board.eventOperatorIdShared}
                        style={{marginRight: "5px", marginTop: "5px"}}
                        onClick={() => {
                            setSelectedBoard(board);
                            setShowDeleteBoardDialog(true);
                        }}/>
                <Button icon="pi pi-pencil" tooltip="Board bearbeiten"
                        disabled={board.eventOperatorIdShared}
                        style={{marginRight: "5px", marginTop: "5px"}}
                        onClick={() => {
                            setBoardBezeichnung(board.bezeichnung);
                            setAutodartsBoardId(board.autodartsBoardId !== undefined && board.autodartsBoardId !== null ? board.autodartsBoardId : "");
                            setSelectedBoard(board);
                            setShowBoardChange(true);
                        }}/>
                <Button icon={PrimeIcons.SEARCH} tooltip="Live-View"
                        style={{marginRight: "5px", marginTop: "5px"}}
                        onClick={() => {
                            setOpenLiveViewBoardId(board.id)
                        }}/>
                <span style={{width:30, marginRight:10}}>Nr. {board.number}</span>Bezeichnung: '{board.bezeichnung}' Board-Token: <span style={{fontWeight:"bold"}}>{board.token}</span>
                {board.autodartsBoardId && <img src="/images/autodarts_small.svg" style={{marginLeft:5, width:25, height:25}}/>}
                { board.eventOperatorIdShared &&
                    <div>
                        <div style={{fontSize:12}}>
                            {board.beschreibungShared}
                        </div>
                    </div>
                }
            </div>
            );
        }
        return rows;
    }

    const getBoardLiveViewUrl = () => {
        return ConfigUtil.getBasicClientUrl() +
            "/public/eventoperator/liveview?eventoperatorid=" + eventOperatorId + "&boardid=" + openLiveViewBoardId;
    }
    if( openLiveViewBoardId ) {
        window.open(getBoardLiveViewUrl(), "_blank");
        setOpenLiveViewBoardId(undefined);
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <Fieldset legend="Wichtige Hinweise" toggleable collapsed={showHinweis} onToggle={(e) => setShowHinweis(!showHinweis)} style={{marginBottom: 10}}>
            <Description message="Generiere dir ein Token und eine beliebige Anzahl von Boards."/>
            <Description
                message="Sobald du den darthelfer-scorer zur automatischen Ergebnisübertragung von Turnieren und Ligen an Darthelfer nutzen möchtest, trage im darthelfer-scorer diesen Token und ein Board-Token ein."/>
            <Description message="Der Token ist für alle Boards innerhalb des Veranstalters gleich, der Board-Token ist je Board unterschiedlich."/>
            <Description
                message="Solltest du nicht über den darthelfer-scorer spielen, aber trotzdem (z.B. bei E-Dart Turnieren) die Boardvergabe nutzen wollen, musst du ebenfalls ein Token und Boards generieren."/>
            <div style={{color: "red"}}>
                <Description message="Du kannst den darthelfer-scorer nicht nutzen, sofern du keinen Veranstalter Pro-Account besitzt." bold={true}/>
            </div>
            <div style={{color: "blue", marginTop:20}}>
                <Description message="Autodarts" bold={true}/>
            </div>
            <Description
                message="Sofern du an dem eingerichteten Board ein Autodarts-System hast, trage bei Autodarts-BoardId die entsprechende ID ein. Wenn nicht, lass das Feld bitte leer."/>
        </Fieldset>
        {eventOperatorToken !== null && eventOperatorToken !== undefined &&
            <PDFDownloadLink
                document={<BoardDocument link={URL} token={eventOperatorToken} boards={boards}/>}
                fileName="boards.pdf">
                {({loading}) => (loading ? "Loading..." :
                    <div><img src="/images/pdf.png" style={{width: 30, marginRight: 10}} alt="PDF"/>Download
                        Zusammenfassung PDF</div>)}
            </PDFDownloadLink>
        }

        <div style={{marginTop:20, fontWeight:"bold", fontSize:20, textAlign:"left", width:"100%", marginBottom:20}}>
            Link zum Eintragen in den darthelfer-scorer (Darthelfer URL)<br/>{URL}
        </div>


        {eventOperatorToken === undefined && <div style={{marginTop:20, marginBottom:20}}>
            <div>Du hast noch keinen Token generiert. Sofern du mit dem darthelfer-scorer spielen möchtest und die Ergebnisse automatisch nach Darthelfer übertragen möchtest, generiere ein Token.</div>
            <div><Button label="Token generieren" style={{width:150}} type="button" onClick={() => generateToken()}/></div>
        </div>}
        {eventOperatorToken !== undefined && <div style={{marginTop:20, marginBottom:20}}>
            <ConfirmDialog header="Frage..."
                           textOben="Sobald du das Token neu generierst, musst du diesen an allen Boards neu eintragen."
                           textUnten="Möchtest du das Token neu generieren?"
                           visible={showTokenQuestion}
                           callBackOnNein={() => setShowTokenQuestion(false)}
                           callBackOnJa={() => {
                               setShowTokenQuestion(false);
                               generateToken();
                           }}/>
            <div style={{fontSize:20}}>Dein aktueller Token: <span style={{fontWeight:"bold", marginRight:10}}>{eventOperatorToken}</span><Button label="Token neu generieren" style={{width:200}} type="button" onClick={() => setShowTokenQuestion(true)}/></div>
        </div>}
        <hr/>
        <div>
            <span style={{cursor:"pointer"}} onClick={() => setShowQRCodeBereich(true)}>-> Hier QR Code erstellen lassen und am Tablet scannen.</span>
            <img src="/images/question.png" alt="Frage" style={{height: 20, width: 20, marginRight: 10, cursor: "pointer"}}  onClick={(e) => hilfeOP.current.toggle(e)}/>
            <OverlayPanel ref={hilfeOP}
                          showCloseIcon={false} dismissable={true}>
                <div>
                    <a href="https://support.darthelfer.de/viewtopic.php?t=100" target="_blank">Weitere Hilfe erhälst du in unserem Supportforum</a>
                </div>
            </OverlayPanel>
        </div>
        <hr/>
        {eventOperatorToken !== undefined && <>
            <div style={{marginTop: 20, marginBottom: 20}}>
                <TextField id="standard-name" label="Boardbezeichnung" variant="standard" value={boardBezeichnung}
                           onChange={(e) => {
                               if (e.target.value.length <= 50) {
                                   setBoardBezeichnung(e.target.value);
                               }
                           }} style={{width: 300, marginRight: 10}}/>
                <TextField id="standard-autodarts" label="Autodarts-BoardId (Hinweis oben beachten)" variant="standard" value={autodartsBoardId}
                           onChange={(e) => {
                               if (e.target.value.length <= 50) {
                                   setAutodartsBoardId(e.target.value);
                               }
                           }} style={{width: 300, marginRight: 10}}/>
            </div>
            <div style={{marginTop: 20, marginBottom: 20, display:"flex"}}>
                <div style={{width:300}}>
                    {!showBoardChange &&
                        <Button label="Neues Board generieren" style={{width: 200}} type="button" onClick={() => saveBoardChange()} disabled={boardBezeichnung === ""}/>
                    }
                    {showBoardChange &&
                        <Button label="Änderung speichern" style={{width: 200}} type="button" onClick={() => saveBoardChange()} disabled={boardBezeichnung === ""}/>
                    }
                </div>
                <div style={{width: 300, padding:5, backgroundImage: "linear-gradient(to left, #1aa3ff, #0059B3)"}}>
                    <img src="/images/autodarts_powered_by.png" style={{height: 40, marginRight: 10}}/>
                </div>
            </div>
        </>}
        {eventOperatorToken !== undefined && getBoards()}
        <ConfirmDialog header="Frage..."
                       textOben="Sofern du das Board löscht, kann darüber nicht mehr mit den darthelfer-scorer gespielt werden."
                       textUnten="Möchtest du das Board wirklich löschen?"
                       visible={showDeleteBoardDialog}
                       callBackOnNein={() => setShowDeleteBoardDialog(false)}
                       callBackOnJa={() => {
                           setShowDeleteBoardDialog(false);
                           deleteBoard();
                       }}/>
        {showQRCodeBereich && qrCodeId !== undefined &&
            <QRCodeSidebar kritisch={false} beschreibung=
                {<div>Scanne mit deinem Tablet den QR Code, die Einrichtung eines Boards erfolgt dann automatisch.
                    <br/><br/>
                    <span style={{fontWeight:"bold"}}>Wichtig! Der Scorer muss am Tablet schon mal geöffnet worden sein. Gehe bitte am Tablet in den Browser und öffne scorer.darthelfer.de</span>
                    <br/><br/>Bitte beachte weitere Hinweise auf dem Tablet.</div>} link={getBoardConnectLink()}
                           onClose={() => setShowQRCodeBereich(false)}/>}
    </div>
}
