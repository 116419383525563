import React from "react";
import PropTypes from "prop-types";
import {Button} from 'primereact/button';
import {Fieldset} from "primereact/fieldset";
import DHDialog from "../../general/DHDialog";

class DoppelQuestionDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {anzahlAmDoppelVorbei: undefined};

        this.keydownEventFunc = this.keydownEventFunc.bind(this);
        this.sendAnzahlWuerfe = this.sendAnzahlWuerfe.bind(this);
        this.setAnzahlAmDoppelVorbei = this.setAnzahlAmDoppelVorbei.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    keydownEventFunc(event) {
        switch (event.key) {
            case "F1":
            case "1":
                this.setAnzahlAmDoppelVorbei(1);
                break;
            case "F2":
            case "2":
                this.setAnzahlAmDoppelVorbei(2);
                break;
            case "F3":
            case "3":
                this.setAnzahlAmDoppelVorbei(3);
                break;
            case "F4":
            case "0":
                this.setAnzahlAmDoppelVorbei(0);
                break;
            case "F5":
            case "5":
                this.sendAnzahlWuerfe(1);
                break;
            case "F6":
            case "6":
                this.sendAnzahlWuerfe(2);
                break;
            case "F7":
            case "7":
                this.sendAnzahlWuerfe(3);
                break;
            default:
                event.preventDefault();
        }
        event.preventDefault();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( !prevProps.visible && this.props.visible) {
            window.addEventListener('keydown',  this.keydownEventFunc);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keydownEventFunc);
    }

    closeDialog() {
        window.removeEventListener('keydown', this.keydownEventFunc);
        this.setState({anzahlAmDoppelVorbei: undefined});
        this.props.callBackOnClose();
    }

    setAnzahlAmDoppelVorbei(anz) {
        if( !this.props.showAnzahlWuerfe) {
            // anzahl würfe wird nicht abgefragt, da noch rest vorhanden.
            // deshalb anzahlAmDoppelVorbei direkt zum Server senden.
            let anzahl;
            if( this.props.anzahlWuerfe !== null && this.props.anzahlWuerfe !== undefined) {
                anzahl = this.props.anzahlWuerfe;
            } else {
                anzahl = 3;
            }
            this.props.sendAnzahlWuerfe(anzahl, anz);
            this.closeDialog();
        } else {
            this.setState({anzahlAmDoppelVorbei: anz});
        }
    }

    sendAnzahlWuerfe(anz) {
        this.props.sendAnzahlWuerfe(anz, this.state.anzahlAmDoppelVorbei);
        this.closeDialog();
    }

    render() {
        if( !this.props.visible ) {
            return "";
        }

        return <DHDialog onClose={() => this.closeDialog()} title="Erfasse Würfe" show={this.props.visible} showCloseButton={true}
                         comp={<>
            <div>
                Wieviel Darts hast du am Doppel vorbei geworfen?<br/><br/>
            </div>
            <div style={{fontWeight:"bold", marginBottom:20}}>Schnellaktion:<br/>
                <span style={{marginLeft:10, marginRight:10}}>F1 oder 1 = 1</span>
                <span style={{marginLeft:10, marginRight:10}}>F2 oder 2 = 2</span><br/>
                <span style={{marginLeft:10, marginRight:10}}>F3 oder 3 = 3</span>
                <span style={{marginLeft:10, marginRight:10}}>F4 oder 0 = 0</span>
            </div>
            <div>
                <Button label="0" style={{width:50, height:50, marginRight:5}} onClick={() => this.setAnzahlAmDoppelVorbei(0)} className={this.state.anzahlAmDoppelVorbei === 0 ? "" : "p-button-secondary"}/>
                <Button label="1" style={{width:50, height:50, marginRight:5}} onClick={() => this.setAnzahlAmDoppelVorbei(1)} className={this.state.anzahlAmDoppelVorbei === 1 ? "" : "p-button-secondary"}/>
                <Button label="2" style={{width:50, height:50, marginRight:5}} onClick={() => this.setAnzahlAmDoppelVorbei(2)} className={this.state.anzahlAmDoppelVorbei === 2 ? "" : "p-button-secondary"}/>
                {!this.props.showAnzahlWuerfe && <Button label="3" style={{width:50, height:50, marginRight:5}} onClick={() => this.setAnzahlAmDoppelVorbei(3)}  className={this.state.anzahlAmDoppelVorbei === 3 ? "" : "p-button-secondary"}/>}
            </div>
            {this.props.showAnzahlWuerfe && this.state.anzahlAmDoppelVorbei !== undefined && <>
                <hr/>
                <div style={{marginTop:20}}>
                    Erfasse die Anzahl der Würfe die du zum 'finishen' gebraucht hast.<br/><br/>
                </div>
                <div style={{fontWeight:"bold", marginBottom:20}}>Schnellaktion:
                    <span style={{marginLeft:10, marginRight:10}}>F5 oder 5 = 1</span>
                    <span style={{marginLeft:10, marginRight:10}}>F6 oder 6 = 2</span>
                    <span style={{marginLeft:10, marginRight:10}}>F7 oder 7 = 3</span>
                </div>
                <div>
                    <Button label="1" style={{width:50, height:50, marginRight:5}} onClick={() => this.sendAnzahlWuerfe(1)}/>
                    <Button label="2" style={{width:50, height:50, marginRight:5}} onClick={() => this.sendAnzahlWuerfe(2)}/>
                    <Button label="3" style={{width:50, height:50, marginRight:5}} onClick={() => this.sendAnzahlWuerfe(3)}/>
                </div>
            </>
            }
            <Fieldset legend="Hinweis..." style={{marginTop:20}}>
                <div>Als angemeldeter Benutzer kannst du diese Eingabe verhindern.</div>
                <div>Unter Einstellungen kannst du einstellen, dass diese Eingabe nicht mehr von dir verlangt wird.</div>
                <div>Damit verzichtest du auf die Eingabe deiner Doppelquote und spielst einfach nur Dart.</div>
                <div>Wir sind ja alle keine Statistiker, sondern Dartspieler. ;-)</div>
                <div style={{marginTop:20}}>Und... manchmal macht die Eingabe von 3 Würfen oder so keinen Sinn, wir zeigen es dir trotzdem an. Erfasse einfach richtig, dass System ist nicht schuld wenn du 3 Würfe erfasst obwohl 3 Würfe gar nicht möglich waren.</div>
            </Fieldset>
         </>}/>
    }
}


DoppelQuestionDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    showAnzahlWuerfe: PropTypes.bool.isRequired,
    callBackOnClose: PropTypes.func.isRequired,
    sendAnzahlWuerfe: PropTypes.func.isRequired,
    anzahlWuerfe: PropTypes.number,
};

export default DoppelQuestionDialog;
