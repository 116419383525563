import React, {useEffect, useRef, useState} from 'react'
import PropTypes from "prop-types";
import PublicTournamentHighlightsTab from "./PublicTournamentHighlightsTab";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import {Menu} from "primereact/menu";
import {PrimeIcons} from "primereact/api";
import PublicTournamentStatistikTab from "./PublicTournamentStatistikTab";
import PublicTournamentParticipantTab from "./PublicTournamentParticipantTab";
import PublicTournamentRegistrationTab from "./PublicTournamentRegistrationTab";
import PublicTournamentTreeTab from "./PublicTournamentTreeTab";
import ConfigUtil from "../../../../util/ConfigUtil";
import FetchUtil from "../../../../util/FetchUtil";
import PublicTournamentGroupsTab from "./PublicTournamentGroupsTab";
import PublicTournamentLiveTab from "./PublicTournamentLiveTab";
import PublicTournamentDetailsTab from "./PublicTournamentDetailsTab";
import PublicTournamentKratzerGamesTab from "./PublicTournamentKratzerGamesTab";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import RefreshButtonComp from "../../../RefreshButtonComp";
import PublicTournamentRanglisteTab from "./PublicTournamentRanglisteTab";
import PublicTournamentBreadCumb from "./PublicTournamentBreadCrumb";
import WebPushUtil from "../../../../util/WebPushUtil";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import PublicTournamentPlatzierungTab from "./PublicTournamentPlatzierungTab";
import PublicLigaspieltagGamesTab from "./PublicLigaspieltagGamesTab";
import {TOURNAMENTSYSTEMS} from "../../../../constants/tournamentSystems";
import DHMobileDiv from "../../../general/DHMobileDiv";
import DHDesktopDiv from "../../../general/DHDesktopDiv";
import PublicTournamentLigaspieltagParticipantTab from "./PublicTournamentLigaspieltagParticipantTab";
import {addLastWatchedEvent, getFromLocalStorageWithDefault, getFromSessionStorageWithDefault, removeFromSessionStorage, saveToSessionStorage} from "../../../../util/localStorageZugriff";
import {isMobile} from 'react-device-detect';
import LastEventComp from "../../../LastEventComp";
import MobileMenuArea from "../../../general/MobileMenuArea";
import PublicLigaSpieltageTab from "./PublicLigaSpieltageTab";
import PublicLigaTabelleTab from "./PublicLigaTabelleTab";
import PublicAnyGamesTab from "./PublicAnyGamesTab";
import {Button} from "primereact/button";
import {ProgressSpinner} from "primereact/progressspinner";
import PublicLigaspielttagGastmannschaftInput from "../ligaspieltag/PublicLigaspielttagGastmannschaftInput";
import LigaspieltagEdartDetailStatisikArea from "../../tournament/LigaspieltagEdartDetailStatisikArea";

let stompClient = undefined;

function PublicTournament({tournamentId, userId, isLoggedIn}) {

    const VIEW_NAME = "PublicTournament";
    const TAB_SESSION_STORAGE_KEY = "dh_tab_" + VIEW_NAME;

    const [errorMessage, setErrorMessage] = useState(undefined);

    const [autorefresh, setAutorefresh] = useState(!isMobile);

    const gastInputLigaspieltag = getFromLocalStorageWithDefault("dh_ligaspieltag_gast_input", undefined)
    const [showGasteingabeDialog, setShowGasteingabeDialog] = useState(false);

    const [tabValue, setTabValue] = useState(getFromSessionStorageWithDefault(TAB_SESSION_STORAGE_KEY, ""));
    const [liveGames, setLiveGames] = useState([]);
    const [tournamentWrapper, setTournamentWrapper] = useState(undefined);
    const [supportsWebPush, setSupportsWebPush] = useState(undefined);
    const [pushSpielerIds, setPushSpielerIds] = useState([]);

    const menu = useRef(null);

    useEffect(() => {
        if( tournamentWrapper === undefined || tournamentWrapper.turnierMain === undefined) {
            return;
        }
        addLastWatchedEvent(tournamentWrapper.turnierMain.id, tournamentWrapper.turnierMain.bezeichnung);
    }, [tournamentWrapper]);

    useEffect(() => {
        if( autorefresh ) {
            connectStomp();
        } else {
            disconnectStomp();
        }
    }, [autorefresh]);

    useEffect(() => {
        return () => {
            removeFromSessionStorage(TAB_SESSION_STORAGE_KEY);
        };
    }, []);

    useEffect(() => {
        return () => {
            disconnectStomp();
        };
    }, []);

    useEffect(() => {
        saveToSessionStorage(TAB_SESSION_STORAGE_KEY, tabValue);
    }, [tabValue]);


    const connectStomp = () => {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        // stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                console.log("Websocket - connect success");
                const destination = "/topic/public/tournament/publicview/" + tournamentId;
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, (payload) => {
                    const body = JSON.parse(payload.body);
                    setTournamentWrapper(body);
                });

                const destinationLiveView = "/topic/public/tournament/game/infos/" + tournamentId;
                console.log("subscribe to " + destinationLiveView);
                stompClient.subscribe(destinationLiveView, (payload) => {
                    const body = JSON.parse(payload.body);
                    setLiveGames(body.gameShortInfos);
                });

            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    const disconnectStomp = () => {
        if( stompClient !== null && stompClient !== undefined) {
            console.log("Websocket - Chat - disconnect:");
            stompClient.disconnect(() => {});
        }
    }

    useEffect(() => {
        ladeDaten(tournamentId);
    }, []);

    const ladeDaten = async (id) => {
        setErrorMessage(undefined);
        if (tournamentId === null || tournamentId === undefined) {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.public + "/tournament/" + id;
        FetchUtil.fetchGet(url,
            json => {
                setTournamentWrapper(json);
                if( json.turnierMain.tournamentstatus === "neu" && tabValue === "" && json.turnierMain.turniersystem !== TOURNAMENTSYSTEMS.LIGASPIELTAG.id ) {
                    setTabValue("anmeldung");
                } else if( tabValue === "") {
                    setTabValue("teilnehmer");
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            });

        const supportsWebPush = await WebPushUtil.check();
        setSupportsWebPush(supportsWebPush);
        if (supportsWebPush) {
            updateSubscriptions();
        }
    }

    const updateSubscriptions = () => {
        WebPushUtil.subscriptionsLaden(tournamentId,
            ok => {
                setPushSpielerIds(ok.spielerIds)
            });
    }

    const isLigaspieltagUndGasteingabe = () => {
        if( tournamentWrapper &&
            tournamentWrapper.turnierMain &&
            tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id) {
            return gastInputLigaspieltag && gastInputLigaspieltag.tournamentid === tournamentWrapper.turnierMain.id;
        }
        return false;
    }

    const getMenuItems = () => {
        let returnValue = [];
        if( tournamentWrapper.turnierMain.tournamentstatus === "neu" && tournamentWrapper.turnierMain.turniersystem !== TOURNAMENTSYSTEMS.LIGASPIELTAG.id ) {
            returnValue.push({label: 'Anmeldung', icon: PrimeIcons.PLUS, command: () => setTabValue("anmeldung"), style:{borderLeft: tabValue === "anmeldung" ? "10px solid #ac1c27" : "none"}});
        }
        returnValue.push({label: 'Teilnehmer', icon: PrimeIcons.USER, command: () => setTabValue("teilnehmer"), style:{borderLeft: tabValue === "teilnehmer" ? "10px solid #ac1c27" : "none"}});
        if( tournamentWrapper.turnierMain.tournamentstatus !== "neu") {
            if (tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id) {
                returnValue.push({label: 'Games', icon: PrimeIcons.TH_LARGE, command: () => setTabValue("ligaspieltaggames"), style:{borderLeft: tabValue === "ligaspieltaggames" ? "10px solid #ac1c27" : "none"}});
            }
            if (tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.ANY_GAMES.id) {
                returnValue.push({label: 'Games', icon: PrimeIcons.TH_LARGE, command: () => setTabValue("anygames"), style:{borderLeft: tabValue === "anygames" ? "10px solid #ac1c27" : "none"}});
            }
            if (tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.LIGA.id) {
                returnValue.push({label: 'Spieltage', icon: PrimeIcons.TH_LARGE, command: () => setTabValue("ligaspieltage"), style:{borderLeft: tabValue === "ligaspieltaggames" ? "10px solid #ac1c27" : "none"}});
            }
            if (tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.LIGA.id) {
                returnValue.push({label: 'Tabelle', icon: PrimeIcons.TH_LARGE, command: () => setTabValue("ligatabelle"), style:{borderLeft: tabValue === "ligaspieltaggames" ? "10px solid #ac1c27" : "none"}});
            }
            if( tournamentWrapper.turnierGruppe !== null && tournamentWrapper.turnierGruppe !== undefined) {
                returnValue.push({label: isSchweizerSystem() ? "Runden" : 'Gruppen', icon: PrimeIcons.TH_LARGE, command: () => setTabValue("gruppen"), style:{borderLeft: tabValue === "gruppen" ? "10px solid #ac1c27" : "none"}});
            }
            if( tournamentWrapper.turnierKO !== null && tournamentWrapper.turnierKO !== undefined) {
                returnValue.push({label: 'KO/DKO', icon: PrimeIcons.SHARE_ALT, command: () => setTabValue("baum"), style:{borderLeft: tabValue === "baum" ? "10px solid #ac1c27" : "none"}});
            }
            if( tournamentWrapper.turnierLuckyLoser !== null && tournamentWrapper.turnierLuckyLoser !== undefined) {
                returnValue.push({label: 'Lucky L.', icon: PrimeIcons.SHARE_ALT, command: () => setTabValue("luckyloser"), style:{borderLeft: tabValue === "luckyloser" ? "10px solid #ac1c27" : "none"}});
            }
            if( tournamentWrapper.kratzerDto !== null && tournamentWrapper.kratzerDto !== undefined) {
                returnValue.push({label: 'Games', icon: PrimeIcons.SHARE_ALT, command: () => setTabValue("kratzergames"), style:{borderLeft: tabValue === "kratzergames" ? "10px solid #ac1c27" : "none"}});
            }
            if( tournamentWrapper.turnierMain.scoringWithDarthelfer) {
                returnValue.push({label: 'Highlights', icon: PrimeIcons.CHART_BAR, command: () => setTabValue("highlights"), style:{borderLeft: tabValue === "highlights" ? "10px solid #ac1c27" : "none"}});
                returnValue.push({label: 'Statistik', icon: PrimeIcons.CHART_LINE, command: () => setTabValue("statistik"), style:{borderLeft: tabValue === "statistik" ? "10px solid #ac1c27" : "none"}});
            }
            if( isEdartDetailStatistik() ) {
                returnValue.push({label: 'Statistik', icon: PrimeIcons.CHART_BAR, command: () => setTabValue("edartstatistik"), style:{borderLeft: tabValue === "edartstatistik" ? "10px solid #ac1c27" : "none"}});
            }

        }
        if( isShowPlatzierung()) {
            returnValue.push({label: 'Platzierung', icon: PrimeIcons.STAR_FILL, command: () => setTabValue("platzierung"), style:{borderLeft: tabValue === "platzierung" ? "10px solid #ac1c27" : "none"}});
        }
        if( tournamentWrapper.turnierMain.tournamentstatus === "abgeschlossen" && (tournamentWrapper.turnierMain.rangliste || (tournamentWrapper.turnierMain.ranglisteId !== null && tournamentWrapper.turnierMain.ranglisteId !== undefined))) {
            returnValue.push({label: 'Rangliste', icon: PrimeIcons.STAR, command: () => setTabValue("rangliste"), style:{borderLeft: tabValue === "rangliste" ? "10px solid #ac1c27" : "none"}});
        }
        if( tournamentWrapper.turnierMain.tournamentstatus === "gestartet" && tournamentWrapper.turnierMain.scoringWithDarthelfer) {
            returnValue.push({label: 'Live', icon: PrimeIcons.EYE, command: () => setTabValue("live"), style:{borderLeft: tabValue === "live" ? "10px solid #ac1c27" : "none"}});
        }

        returnValue.push({label: 'Details', icon: PrimeIcons.FOLDER_OPEN, command: () => setTabValue("details"), style:{borderLeft: tabValue === "details" ? "10px solid #ac1c27" : "none"}});
        return returnValue;
    }

    const erzeugeTab = (value, key, icon, width) => {
        return <div style={{color: "white", fontSize: 20, width: width, marginLeft:5, cursor:"pointer", borderBottom: tabValue === key ? "2px solid red" : "none", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>

            <i className={icon}
               style={{fontSize: 20, marginLeft: 5, marginRight: 5, color: "white", cursor: "pointer"}}/>
            {value.toUpperCase()}
        </div>
    }

    const isShowPlatzierung = () => {
        let isNotNew = tournamentWrapper.turnierMain.tournamentstatus !== "neu"
        let isGruppenturnier = tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id ||
            tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id;
        let isKOoderDKO = tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id ||
            tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.EINFACHERKO.id;
        let isSchweizerSystem = tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id;
        return isNotNew && (isGruppenturnier || isKOoderDKO || isSchweizerSystem);
    }

    const isSchweizerSystem = () => {
        return tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id;
    }

    const isEdartDetailStatistik = () => {
        return tournamentWrapper.turnierMain.edartDetailStatistik
    }

    const getTabs = () => {
        let returnValue = [];
        if( tournamentWrapper.turnierMain.tournamentstatus === "neu" && tournamentWrapper.turnierMain.turniersystem !== TOURNAMENTSYSTEMS.LIGASPIELTAG.id ) {
            returnValue.push(erzeugeTab("Anmeldung" ,"anmeldung", PrimeIcons.PLUS, 160));
        }
        returnValue.push(erzeugeTab("Teilnehmer", "teilnehmer", PrimeIcons.USER, 160));
        if( tournamentWrapper.turnierMain.tournamentstatus !== "neu") {
            if (tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id) {
                returnValue.push(erzeugeTab("Games", "ligaspieltaggames", PrimeIcons.TH_LARGE, 160));
            }
            if (tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.ANY_GAMES.id) {
                returnValue.push(erzeugeTab("Games", "anygames", PrimeIcons.TH_LARGE, 160));
            }
            if (tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.LIGA.id) {
                returnValue.push(erzeugeTab("Spieltage", "ligaspieltage", PrimeIcons.TH_LARGE, 160));
            }
            if (tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.LIGA.id) {
                returnValue.push(erzeugeTab("Tabelle", "ligatabelle", PrimeIcons.TH_LARGE, 160));
            }
            if( tournamentWrapper.turnierGruppe !== null && tournamentWrapper.turnierKO !== undefined) {
                returnValue.push(erzeugeTab(isSchweizerSystem() ? "Runden" : "Gruppen", "gruppen", PrimeIcons.TH_LARGE, 160));
            }
            if( tournamentWrapper.turnierKO !== null && tournamentWrapper.turnierKO !== undefined) {
                returnValue.push(erzeugeTab("KO/DKO","baum", PrimeIcons.SHARE_ALT, 160));
            }
            if( tournamentWrapper.turnierLuckyLoser !== null && tournamentWrapper.turnierLuckyLoser !== undefined) {
                returnValue.push(erzeugeTab("Lucky L.","luckyloser", PrimeIcons.SHARE_ALT, 160));
            }
            if( tournamentWrapper.kratzerDto !== null && tournamentWrapper.kratzerDto !== undefined) {
                returnValue.push(erzeugeTab("Games","kratzergames", PrimeIcons.TH_LARGE, 160));
            }
            if( tournamentWrapper.turnierMain.scoringWithDarthelfer) {
                returnValue.push(erzeugeTab("Highlights","highlights", PrimeIcons.CHART_BAR, 160));
                returnValue.push(erzeugeTab("Statistik","statistik", PrimeIcons.CHART_LINE, 160));
            }
            if( isEdartDetailStatistik() ) {
                returnValue.push(erzeugeTab("Statistik","edartstatistik", PrimeIcons.CHART_BAR, 160));
            }
        }
        if( isShowPlatzierung()) {
            returnValue.push(erzeugeTab("Platzierung","platzierung", PrimeIcons.STAR_FILL, 180));
        }
        if( tournamentWrapper.turnierMain.tournamentstatus === "abgeschlossen" && (tournamentWrapper.turnierMain.rangliste || (tournamentWrapper.turnierMain.ranglisteId !== null && tournamentWrapper.turnierMain.ranglisteId !== undefined))) {
            returnValue.push(erzeugeTab("Rangliste","rangliste", PrimeIcons.STAR, 160));
        }
        if( tournamentWrapper.turnierMain.tournamentstatus === "gestartet" && tournamentWrapper.turnierMain.scoringWithDarthelfer) {
            returnValue.push(erzeugeTab("Live","live", PrimeIcons.EYE, 160));
        }
        returnValue.push(erzeugeTab("Details","details", PrimeIcons.FOLDER_OPEN, 160));
        returnValue.push(<RefreshButtonComp text="Auto" autorefresh={autorefresh} onRefresh={() => ladeDaten(tournamentId)} shutdownminutes={600}
                onAutoRefresh={(schalter) => {
                    setAutorefresh(schalter);
        }}/>);
        return returnValue;
    }

    const isUserTeilnehmer = () => {
        if( userId === undefined || userId === null) {
            return false;
        }
        for (const player of tournamentWrapper.turnierMain.tournamentPlayerEntities) {
            if( player.userId === userId ) {
                return true;
            }
        }
        return false;
    }

    const getTabpanel = () => {
        if( tabValue === "anmeldung" ) {
            return <div className="darthelfer-background-blue">
                <PublicTournamentRegistrationTab userId={userId} eventOperatorId={tournamentWrapper.turnierMain.eventOperatorId} isLoggedIn={isLoggedIn} isUserTeilnehmer={isUserTeilnehmer()}
                                                 tournamentId={tournamentWrapper.turnierMain.id}
                                                 maxAnzahlTeilnehmer={tournamentWrapper.turnierMain.maxAnzahlTeilnehmer}
                                                 anzahlTeilnehmer={tournamentWrapper.turnierMain.tournamentPlayerEntities.length}
                                                 isDoppelturnier={tournamentWrapper.turnierMain.doppelturnier}
                                                 isDYP={tournamentWrapper.turnierMain.dyp}
                                                 registrationAllowed={tournamentWrapper.turnierMain.registrationAllowed} isParticipantsMailConfirmation={tournamentWrapper.turnierMain.participantsMailConfirmation} passwordRequired={tournamentWrapper.turnierMain.kennwort !== null && tournamentWrapper.turnierMain.kennwort !== undefined && tournamentWrapper.turnierMain.kennwort !== ""} onRefresh={() => ladeDaten(tournamentId)}/>
            </div>
        } else if( tabValue === "teilnehmer" ) {
            if( tournamentWrapper.turnierMain.turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id) {
                return <div className="darthelfer-background-blue">
                    <PublicTournamentLigaspieltagParticipantTab tournamentId={tournamentWrapper.turnierMain.id}
                                                    participants={tournamentWrapper.turnierMain.tournamentPlayerEntities}/>
                </div>
            }
            return <div className="darthelfer-background-blue">
                <PublicTournamentParticipantTab tournamentId={tournamentWrapper.turnierMain.id}
                                                maxAnzahlTeilnehmer={tournamentWrapper.turnierMain.maxAnzahlTeilnehmer}
                                                participants={tournamentWrapper.turnierMain.tournamentPlayerEntities}
                                                playersSortedByTime={tournamentWrapper.turnierMain.playersSortedByTime}
                                                isBezahlung={tournamentWrapper.turnierMain.bezahlung} isParticipantsMailConfirmation={tournamentWrapper.turnierMain.participantsMailConfirmation}
                                                supportsWebPush={supportsWebPush} pushSpielerIds={pushSpielerIds} updateSubscriptions={updateSubscriptions} />
            </div>
        }
        else if( tabValue === "ligaspieltaggames" ) {
            return <div className="darthelfer-background-blue">
                <PublicLigaspieltagGamesTab tournament={tournamentWrapper.turnierMain} boardGames={tournamentWrapper.boardGames}/>
            </div>
        }
        else if( tabValue === "anygames" ) {
            return <div className="darthelfer-background-blue">
                <PublicAnyGamesTab tournament={tournamentWrapper.turnierMain} games={tournamentWrapper.tournamentAnyGames} boardGames={tournamentWrapper.boardGames}/>
            </div>
        } else if( tabValue === "ligaspieltage" ) {
            return <div className="darthelfer-background-blue">
                <PublicLigaSpieltageTab tournament={tournamentWrapper.turnierMain} boardGames={tournamentWrapper.boardGames}/>
            </div>
        } else if( tabValue === "ligatabelle" ) {
            return <div className="darthelfer-background-blue">
                <PublicLigaTabelleTab tournament={tournamentWrapper.turnierMain} gruppenTabellen={tournamentWrapper.gruppenTabellen}/>
            </div>
        }
        else if( tabValue === "rangliste" ) {
            return <div className="darthelfer-background-blue">
                <PublicTournamentRanglisteTab participants={tournamentWrapper.turnierMain.tournamentPlayerEntities}
                                              eventOperatorId={tournamentWrapper.turnierMain.eventOperatorId}
                                              rankingId={tournamentWrapper.turnierMain.ranglisteId}/>
            </div>
        }
        else if( tabValue === "platzierung" ) {
            return <div className="darthelfer-background-blue">
                <PublicTournamentPlatzierungTab tournamentId={tournamentWrapper.turnierMain.id}/>
            </div>
        } else if( tabValue === "gruppen" ) {
            return <div className="darthelfer-background-blue">
                <PublicTournamentGroupsTab tournament={tournamentWrapper.turnierGruppe} gruppenTabellen={tournamentWrapper.gruppenTabellen}
                                           bestplatzierteDtos={tournamentWrapper.bestplatzierteDtos}
                                           boardGames={tournamentWrapper.boardGames}
                                           liveGames={liveGames}
                                           gruppeModelWrapperListe={tournamentWrapper.gruppeModelWrapperListe}
                />
            </div>
        } else if( tabValue === "baum" ) {
            return <div className="darthelfer-background-blue" style={{minHeight: "100vh", height: "100%"}}>
                <PublicTournamentTreeTab tournament={tournamentWrapper.turnierKO}
                                         model={tournamentWrapper.modelKO}
                                         boardGames={tournamentWrapper.boardGames}
                                         liveGames={liveGames}
                                         boardReihenfolge={tournamentWrapper.boardReihenfolgeKO}
                                         tournamentBeschreibungen={tournamentWrapper.tournamentBeschreibungenKO}/>
            </div>
        } else if( tabValue === "luckyloser" ) {
            return <div className="darthelfer-background-blue" style={{minHeight: "100vh", height: "100%"}}>
                <PublicTournamentTreeTab tournament={tournamentWrapper.turnierLuckyLoser} model={tournamentWrapper.modelLuckyLoser} boardGames={tournamentWrapper.boardGames} boardReihenfolge={tournamentWrapper.boardReihenfolgeLuckyLoser} tournamentBeschreibungen={tournamentWrapper.tournamentBeschreibungenLuckyLoser}/>
            </div>
        } else if( tabValue === "kratzergames" ) {
            return <div className="darthelfer-background-blue" style={{minHeight: "100vh", height: "100%"}}>
                <PublicTournamentKratzerGamesTab tournament={tournamentWrapper.turnierMain}
                                                 aktuelleRunde={tournamentWrapper.kratzerDto.aktuelleRunde} gewinnerPlayerId={tournamentWrapper.kratzerDto.gewinnerPlayerId}
                                                 tournamentPlayers={tournamentWrapper.turnierMain.tournamentPlayerEntities}
                                                 tournamentKratzerGames={tournamentWrapper.kratzerDto.tournamentKratzerGameEntities}
                                                 tournamentKratzerGamePlayers={tournamentWrapper.kratzerDto.tournamentKratzerGamePlayerEntities}
                                                 restpunkteDtos={tournamentWrapper.kratzerDto.restpunkteDtos} onCallbackReload={() => ladeDaten(tournamentId)}/>
            </div>
        } else if( tabValue === "highlights" ) {
            return <div className="darthelfer-background-blue">
                <PublicTournamentHighlightsTab tournament={tournamentWrapper}/>
            </div>
        } else if( tabValue === "statistik" ) {
            return <div className="darthelfer-background-blue">
                <PublicTournamentStatistikTab tournamentId={tournamentWrapper.turnierMain.id} participants={tournamentWrapper.turnierMain.tournamentPlayerEntities}
                                              gesamtStatistik={tournamentWrapper.ranglisteWrapper.statistikDto} playerStatistiks={tournamentWrapper.ranglisteWrapper.playerStatistiks}/>
            </div>
        } else if( tabValue === "live" ) {
            return <div className="darthelfer-background-blue">
                <PublicTournamentLiveTab tournamentId={tournamentWrapper.turnierMain.id} tournamentBezeichnung={tournamentWrapper.turnierMain.bezeichnung} eventOperatorId={tournamentWrapper.turnierMain.eventOperatorId}/>
            </div>
        } else if( tabValue === "details" ) {
            return <div className="darthelfer-background-blue">
                <PublicTournamentDetailsTab tournament={tournamentWrapper.turnierMain} />
            </div>
        } else if (tabValue === "edartstatistik") {
            return <div className="darthelfer-background-blue">
                <LigaspieltagEdartDetailStatisikArea tournamentId={tournamentWrapper.turnierMain.id} players={tournamentWrapper.turnierMain.tournamentPlayerEntities}
                                                     games={tournamentWrapper.turnierMain.tournamentLeagueGameEntities} publicView={true}/>
            </div>
        }
    }

    if( tournamentWrapper === undefined) {
        return <div>
            <ErrorInfoComponent infoMessage={undefined} errorMessage={errorMessage} onClearInfoMessage={() => ""}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <ProgressSpinner style={{width:100, height:100}}/>
        </div>
    }

    return <div style={{padding: 0, backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
        <DHMobileDiv comp={
            <>
                <PublicTournamentBreadCumb isMobil={true} isLiveView={false} tournamentBezeichnung={tournamentWrapper.turnierMain.bezeichnung}
                                           eventOperatorId={tournamentWrapper.turnierMain.eventOperatorId} eventOperatorBezeichnung={tournamentWrapper.eventOperatorBezeichnung}
                                           tournamentId={tournamentWrapper.turnierMain.id}/>
                <hr style={{marginTop: 0, marginBottom: 0}}/>
                <div style={{paddingLeft: 10, color: "black", fontSize: "2em", backgroundColor: "white"}}>
                    {tournamentWrapper.turnierMain.bezeichnung}
                </div>
                <div style={{display: "flex"}}>
                    <MobileMenuArea onClick={(e) => menu.current.toggle(e)}/>
                    <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENTS.route} style={{textDecoration: "none"}}>
                        <img alt="Logo" src={"/images/darthelfer_quer_weiss.png"} style={{height: 50, margin: 10}}/>
                    </NavLink>
                    <Menu model={getMenuItems()} popup ref={menu} style={{fontSize: 30, width: 300}}/>
                </div>
            </>
        }/>
        <DHDesktopDiv comp={
            <>
            <div style={{width: "100%"}}>
                    <PublicTournamentBreadCumb isMobil={false} isLiveView={false} tournamentBezeichnung={tournamentWrapper.turnierMain.bezeichnung}
                                               eventOperatorId={tournamentWrapper.turnierMain.eventOperatorId} eventOperatorBezeichnung={tournamentWrapper.eventOperatorBezeichnung}
                                               tournamentId={tournamentWrapper.turnierMain.id}/>
                </div>
                <hr style={{marginTop:0,marginBottom:0}}/>
                <div style={{paddingLeft: 20, backgroundColor: "white", color: "black", fontSize: "2em"}}>
                    {tournamentWrapper.turnierMain.bezeichnung}
                </div>
            </>
        }/>
        <LastEventComp currentId={tournamentWrapper.turnierMain.id} onLoad={(id) => ladeDaten(id)}/>
        { isLigaspieltagUndGasteingabe() && <div style={{padding:10}}><Button label="Gasteingabe" onClick={() => setShowGasteingabeDialog(true)}/> </div>}
        <DHDesktopDiv comp={
            <div style={{marginTop: 10}}>
                <div style={{display: "flex"}}>
                    {getTabs()}
                </div>
            </div>
        }/>
        {getTabpanel()}

        {showGasteingabeDialog && <PublicLigaspielttagGastmannschaftInput
            aktuellerBereich={tournamentWrapper.turnierMain.ligaspieltagBereich}
            onRefresh={() => ladeDaten(tournamentWrapper.turnierMain.id)}
            onClose={() => setShowGasteingabeDialog(false)}/> }
    </div>
}

PublicTournament.propTypes = {
    userId: PropTypes.string,
    isLoggedIn: PropTypes.bool.isRequired,
    tournamentId: PropTypes.string.isRequired,
};

export default PublicTournament;
