import React, {useState, useEffect} from 'react'

import ConfigUtil from "../../util/ConfigUtil";
import PropTypes from "prop-types";
import FetchUtil from "../../util/FetchUtil";
import {ProgressSpinner} from "primereact/progressspinner";
import {NAVIGATION_ITEM} from "../../constants/navigationItems";
import {Redirect} from "react-router-dom";

export default function NuConnectComponent({eventOperatorId, onError, onResponseNotOK}) {

    let [nuAuthLink, setNuAuthLink] = useState(undefined);

    useEffect(() => {
        return FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/config/" + eventOperatorId,
            {},
            result => {
                console.log("NU: Get config");
                console.log(result);
                let url = new URL(result.authUrl);
                url.searchParams.append("client_id", result.clientId);
                url.searchParams.append("redirect_uri", ConfigUtil.getBasicClientUrl() + "/#/extern/nucallback");
                url.searchParams.append("scope", "profile");
                url.searchParams.append("response_type", "code");
                setNuAuthLink(url.href);
            },
            responseNotOk => {
                onResponseNotOK(responseNotOk.message);
            },
            error => {
                onError(error.message);
            }
        );
    }, []);

    if( nuAuthLink ) {
        window.open(nuAuthLink, "_self");
    }
    return <div>
        { !nuAuthLink && <div><ProgressSpinner style={{width: 50, height: 50}} /> Lade Konfiguration ...</div>}
    </div>

}

NuConnectComponent.propTypes = {
    onResponseNotOK: PropTypes.func,
    onError: PropTypes.func
}
