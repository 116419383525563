import React, {useEffect, useRef, useState} from 'react'
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {Messages} from "primereact/messages";
import TurnierVerwaltenDialog from "../../tournament/TurnierVerwaltenDialog";
import PropTypes from "prop-types";
import LigaVerwaltenDialog from "../../tournament/LigaVerwaltenDialog";
import RundUmsBoardDialog from "../board/RundUmsBoardDialog";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import BoardsVerwaltenDialog from "../BoardsVerwaltenDialog";
import {OverlayPanel} from "primereact/overlaypanel";
import VeranstalterTipps from "../VeranstalterTipps";
import EventOperatorSettingsDialog from "../EventOperatorSettingsDialog";
import RanglistenDialog from "../../rangliste/RanglistenDialog";
import RanglistenProfileDialog from "../../rangliste/RanglisteProfileDialog";
import QRCodeSidebar from "../../../general/QRCodeSidebar";
import LigaspieltagVerwaltenDialog from "../../tournament/LigaspieltagVerwaltenDialog";
import {getFromLocalStorageWithDefault, saveToLocalStorage} from "../../../../util/localStorageZugriff";
import NuSyncComponent from "../../../nu/NuSyncComponent";
import {PrimeIcons} from "primereact/api";
import DHSelectionComponent from "../../../general/DHSeclectionCompontent";
import VeranstalterUebersichtDialog from "../VeranstalterUebersichtDialog";
import VeranstalterBezahlungDialog from "../VeranstalterBezahlungDialog";
import VeranstalterAdminsDialog from "../VeranstalterAdminsDialog";
import LigaVerwaltungDialog from "../../ligaverwaltung/LigaVerwaltungDialog";

function EventOperatorSelection({selectedEventOperator, onEventOperatorChanged, onRefresh, onDashboardChange}) {

    const hilfeOP = useRef(null);
    const messages = useRef(null);
    const newEventAreaOP = useRef(null);

    let [showLigaVerwaltung, setShowLigaVerwaltung] = useState(false);
    let [showNewLigaDialog, setShowNewLigaDialog] = useState(false);
    let [showNewLigaspieltagDialog, setShowNewLigaspieltagDialog] = useState(false);
    let [showNewTournamentDialog, setShowNewTournamentDialog] = useState(false);
    let [showScorer, setShowScorer] = useState(false);
    let [showOeffentlicheTurniere, setShowOeffentlicheTurniere] = useState(false);
    let [showOeffentlicherBereich, setShowOeffentlicherBereich] = useState(false);
    let [showRundUmsBoardDialog, setShowRundUmsBoardDialog] = useState(false);
    let [showBoardsVerwaltenDialog, setShowBoardsVerwaltenDialog] = useState(false);
    let [showEventOperatorSettingsDialog, setShowEventOperatorSettingsDialog] = useState(false);
    let [showRanglisteNewDialog, setShowRanglisteNewDialog] = useState(false);
    let [showRanglistenProfileDialog, setShowRanglistenProfileDialog] = useState(false);
    let [showQRCodeBereich, setShowQRCodeBereich] = useState(false);
    let [showBDVDialog, setShowBDVDialog] = useState(false);
    let [showVeranstalterVerwaltenDialog, setShowVeranstalterVerwaltenDialog] = useState(false);
    let [showVeranstalterBezahlungDialog, setShowVeranstalterBezahlungDialog] = useState(false);
    let [showVeranstalterAdminsDialog, setShowVeranstalterAdminsDialog] = useState(false);

    let [veranstalterListe, setVeranstalterListe] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, []);

    useEffect(() => {
        if( veranstalterListe === undefined || veranstalterListe.length === 0) {
            return;
        }
        let eventOperatorId = getFromLocalStorageWithDefault("dh_eventoperator_dashboard_selected", undefined);
        if( selectedEventOperator === undefined) {
            // prüfen ob im Storage ein eventoperator selektiert ist.
            if( eventOperatorId !== undefined) {
                let tmpArray = veranstalterListe.filter(tmp => tmp.id === eventOperatorId);
                if (tmpArray.length === 1) {
                    onEventOperatorChanged(tmpArray[0]);
                } else {
                    onEventOperatorChanged(undefined);
                }
            } else if( veranstalterListe.length === 1) {
                onEventOperatorChanged(veranstalterListe[0]);
            } else {
                onEventOperatorChanged(undefined);
            }
        } else if( veranstalterListe.length === 1) {
            onEventOperatorChanged(veranstalterListe[0]);
        } else {
            let tmpArray = veranstalterListe.filter(tmp => tmp.id === selectedEventOperator.id);
            if( tmpArray.length === 0) {
                onEventOperatorChanged(undefined);
            }
        }
    }, [veranstalterListe, selectedEventOperator]);

    const showError = (message) => {
        messages.current.show({severity: 'error', sticky: true, summary: 'Fehler...', detail: message});
    }

    const saveEventOperatorToLocalStorage = (eventOperator) => {
        if( eventOperator !== null && eventOperator !== undefined) {
            saveToLocalStorage("dh_eventoperator_dashboard_selected", eventOperator.id);
        }
    }

    const ladeDaten = (idToRefresh) => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/byCurrentUser",
            json => {
                setVeranstalterListe(json);
                if (json.length === 1) {
                    onEventOperatorChanged(json[0]);
                }

                if( idToRefresh ) {
                    let e = json.filter(f => f.id === idToRefresh)[0];
                    onEventOperatorChanged(e);
                }
            },
            responseNotOk => {
                showError("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                showError("Fehler beim Laden: " + error.message);
            });
    }

    const getEventoperatorPublicAreaLink = () => {
        return ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_EVENTOPERATOR.route + "/" + selectedEventOperator.id;
    }

    if (showScorer) {
        setShowScorer(false);
        window.open("https://scorer.darthelfer.de", '_blank');
    }

    if (showOeffentlicherBereich) {
        setShowOeffentlicherBereich(false);
        window.open(getEventoperatorPublicAreaLink(), '_blank');
    }

    if (showOeffentlicheTurniere) {
        setShowOeffentlicheTurniere(false);
        let url = ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_TOURNAMENTS.route;
        window.open(url, '_blank');
    }

    const getNewEventArea = () => {
        return <OverlayPanel ref={newEventAreaOP}
                                 showCloseIcon={false} dismissable={true}
                                 style={{height: 150, width: 250, fontSize:16}}>
            <div>
                <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}} onClick={() => setShowNewTournamentDialog(true)}>
                    <img src="/images/tournament.png" alt="Turnier anlegen" style={{height: 20, width: 20, marginRight: 10}}/>
                    Turnier anlegen
                </div>
                <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}} onClick={() => setShowNewLigaDialog(true)}>
                    <img src="/images/tournament.png" alt="Liga anlegen" style={{height: 20, width: 20, marginRight: 10}}/>
                    Liga anlegen
                </div>
                <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}} onClick={() => setShowNewLigaspieltagDialog(true)}>
                    <img src="/images/tournament.png" alt="Ligaspieltag anlegen" style={{height: 20, width: 20, marginRight: 10}}/>
                    Ligaspieltag anlegen
                </div>
            </div>
        </OverlayPanel>
    }

    const getEventOperators = () => {
        let tmp = [];
        tmp.push({name: 'Auswahl löschen', code: undefined});
        for (const veranstalter of veranstalterListe) {
            tmp.push({name: veranstalter.name, code: veranstalter.id});
        }
        return tmp;
    }

    return <div className="col-12 md:col-12 lg:col-12" style={{padding: 10}}>
        <div className="ligashortoverview"
             style={{height: "100%", borderLeft: "10px solid black", alignContent: "center"}}>
            <Messages ref={messages}></Messages>
            {selectedEventOperator && selectedEventOperator.proAccountLaeuftAus && <div style={{marginBottom: 20}}>
                <div className="grid" style={{padding: 0}}>
                    <div className="col-12 md:col-12 lg:col-12" style={{verticalAlign: "top", padding: 10, display: "flex"}}>
                        <div style={{marginRight: 10, color: "red"}}>
                            <i className={PrimeIcons.INFO_CIRCLE} style={{fontSize: 30}}/>
                        </div>
                        <div>
                            Dein Pro-Account ist abgelaufen oder läuft in den nächsten Tagen ab.<br/>
                            Endezeitpunkt {selectedEventOperator.proAccountEndeDatum}<br/><br/>
                            Sofern du weiterhin Interesse an einem Pro-Account hast, kannst du diesen online per PayPal kaufen oder nimm per Mail ( support(a)darthelfer.de ) Kontakt zu uns auf.
                        </div>
                    </div>
                </div>
                <hr style={{color: "red", border:"5px solid red"}}/>
            </div>
            }

            <div className="grid" style={{padding: 0}}>
                <div className="col-12 md:col-12 lg:col-3" style={{verticalAlign: "top", padding: 10}}>
                    <div style={{marginBottom: 10}}>
                        <div style={{marginBottom: 5}}>Veranstalterauswahl:</div>
                        <div>
                            <DHSelectionComponent value={selectedEventOperator ? selectedEventOperator.id : undefined} onChange={(id) => {
                                let veranstalter = veranstalterListe.filter(v => v.id === id)[0];
                                saveEventOperatorToLocalStorage(veranstalter);
                                onEventOperatorChanged(veranstalter);
                            }} values={getEventOperators()} title="Auswahl"/>
                        </div>
                    </div>
                    {selectedEventOperator &&
                        <div style={{fontSize: 14, marginBottom: 10}}>Id: {selectedEventOperator.idShort}</div>
                    }
                    {selectedEventOperator && selectedEventOperator.proVeranstalter &&
                        selectedEventOperator.proAccountEndeDatum &&
                        <div style={{fontSize: 10, marginBottom: 10}}>Dein Pro-Account endet am {selectedEventOperator.proAccountEndeDatum}.</div>
                    }
                    {selectedEventOperator && !selectedEventOperator.proVeranstalter &&
                        <div style={{fontSize: 10, marginBottom: 10, color: "red"}}>Dein Veranstalter besitzt aktuell keinen Pro-Account.</div>
                    }
                    <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10}} onClick={(e) => hilfeOP.current.toggle(e)}>
                        <img src="/images/question.png" alt="Frage" style={{height: 20, width: 20, marginRight: 10, cursor: "pointer"}}/>
                        Hilfe / Support
                        <OverlayPanel ref={hilfeOP}
                                      showCloseIcon={false} dismissable={true}>
                            <VeranstalterTipps/>
                        </OverlayPanel>
                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-3" style={{padding: 10}}>
                    <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}}
                         onClick={() => setShowVeranstalterVerwaltenDialog(true)}>
                        <img src="/images/eventoperator.png" alt="Veranstalter" style={{height: 20, width: 20, marginRight: 10}}/>
                        Veranstalter hinzufügen/verwalten
                    </div>
                    {selectedEventOperator !== undefined && <>
                    <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}}
                             onClick={() => setShowVeranstalterBezahlungDialog(true)}>
                            <img src="/images/paypal.png" alt="Bezahlung" style={{height: 20, width: 20, marginRight: 10}}/>
                            Bezahlung
                        </div>
                        <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}}
                             onClick={() => setShowVeranstalterAdminsDialog(true)}>
                            <img src="/images/kachel/gear_blue.png" alt="Admins" style={{height: 20, width: 20, marginRight: 10}}/>
                            Admins
                        </div>
                        <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}}
                             onClick={() => setShowBoardsVerwaltenDialog(true)}>
                            <img src="/images/automat.png" alt="Boards" style={{height: 20, width: 20, marginRight: 10}}/>
                            Boards/Automaten verwalten
                        </div>
                        <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}}
                             onClick={() => setShowRundUmsBoardDialog(true)}>
                            <img src="/images/dart_select.png" alt="Rund ums Board" style={{height: 20, width: 20, marginRight: 10}}/>
                            Rund ums Board
                        </div>
                    </>}
                </div>
                <div className="col-12 md:col-12 lg:col-3" style={{padding: 10}}>
                    <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}} onClick={() => setShowScorer(true)}>
                        <img src="/images/scorer.png" alt="Scorer" style={{height: 20, width: 20, marginRight: 10}}/>
                        darthelfer-scorer
                    </div>
                    <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}}
                         onClick={() => setShowOeffentlicheTurniere(true)}>
                        <img src="/images/tournamentlive.png" alt="Turniere und Livescores" style={{height: 20, width: 20, marginRight: 10}}/>
                        Events / Livescores
                    </div>
                    {selectedEventOperator !== undefined && <>
                        <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}}
                             onClick={() => setShowOeffentlicherBereich(true)}>
                            <img src="/images/publicarea.png" alt="Oeffentlicher Bereich" style={{height: 20, width: 20, marginRight: 10}}/>
                            Dein öffentlicher Bereich
                        </div>
                        <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}} onClick={() => setShowQRCodeBereich(true)}>
                            <img src="/images/qrcode.png" alt="QR Oeffentlicher Bereich" style={{height: 20, width: 20, marginRight: 10}}/>
                            QR Code zum öffentlichen Bereich
                        </div>
                        <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}}>
                            <NavLink to={NAVIGATION_ITEM.VERANSTALTER_LIVE_VIEW.route} style={{textDecoration: "none"}}><>
                                <img src="/images/tv_black.png" alt="Live-View" style={{height: 20, marginRight: 10}}/>
                                Live-View
                            </>
                            </NavLink>
                        </div>
                    </>
                    }
                </div>
                <div className="col-12 md:col-12 lg:col-3" style={{padding: 10}}>
                    {getNewEventArea()}
                    {selectedEventOperator !== undefined &&
                        <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}}>
                            <img src="/images/event.png" alt="Neues Event anlegen" style={{height: 20, width: 20, marginRight: 10}} onClick={(e) => newEventAreaOP.current.toggle(e)}/>
                            <span onClick={(e) => newEventAreaOP.current.toggle(e)}>Neues Event anlegen</span>
                        </div>
                    }
                    {selectedEventOperator !== undefined && selectedEventOperator.ligabetreiber &&
                        <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}}
                             onClick={() => setShowLigaVerwaltung(true)}>
                            <img src="/images/league_0.png" alt="Ligaverwaltung" style={{height: 20, width: 20, marginRight: 10}}/>
                            Ligaverwaltung
                        </div>
                    }
                    {selectedEventOperator !== undefined &&
                        <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}}
                             onClick={(e) => setShowRanglisteNewDialog(true)}>
                            <img src="/images/rangliste.png" alt="Ranglisten" style={{height: 20, width: 20, marginRight: 10}}/>
                            Ranglisten
                        </div>
                    }
                    {selectedEventOperator !== undefined &&
                        <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}}
                             onClick={(e) => setShowRanglistenProfileDialog(true)}>
                            <img src="/images/ranglistenprofil.png" alt="Ranglistenprofile" style={{height: 20, width: 20, marginRight: 10}}/>
                            Ranglistenprofile
                        </div>
                    }
                    {selectedEventOperator !== undefined && selectedEventOperator.nuClubUUID !== null && selectedEventOperator.nuClubUUID !== undefined &&
                        <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}} onClick={() => setShowBDVDialog(true)}>
                            <img src="/images/bdv.png" alt="BDV" style={{height: 20, marginRight: 10}}/>
                            BDV/nu Aktionen
                        </div>
                    }
                    {selectedEventOperator !== undefined &&
                        <div style={{display: "flex", verticalAlign: "middle", margin: "auto", alignItems: "center", marginBottom: 10, cursor: "pointer"}}
                             onClick={() => setShowEventOperatorSettingsDialog(true)}>
                            <img src="/images/settings.png" alt="Einstellungen" style={{height: 20, width: 20, marginRight: 10}}/>
                            Einstellungen
                        </div>
                    }
                </div>
            </div>
            {showNewLigaDialog && <LigaVerwaltenDialog ligaId={undefined}
                                                       propEventOperatorId={selectedEventOperator.id}
                                                       callBackRefresh={onRefresh}
                                                       callBackOnClose={() => setShowNewLigaDialog(false)}/>
            }
            {showNewTournamentDialog && <TurnierVerwaltenDialog tournamentId={undefined}
                                                                propEventOperatorId={selectedEventOperator.id}
                                                                callBackRefresh={onRefresh}
                                                                callBackOnDelete={() => {
                                                                    onRefresh();
                                                                    setShowNewTournamentDialog(false);
                                                                }}
                                                                callBackOnClose={() => setShowNewTournamentDialog(false)}/>
            }
            {showNewLigaspieltagDialog && <LigaspieltagVerwaltenDialog tournamentId={undefined}
                                                                propEventOperatorId={selectedEventOperator.id}
                                                                callBackRefresh={onRefresh}
                                                                callBackOnDelete={() => {
                                                                    onRefresh();
                                                                    setShowNewLigaspieltagDialog(false);
                                                                }}
                                                                callBackOnClose={() => setShowNewLigaspieltagDialog(false)}/>
            }
            {showRundUmsBoardDialog && <RundUmsBoardDialog eventOperatorId={selectedEventOperator.id} onClose={() => setShowRundUmsBoardDialog(false)}/>}
            {showBoardsVerwaltenDialog && <BoardsVerwaltenDialog eventOperatorId={selectedEventOperator.id} onClose={() => setShowBoardsVerwaltenDialog(false)}/>}
            {showEventOperatorSettingsDialog && <EventOperatorSettingsDialog eventOperatorId={selectedEventOperator.id} onClose={() => setShowEventOperatorSettingsDialog(false)}/>}
            {showRanglisteNewDialog && <RanglistenDialog eventOperatorId={selectedEventOperator.id} onClose={() => setShowRanglisteNewDialog(false)}/>}
            {showRanglistenProfileDialog && <RanglistenProfileDialog eventOperatorId={selectedEventOperator.id} onClose={() => setShowRanglistenProfileDialog(false)}/>}
            {showQRCodeBereich && <QRCodeSidebar kritisch={false} beschreibung="QR Code zum öffentlichen Bereich deines Veranstalters. Rechtsklick auf den QR Code und Bild/Grafik speichern." link={getEventoperatorPublicAreaLink()} onClose={() => setShowQRCodeBereich(false)}/>}
            {showBDVDialog && <NuSyncComponent eventOperatorId={selectedEventOperator.id} onRefresh={onRefresh} onClose={() => setShowBDVDialog(false)} />}
            {showVeranstalterVerwaltenDialog && <VeranstalterUebersichtDialog onRefresh={() => ladeDaten()} onClose={() => setShowVeranstalterVerwaltenDialog(false)}/>}
            {showVeranstalterBezahlungDialog && <VeranstalterBezahlungDialog eventOperatorId={selectedEventOperator.id}
                                                                             onRefresh={() => ladeDaten(selectedEventOperator.id)} onClose={() => setShowVeranstalterBezahlungDialog(false)}/>}
            {showVeranstalterAdminsDialog && <VeranstalterAdminsDialog eventOperatorId={selectedEventOperator.id} onRefresh={() => ""} onClose={() => setShowVeranstalterAdminsDialog(false)}/>}
            {showLigaVerwaltung && <LigaVerwaltungDialog eventOperatorId={selectedEventOperator.id} onClose={() => setShowLigaVerwaltung(false)}/>}
        </div>
    </div>
}

EventOperatorSelection.propTypes = {
    selectedEventOperator: PropTypes.object,
    onEventOperatorChanged: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired,
};

export default EventOperatorSelection;
