import React from "react";
import {Button} from "primereact/button";
import Iframe from "react-iframe";
import PropTypes from "prop-types";

class GameVideo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {height:100, width:100};
    }

    webcamSettingsArea() {
        return <div className="grid align-content-end vertical-container" style={{position:"absolute", top:0, left:10, height:200, width:20, zIndex:1, margin:0, padding:0}}>
            <div className="col" style={{padding:0, margin:0, textAlign:"left"}}>
                <Button icon="pi pi-plus" style={{height: 35, width: 35, margin: 0, padding: 0, marginTop:10}} onClick={() => this.setState({height:this.state.height + 5})} tooltip=""/>
                <Button icon="pi pi-minus" style={{height:35, width:35, margin:0, padding:0, marginTop:1}} onClick={() => this.setState({height:this.state.height - 5})} tooltip=""/>
                <Button icon="pi pi-plus" style={{height: 35, width: 35, margin: 0, padding: 0, marginTop:10}} onClick={() => this.setState({width:this.state.width + 5})} tooltip=""/>
                <Button icon="pi pi-minus" style={{height:35, width:35, margin:0, padding:0, marginTop:1}} onClick={() => this.setState({width:this.state.width - 5})} tooltip=""/>
            </div>
        </div>

    }

    render() {
        const video = <div style={{width: this.state.width + "%", height:this.state.height +"%", padding:0}}>
            <Iframe url={"https://meet.ffmuc.net/dh_" + this.props.gameid + "#userInfo.displayName=\"" + this.props.username + "\""}
                                  width="100%"
                                  height="100%"
                                  allow="camera; microphone;"
                                  id={"frame_" + this.props.gameid}
                                  display="initial"
                                  position="relative"/>
        </div>

        return (<div style={{height:"100%", width:"100%"}}>
                {this.props.testhinweis && <div style={{fontSize:25, fontWeight:"bold", width:"100%", backgroundColor:"white"}}>Hinweis: dein Bild kann gedreht anzeigt werden, dein Gegner wird das Bild richtig herum sehen!!!</div>}
                {this.webcamSettingsArea()}
                {video}
            </div>

        );
    }
}

GameVideo.propTypes = {
    username: PropTypes.string.isRequired,
    gameid: PropTypes.string.isRequired,
    testhinweis: PropTypes.bool,
}

export default GameVideo;
