import React, {useState} from 'react'
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";
import DHDesktopDiv from "../../general/DHDesktopDiv";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import LigaSpielorteComp from "./LigaSpielorteComp";
import LigaSpielerComp from "./LigaSpielerComp";
import LigaMannschaftComp from "./LigaMannschaftComp";
import LigaVereinComp from "./LigaVereinComp";

export default function LigaVerwaltungDialog({eventOperatorId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [tabValue, setTabValue] = useState(undefined);

    const erzeugeTab = (value, key, icon, width) => {
        return <div style={{fontSize: 20, width: width, marginLeft: 5, cursor: "pointer", borderBottom: tabValue === key ? "2px solid red" : "none", textAlign: "center"}}
                    onClick={() => setTabValue(key)}>
            {icon && <span style={{marginRight:5}}>{icon}</span>}
            <span style={{marginBottom:15}}>{value.toUpperCase()}</span>
        </div>
    }

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Ligen", "leagues", <SmartToyIcon/>, 180));
        returnValue.push(erzeugeTab("Vereine", "clubs", <AccountBalanceIcon/>, 180));
        returnValue.push(erzeugeTab("Mannschaften", "teams", <GroupsIcon/>, 220));
        returnValue.push(erzeugeTab("Spieler", "players", <PersonIcon/>, 180));
        returnValue.push(erzeugeTab("Spielorte", "spielorte", <LocationCityIcon/>, 220));
        return returnValue;
    }

    const getTabpanel = () => {
        if (tabValue === "teams") {
            return <LigaMannschaftComp eventOperatorId={eventOperatorId}/>
        } else if (tabValue === "clubs") {
            return <LigaVereinComp eventOperatorId={eventOperatorId}/>
        } else if (tabValue === "players") {
            return <LigaSpielerComp eventOperatorId={eventOperatorId}/>
        } else if (tabValue === "spielorte") {
            return <LigaSpielorteComp eventOperatorId={eventOperatorId}/>
        }
    }

    const getDataArea = () => {
        return <div style={{padding:20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <DHDesktopDiv comp={
                <div style={{marginTop: 10}}>
                    <div style={{display: "flex"}}>
                        {getTabs()}
                    </div>
                </div>
            }/>
            {getTabpanel()}
        </div>
    }

    return <DHDialog onClose={() => onClose()} title="Ligaverwaltung" show={true} showCloseButton={true} comp={getDataArea()}/>
}
