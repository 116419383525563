import React from "react";
import {CarouselProvider, Slide, Slider} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import LoginContainer from "../../containers/LoginContainer";

class HeaderBig extends React.Component {

    render() {
        return <div className="grid p-dir-row" style={{
            paddingTop: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            marginRight: 0,
            marginLeft: 0,
            backgroundColor: "#f2f2f2",
            color: "black",
            borderBottom: "1px solid #888888",
            width: "100%",
        }}>
            <div className="col-12 sm:col-2 md:col-3 lg:col-1" style={{textAlign: "center"}}>
                <img alt="Logo" src={"/images/darthelfer.svg"} style={{width: "100%", maxWidth: "100px"}}/>
            </div>
            <div className="col-12 sm:col-10 md-9 lg:col-8 maintitle">
                    <h1>Deine persönliche Dartplattform!</h1>
                    <CarouselProvider
                        naturalSlideWidth={100}
                        naturalSlideHeight={5}
                        totalSlides={4}
                        isPlaying={true}
                        dragEnabled={false}
                        touchEnabled={false}
                        interval={10000}
                        className="slogan"
                        disableAnimation={true}
                        infinite={true}
                    >
                        <Slider>
                            <Slide index={0}>... <strong>never</strong> stop the training</Slide>
                            <Slide index={1}>... deinen <strong>Average</strong> immer im <strong>Blick</strong></Slide>
                            <Slide index={2}>... <strong>Trainingseinheiten</strong> verwalten</Slide>
                            <Slide index={3}>... dein <strong>Team</strong> beobachten</Slide>
                        </Slider>
                    </CarouselProvider>
            </div>
            <div className="col-fixed" style={{width:310, minWidth:310}}>
                <LoginContainer/>
            </div>
        </div>
    }
}

export default HeaderBig;
