import React, {useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import FetchUtil from "../util/FetchUtil";
import ConfigUtil from "../util/ConfigUtil";
import ErrorInfoComponent from "./general/ErrorInfoComponent";
import DHDefaultDrawer from "./general/DHDefaultDrawer";
import DHButtonClose from "./general/DHButtonClose";

export default function KennwortVergessenDialog({onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [phase, setPhase] = useState(1);
    const [username, setUsername] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [code, setCode] = useState("");

    const handelKeyDown = (e) => {
        if (e.keyCode === 13) {
            // enter
            if (phase === 1) {
                postPhase1();
            } else if (phase === 2) {
                postPhase2();
            }
            if (phase === 3) {
                postPhase3();
            }

        }
    }

    const postPhase3 = () => {
        setErrorMessage(undefined);
        let date = {password1: password1, password2: password2};
        let url = ConfigUtil.getConfig().resourceUrls.user + "/kennwortvergessenchangepassword?code=" + code;
        FetchUtil.fetchPost(url,
            date,
            json => {
                setPhase(4);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const postPhase2 = () => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.user + "/kennwortvergessencodeueberpruefen?code=" + code;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setPhase(3);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const postPhase1 = () => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.user + "/kennwortvergessencodeanfordern?param=" + username;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setPhase(2);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const getPhase2Area = () => {
        if (phase !== 2) {
            return "";
        }
        return <div>
            <h1>Überprüfung deiner Identität</h1>
            Aus Sicherheitsgründen müssen wir deine Identität überprüfen. Sofern wir zu deinen Daten ein Konto gefunden haben, haben wir dir einen Code an deine Emailadresse geschickt. Gib diesen
            bitte unten ein.
            <br/><br/>Code eingeben
            <div className="col-12 md:col-12 lg:col-8">
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-12" style={{marginTop: 10}}>
                        <InputText value={code} style={{width: "100%"}}
                                   placeholder="Code"
                                   onChange={(e) => setCode(e.target.value)}
                                   onKeyDown={(e) => handelKeyDown(e)}/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-12" style={{marginTop: 5}}>
                        <Button label="Weiter"
                                icon="pi pi-key"
                                disabled={!code}
                                type="button" onClick={() => postPhase2()} style={{marginRight: 5}}/>
                        <DHButtonClose onClick={() => onClose()}/>
                    </div>
                </div>
            </div>
            Sofern du keine Mail erhalten hast (prüfe bitte auch den SPAM Ordner), nimm bitte Kontakt mit unserem Support auf.
        </div>
    }

    const getPhase4Area = () => {
        if (phase !== 4) {
            return "";
        }
        return <div>
            <h1>Super...</h1>
            Dein Kennwort wurde geändert, du kannst dich nun mit deinem Benutzernamen und deinem Kennwort anmelden.
            <br/>
            <br/>
            Sofern du weiter Probleme hast, nimm Kontakt mit unserem Support auf.
            <div style={{marginTop:10}}>
                <DHButtonClose onClick={() => onClose()}/>
            </div>
        </div>
    }

    const getPhase3Area = () => {
        if (phase !== 3) {
            return "";
        }
        return <div>
            <h1>Neues Passwort erstellen</h1>
            Gib hier deine E-Mail-Adresse oder deinen Benutzernamen ein, die/das mit deinem darthelfer-Konto verbunden
            ist.
            <div className="col-12 md:col-12 lg:col-8">
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-12" style={{marginTop: 10}}>
                        <Password value={password1} toggleMask={true}
                                  placeholder="Kennwort"
                                  onChange={(e) => setPassword1(e.target.value)}
                                  onKeyDown={(e) => handelKeyDown(e)}
                                  feedback={true}/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-12" style={{marginTop: 10}}>
                        <Password value={password2} toggleMask={true}
                                  placeholder="Kennwort wiederholen"
                                  onChange={(e) => setPassword2(e.target.value)}
                                  onKeyDown={(e) => handelKeyDown(e)}
                                  feedback={false}/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-12" style={{marginTop: 5}}>
                        <Button label="Weiter"
                                icon="pi pi-key"
                                disabled={!password1 && !password2}
                                type="button" onClick={() => postPhase3()} style={{marginRight: 5}}/>
                        <DHButtonClose onClick={() => onClose()}/>
                    </div>
                </div>
            </div>
        </div>
    }

    const getPhase1Area = () => {
        if (phase !== 1) {
            return "";
        }
        return <div>
            <h1>Passworthilfe</h1>
            Gib hier deine E-Mail-Adresse oder deinen Benutzernamen ein, die/das mit deinem darthelfer-Konto verbunden
            ist.
            <div className="col-12 md:col-12 lg:col-8">
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-12" style={{marginTop: 10}}>
                        <InputText value={username} style={{width: "100%"}}
                                   placeholder="Benutzername oder Emailadresse"
                                   onChange={(e) => setUsername(e.target.value)}
                                   onKeyDown={(e) => handelKeyDown(e)}/>
                    </div>
                    <div className="col-12 md:col-6 lg:col-12" style={{marginTop: 5}}>
                        <Button label="Weiter"
                                icon="pi pi-key"
                                disabled={!username}
                                type="button" onClick={() => postPhase1()} style={{marginRight: 5}}/>
                        <DHButtonClose onClick={() => onClose()}/>
                    </div>
                </div>
            </div>
        </div>
    }

    return <DHDefaultDrawer title="Kennwort vergessen" onClose={() => onClose()} show={true} children={<div style={{padding: 20}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div className="col-12 md:col-12 lg:col-12">
            {getPhase1Area()}
            {getPhase2Area()}
            {getPhase3Area()}
            {getPhase4Area()}
        </div>
    </div>}/>
}