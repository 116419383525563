import React, {useState} from 'react'
import {Button} from "primereact/button";
import TournamentPrioArea from "./TournamentPrioArea";
import BoardBelegungArea from "./BoardBelegungArea";
import BoardAufrufArea from "./BoardAufrufArea";
import BoardVergabeArea from "./BoardVergabeArea";
import TeilnehmerPrioArea from "./TeilnehmerPrioArea";
import TurnierSchreiberArea from "../../liga/TurnierSchreiberArea";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import DHMobileDiv from "../../../general/DHMobileDiv";
import DHDesktopDiv from "../../../general/DHDesktopDiv";
import BoardTeilenArea from "./BoardTeilenArea";
import DHDialog from "../../../general/DHDialog";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MobileMenuArea from "../../../general/MobileMenuArea";

export default function RundUmsBoardDialog({eventOperatorId, onClose}) {
    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [refreshCounter, setRefreshCounter] = useState(0);

    const [menu, setMenu] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const [tabValue, setTabValue] = useState("boardbelegung");

    const boardvergabeAnstossen = () => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/boardvergabeanstossen/" + eventOperatorId,
            {},
            json => {
                setInfoMessage("Boardvergabe wurde angestoßen!");
                setRefreshCounter(refreshCounter+1);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Anstossen der Boardvergabe: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Anstossen der Boardvergabe: " + error.message);
            })
    }


    const erzeugeTab = (value, key, width) => {
        return <div style={{fontSize: 16, width:width, marginLeft:5, cursor:"pointer", borderBottom: tabValue === key ? "2px solid red" : "none", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>
            {value.toUpperCase()}
        </div>
    }

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Boardbelegung" ,"boardbelegung", 180));
        returnValue.push(erzeugeTab("Boardvergabe" ,"boardvergabe", 180));
        returnValue.push(erzeugeTab("Boardaufruf" ,"boardauruf", 180));
        returnValue.push(erzeugeTab("Schreiber" ,"schreiber", 100));
        returnValue.push(erzeugeTab("Turnierpriorität" ,"tournamentprio", 200));
        returnValue.push(erzeugeTab("Teilnehmerpriorität" ,"teilnehmerprio", 220));
        returnValue.push(erzeugeTab("Teilen" ,"teilen", 100));
        return returnValue;
    }

    const getMenuItems = () => {
        let returnValue = [];
        returnValue.push({label: 'Boardbelegung', commandValue: "boardbelegung", command: () => setTabValue("boardbelegung")});
        returnValue.push({label: 'Boardvergabe', commandValue: "boardvergabe", command: () => setTabValue("boardvergabe")});
        returnValue.push({label: 'Boardaufruf', commandValue: "boardauruf", command: () => setTabValue("boardauruf")});
        returnValue.push({label: 'Schreiber', commandValue: "schreiber", command: () => setTabValue("schreiber")});
        returnValue.push({label: 'Turnierpriorität', commandValue: "tournamentprio", command: () => setTabValue("tournamentprio")});
        returnValue.push({label: 'Teilnehmerpriorität', commandValue: "teilnehmerprio", command: () => setTabValue("teilnehmerprio")});
        returnValue.push({label: 'Teilen', commandValue: "teilen", command: () => setTabValue("teilen")});
        return returnValue;
    }

    const getTabpanel = () => {
        if( tabValue === "boardauruf" ) {
            return <BoardAufrufArea eventOperatorId={eventOperatorId}/>;
        } else if( tabValue === "tournamentprio" ) {
            return <TournamentPrioArea eventOperatorId={eventOperatorId} />;
        } else if( tabValue === "boardbelegung" ) {
            return <BoardBelegungArea eventOperatorId={eventOperatorId} refreshCounter={refreshCounter}/>;
        } else if( tabValue === "boardvergabe" ) {
            return <BoardVergabeArea eventOperatorId={eventOperatorId} />;
        } else if( tabValue === "teilnehmerprio" ) {
            return <TeilnehmerPrioArea eventOperatorId={eventOperatorId} />;
        } else if( tabValue === "schreiber" ) {
            return <TurnierSchreiberArea eventOperatorId={eventOperatorId} />;
        } else if( tabValue === "teilen" ) {
             return <BoardTeilenArea eventOperatorId={eventOperatorId} />;
        }
    }

    const getMobileMenu = () => {
        let menuItems = getMenuItems();
        let rows = [];
        for (const menuItem of menuItems) {
            rows.push(<MenuItem onClick={() => {
                menuItem.command();
                setMenuOpen(false);
            }} style={{fontSize:20, borderLeft: tabValue === menuItem.commandValue ? "10px solid #ac1c27" : "none"}}>
                {menuItem.label}
            </MenuItem>)
        }
        return <Menu
            id="basic-menu"
            anchorEl={menu}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {rows}
        </Menu>
    }

    return <DHDialog title="Rund ums Board" showCloseButton={true} onClose={() => onClose()} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <DHMobileDiv comp={
            <div style={{display: "flex", marginBottom:20}}>
                <MobileMenuArea onClick={(e) => {
                    setMenu(e.currentTarget);
                    setMenuOpen(true);
                }} color="black"/>
                {getMobileMenu()}
                <Button onClick={() => boardvergabeAnstossen()} style={{width:"100%", marginLeft:20}}>Es zwickt im Turnierverlauf? Stosse hier die automatische Boardvergabe erneut an!</Button>
            </div>
        }/>
        <DHDesktopDiv comp={<>
            <div style={{marginBottom: 20}}>
                <Button onClick={() => boardvergabeAnstossen()} style={{width: "100%"}}>Es zwickt im Turnierverlauf? Stosse hier die automatische Boardvergabe erneut an!</Button>
            </div>
            <div style={{marginTop:10, marginBottom:20}}>
            <div style={{display: "flex"}}>
                {getTabs()}
            </div>
        </div>
        </>}/>
    {getTabpanel()}
    </>
}/>
}
