import { useEffect } from "react";
import {useLocation, useParams, useNavigate} from "react-router-dom";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let params = useParams();
        const navigate = useNavigate();

        useEffect( () => {
            if (location.hash.startsWith('#/')) {
                navigate(location.hash.replace('#', ''));
            }
        } ,[navigate]);

        return (
            <Component
                {...props}
                router={{ location, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter;
