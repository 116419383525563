import React, {useState} from "react";
import FetchUtil from "../../../util/FetchUtil";
import ProgressButton from "../../general/ProgressButton";
import DHDialog from "../../general/DHDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import ConfigUtil from "../../../util/ConfigUtil";
import {Button} from "primereact/button";


export default function TournamentSchweizerSystemNaechsteRundeDialog({tournament, isEineGruppe, gruppe, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const[players, setPlayers] = useState([]);
    const[working, setWorking] = useState(false);

    const starteNaechsteRunde = () => {
        setWorking(true);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/schweizersystem/startenaechsterunde/" + tournament.id + "/" + gruppe,
            players,
            () => {
                setInfoMessage("Nächste Runde wurde gestartet.");
                setWorking(false);
                onRefresh();
                onClose();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setWorking(false);
            }, 20000
        );
    }

    const changePlayer = (playerId) => {
        let newPlayers = [...players]
        if( newPlayers.includes(playerId)) {
            newPlayers = newPlayers.filter(p => p !== playerId);
        } else {
            newPlayers.push(playerId);
        }
        setPlayers(newPlayers);
    }

    const getPlayerRow = (player) => {
        let playerSelected = players.includes(player.id);
        return <div key={"key_player" + player.id}>
            <Button icon={playerSelected && "pi pi-check"} style={{marginRight:10, backgroundColor: playerSelected ? "#009f5a" : ""}}
                    onClick={() => changePlayer(player.id)}/>
            {player.name}
        </div>
    }

    const getPlayersOhneGame = () => {
        let rows = [];
        let players = tournament.tournamentPlayerEntities;
        for (const player of players) {
            if( player.inaktiv) {
                continue;
            }
            let games = tournament.tournamentGroupGameEntities.filter(g => g.spieler1 === player.id || g.spieler2 === player.id);
            if( games.length === 0) {
                rows.push(getPlayerRow(player));
            }
        }
        return rows;
    }

    const getArea = () => {
        return <div style={{textAlign: "left", padding: 20}}>
            {getPlayersOhneGame().length > 0 &&
                <div style={{marginBottom:30}}>
                    <div style={{marginBottom:10}}>
                        Sofern ein neuer Spieler in dieser Gruppe mitspielen soll, wähle diesen bitte aus.
                    </div>
                    {getPlayersOhneGame()}
                </div>
            }
            <div>
                {isEineGruppe ? "Soll die nächste Runde gestartet werden?" : "Soll die nächste Runde für Gruppe '" + gruppe+ "' gestartet werden?"}
            </div>
        </div>;
    }

    return <DHDialog title={"Nächste Runde starten"} showCloseButton={true} onClose={() => onClose()}
                     buttons={[
                         <ProgressButton text="Nächste Runde starten" icon="pi pi-check"
                                         working={working}
                                         disabled={false}
                                         onClick={() => starteNaechsteRunde()} />
                     ]} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {working && <div style={{color: "red"}}>Die Auslosung wird durchgeführt, dies kann etwas dauern!!!</div>}
        {getArea()}
    </>}/>
}