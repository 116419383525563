import React, {useState} from 'react'
import {getFromLocalStorageWithDefault, saveToLocalStorage} from "../../../util/localStorageZugriff";
import DHDialog from "../../general/DHDialog";

export default function DashboardSelectionArea({isOnlineDashboard, onChange}) {
    let [showHelp, setShowHelp] = useState(false);

    const save = (area) => {
        saveToLocalStorage("dh_dashboard", area);
        onChange(area);
    }

    const getHelpArea = () => {
        if( !showHelp) {
            return "";
        }
        return <DHDialog showCloseButton={true} onClose={() => setShowHelp(false)} buttons={[]} title={"Hilfe"} comp={<>
            <div style={{fontWeight:"bold", marginBottom:10}}>Veranstalterbereich</div>
            <div style={{marginBottom:5}}>Im Veranstalterbereich kannst du jederzeit Ligen und Turniere anlegen und verwalten.</div>
            <div style={{marginBottom:5}}>Spiele mit deinen Freunden, Vereins- oder Mannschaftskollegen oder eine beliebige Gruppe deine Ligen und Turniere und verwalte über darthelfer.de deine Ergebnisse.</div>
            <div style={{marginBottom:5}}>Über den öffentlichen Bereich (Link zum Turnier erhälst du kostenlos) kann die ganze Welt deine Ligen und Turniere verfolgen.</div>
            <div style={{marginBottom:5}}>Sofern du den Veranstalter Pro-Account buchst, kannst du deine Ligen und Turniere vollautomatisch über den darthelfer-scorer spielen. In diesem Fall werden Ergebnisse und Statistiken automatisch übernommen. Lehne dich zurück und genieße einen stressfreien und entspannden Turnierablauf.</div>
            <div style={{fontWeight:"bold", marginTop: 10, marginBottom:10}}>Bereich für Onlineligen und -turniere</div>
            <div style={{marginBottom:5}}>Hier werden von verschiedenen Onlineveranstaltern Ligen und Turniere angeboten.</div>
            <div style={{marginBottom:5}}>Die Spiele erfolgen ausschließlich Online und sind nicht für die Kneipe oder deiner Veranstaltung gedacht.</div>
            <div style={{marginTop:20}}><a href="https://scorer.darthelfer.de" target="_blank" rel="noopener noreferrer">Hier gehts zum Scorer...</a></div>
            <div style={{marginTop:20}}><a href="https://www.facebook.com/groups/darthelfer" target="_blank" rel="noopener noreferrer">Hier gehts zur Facebook-Gruppe, da wird dir jederzeit geholfen...</a></div>
        </>}/>
    }

    if( !getFromLocalStorageWithDefault("dh_dashboard", undefined)) {
        return <div className={"col-12 md:col-12 lg:col-12"} style={{padding: 10}}>
            <div className="kachelStartseite" style={{height: "100%"}}>
                <div style={{marginTop:10}}>Bitte wähle deinen Bereich den du nutzen möchtest, du kannst danach jederzeit erneut wechseln.</div>
                <div style={{marginTop: 20, cursor: "pointer"}} onClick={() => save("online")}>
                    <img src={"/images/kachel/tree_blue.png"} alt="Dashboard" style={{height: 20, width: 20, marginLeft: 10, marginRight: 10}}/>
                    <span style={{fontWeight: "bold"}}>zum Bereich für Onlineligen und -turniere</span>
                    <div style={{marginTop: 5}}>
                        Hier kannst du an Onlineligen oder Onlineturniere teilnehmen.<br/>
                        Weiter siehst du in diesem Bereich deine Statistik von Spielen die du im Onlinebereich oder auf einem Veranstalterevent gespielt hast.
                    </div>
                </div>
                <div style={{marginTop: 20, cursor: "pointer"}} onClick={() => save("eventoperator")}>
                    <img src={"/images/eventoperator.png"} alt="Dashboard" style={{height: 20, width: 20, marginLeft: 10, marginRight: 10}}/>
                    <span style={{fontWeight: "bold"}}>zum Veranstalterbereich</span>
                    <div style={{marginTop: 5}}>
                        Im Veranstalterbereich kannst du deine eigenen Events (Turniere, Ligen, Ligaspieltage) anlegen und mit darthelfer verwalten.
                    </div>
                </div>
                {getHelpArea()}
                <div style={{marginTop:30}}>Hinweis: In Darthelfer wird zwischen Onlineligabereich und Veranstalterbereich unterschieden.
                    <img src="/images/question.png" alt="Frage?" onClick={() => setShowHelp(true)} style={{height: 30, width: 30, cursor:"pointer"}}/></div>
            </div>
        </div>
    }

    return <div className={"col-12 md:col-12 lg:col-12"} style={{padding: 10}}>
        <div className="kachelStartseite" style={{height: "100%"}}>
            {getHelpArea()}
            <div style={{marginBottom:5}}>Hinweis: In Darthelfer wird zwischen Onlineligabereich und Veranstalterbereich unterschieden.
                <img src="/images/question.png" alt="Frage?" onClick={() => setShowHelp(true)} style={{height: 30, width: 30, cursor:"pointer"}}/></div>
            <div style={{marginBottom:5}}>Aktuell befindest du dich im
                <img src={isOnlineDashboard ? "/images/kachel/tree_blue.png" : "/images/eventoperator.png"} alt="Dashboard" style={{height: 20, width: 20, marginLeft:10, marginRight:10}}/>
                <span style={{fontWeight:"bold"}}>{isOnlineDashboard ? " Bereich für Onlineligen und -turniere" : " Veranstalterbereich"}.</span>
            </div>
            <div>Wechsle hier
                <img src={!isOnlineDashboard ? "/images/kachel/tree_blue.png" : "/images/eventoperator.png"} alt="Dashboard" style={{height: 20, width: 20, marginLeft:10, marginRight:10}}/>
                <span style={{fontWeight:"bold", cursor:"pointer"}} onClick={() => save(isOnlineDashboard ? "eventoperator" : "online")}>zum {isOnlineDashboard ? " Veranstalterbereich" : " Bereich für Onlineligen und -turniere."}</span></div>
        </div>
    </div>
}
