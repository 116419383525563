import React from 'react'
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PropTypes from "prop-types";
import {InputText} from "primereact/inputtext";
import {TabPanel, TabView} from "primereact/tabview";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {NavLink} from "react-router-dom";

class BenutzersucheView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {personen: [], doppelpartner: [], doppelname: "", username: "", vorname: "", name: ""};

        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.ladeDoppelpartner = this.ladeDoppelpartner.bind(this);
        this.ladePersonen = this.ladePersonen.bind(this);
    }

    showWarn(message) {
        this.messages.show({
            severity: 'info',
            summary: 'Hinweis...',
            detail: message
        });
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    ladePersonen() {
        this.messages.clear();
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.user + "/search?username=" + this.state.username  + "&vorname=" + this.state.vorname  + "&name=" + this.state.name,
            json => {
                this.setState({personen: [...json]});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    ladeDoppelpartner() {
        this.messages.clear();
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.doppelpartner + "/search?doppelpartnername=" + this.state.doppelname,
            json => {
                this.setState({doppelpartner: [...json]});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    getDescription(description) {
        return <div className="grid" style={{textAlign:"left"}}>
            <div className="col" style={{fontSize:12}}>
                {description}
            </div>
        </div>
    }

    getDoppelpartnerArea() {
        let rows = [];
        if( this.state.doppelpartner.length === 0) {
            rows.push(<div key="user_search_empty_rows">Keine Daten vorhanden...</div>)
        }
        for (const dPartner of this.state.doppelpartner) {
            rows.push(<div key={"row_doppel_search_" + dPartner.id}>{dPartner.name}<br/>
            <span style={{fontSize:12}}>Partner: {dPartner.userName1} u. {dPartner.userName2}</span><br/>
            <span style={{fontSize:12}}>30 Tage Avg.: {dPartner.average}</span><br/>
            </div>);
            rows.push(<hr key={"row_doppel_line_" + dPartner.id}/>);
        }
        return <div>
            {this.getDescription("Suche nach Doppelpartnern. Du musst mindestens drei Buchstaben eingeben!")}
            <div className="grid" style={{textAlign:"left", marginTop:10}}>
                <div className="col-fixed" style={{width:120}}>
                    Doppelname:
                </div>
                <div className="col">
                    <InputText style={{width:300}} value={this.state.doppelname}
                               onChange={(e) => this.setState({doppelname: e.target.value})}/>
                </div>
            </div>
            <div className="grid" style={{textAlign:"left", marginTop:5}}>
                <div className="col-fixed" style={{width:120}}>
                </div>
                <div className="col">
                    <Button icon="pi pi-search" label="Doppelpartner suchen" onClick={() => this.ladeDoppelpartner()}/>
                </div>
            </div>
            <div style={{marginTop:20, textAlign:"left"}}>
                {rows}
            </div>
        </div>
    }

    getPersonArea() {
        let rows = [];
        if( this.state.personen.length === 0) {
            rows.push(<div key="user_search_empty_rows">Keine Daten vorhanden...</div>)
        }
        for (const person of this.state.personen) {
            rows.push(<NavLink to={NAVIGATION_ITEM.PROFILFRIEND.route + "?profilId=" + person.userId}  style={{textDecoration: "none"}}>
                <div key={"row_person_search_" + person.userId} style={{textDecoration: "none", color:"black"}}>
                    <Button icon="pi pi-search-plus" style={{marginRight: 5}}/>{person.name}<br/>
                <span style={{fontSize:12}}>Username: {person.username}</span><br/>
                <span style={{fontSize:12}}>30 Tage Avg.: {person.average}</span><br/>
            </div></NavLink>);
            rows.push(<hr key={"row_person_line_" + person.userId}/>);
        }
        return <div>
            {this.getDescription("Es müssen im Feld mindestens drei Buchstaben eingegeben werden, ansonsten wird das Feld in der Suche nicht berücksichtigt!!!")}
            {this.getDescription("Es werden alle gefüllten Felder bei der Suche benutzt, somit kannst du darüber die Sucheergebnisse einschränken.")}
            <div className="grid" style={{textAlign:"left", marginTop:10}}>
                <div className="col-fixed" style={{width:120}}>
                    Name:
                </div>
                <div className="col">
                    <InputText style={{width:300}} value={this.state.name}
                               onChange={(e) => this.setState({name: e.target.value})}/>
                </div>
            </div>
            <div className="grid" style={{textAlign:"left"}}>
                <div className="col-fixed" style={{width:120}}>
                    Vorname:
                </div>
                <div className="col">
                    <InputText style={{width:300}} value={this.state.vorname}
                               onChange={(e) => this.setState({vorname: e.target.value})}/>
                </div>
            </div>
            <div className="grid" style={{textAlign:"left"}}>
                <div className="col-fixed" style={{width:120}}>
                    Benutzername:
                </div>
                <div className="col">
                    <InputText style={{width:300}} value={this.state.username}
                               onChange={(e) => this.setState({username: e.target.value})}/>
                </div>
            </div>
            <div className="grid" style={{textAlign:"left", marginTop:5}}>
                <div className="col-fixed" style={{width:120}}>
                </div>
                <div className="col">
                    <Button icon="pi pi-search" label="Benutzer suchen" onClick={() => this.ladePersonen()}/>
                </div>
            </div>
            <div style={{marginTop:20, textAlign:"left"}}>
                {rows}
            </div>
        </div>
    }

    render() {
        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="pageHeader" style={{backgroundColor: "#0367B5"}}>
                Benutzersuche
            </div>
            <TabView style={{width: "100%", paddingTop:10, paddingLeft:10, paddingRight:10}}>
                <TabPanel header="Person">
                    {this.getPersonArea()}
                </TabPanel>
                <TabPanel header="Doppelpaarung">
                    {this.getDoppelpartnerArea()}
                </TabPanel>
            </TabView>
        </div>
    }
}

BenutzersucheView.propTypes = {
    userId: PropTypes.string.isRequired
};

export default BenutzersucheView;
