import React, {useEffect, useState} from 'react'
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";

export default function ParticipantConfirmation({id, startseiteUrl}) {

    const [infoMessage, setInfoMessage] = useState("");
    const [errorMessage, setErorMessage] = useState("");

    useEffect(() => {
        abschliessen();
    }, []);

    const abschliessen = () => {
        setErorMessage("");
        if (id === null || id === undefined || id === "") {
            setErorMessage("Aufruf dieser Seite ohne eine ID ist nicht erlaubt.");
            return;
        }

        let url = ConfigUtil.getConfig().resourceUrls.public + "/participantconfirmation/" + id;
        FetchUtil.fetchPost(url,
            {},
            message => {
                if( message === "") {
                    setInfoMessage("Deine Teilnahme wurde vermerkt. Viel Spaß mit Darhelfer, viel Erfolg im Turnier und good darts...");
                } else {
                    setErorMessage(message);
                }
            },
            responseNotOk => {
                setErorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErorMessage("Fehler: " + error);
            }
        );
    }

    return <div style={{padding: 0, backgroundColor: "#022836", minHeight: "100vh", height: "100%", textAlign: "center", color:"white"}}>
        <div style={{color:"white", marginTop:10}}>
            <a href={startseiteUrl}><img alt="Logo" src={"/images/darthelfer.svg"} style={{width: 300, maxWidth: 300}}/></a>
        </div>
        <div style={{color:"white", marginTop:10, fontSize:18, cursor:"pointer"}} onClick={() => window.open(startseiteUrl, "_self")}>
            Hier gehts zur Startseite...
        </div>
        {infoMessage !== "" && <div style={{marginTop:30, color:"green", fontSize:18}}>{infoMessage}</div>}
        {errorMessage !== "" && <div style={{marginTop:30, color:"red", fontSize:18}}>{errorMessage}</div>}
    </div>
}
