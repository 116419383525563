import React from "react";
import {TextField,} from "@mui/material";

export default function DHNumberTextField({id, label, width, required, min, max, value, onChange}) {

    return <TextField id={id} label={label} variant="filled"
                      type="number"
                      style={{width: width}} required={required}
                      value={value} onChange={(event) => onChange(event.target.value)}
                      slotProps={{
                          htmlInput: {
                              min: min,
                              max: max,
                          },
                          inputLabel: {
                              shrink: true,
                          },
                      }}
    />

}