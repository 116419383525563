import React from "react";
import {Button, createTheme,} from "@mui/material";
import {ThemeProvider} from "@mui/styles";

import CloseIcon from '@mui/icons-material/Close';

export default function DHButtonClose({onClick}) {

    const theme = createTheme({
        typography: {
            button: {
                textTransform: 'none',
            },
        },
        button: {
            textTransform: 'none',
        },
    });

    return <ThemeProvider theme={theme}>
        <Button variant="contained" onClick={() => onClick()} startIcon={<CloseIcon/>} style={{textTransform: "none", letterSpacing:"0.1em"}}>Schliessen</Button>
    </ThemeProvider>
}