import React from 'react'
import {thunk} from 'redux-thunk'
import {applyMiddleware, createStore} from 'redux'
import {Provider} from 'react-redux'
import rootReducer from './reducers'
import AppContainer from "./containers/app/AppContainer";
import {BrowserRouter} from "react-router-dom";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {CookiesProvider} from 'react-cookie';
import {createRoot} from 'react-dom/client';

const store = createStore(
    rootReducer,
    applyMiddleware(
      thunk
    )
);

const container = createRoot( document.getElementById('root'));
container.render(
    <Provider store={store}>
        <BrowserRouter>
            <CookiesProvider>
                <AppContainer/>
            </CookiesProvider>
        </BrowserRouter>
    </Provider>
);
